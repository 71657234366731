import React, { useState } from 'react';
import { Stack, Group, Text, Alert, Badge, Box, Button, Collapse } from '@mantine/core';
import { Editor } from '@monaco-editor/react';
import { AlertCircle, ChevronDown, ChevronRight } from 'lucide-react';
import { agentAttachedStyles as styles } from 'theme/agentAttachedStyles';

const AgentInstructions = ({ competitionInfo }) => {
  const [showStructure, setShowStructure] = useState(true);

  const editorOptions = {
    minimap: { enabled: false },
    scrollBeyondLastLine: false,
    lineNumbers: 'on',
    fontSize: 14,
    tabSize: 4,
    readOnly: true
  };

  return (
    <div style={styles.section}>
      <Group justify="space-between" mb="md" className="cursor-pointer" onClick={() => setShowStructure(!showStructure)}>
        <Text style={styles.sectionTitle}>Implementation Guide</Text>
        <Button 
          variant="subtle" 
          rightSection={showStructure ? <ChevronDown size={16} /> : <ChevronRight size={16} />}
        >
          {showStructure ? 'Hide Details' : 'Show Details'}
        </Button>
      </Group>

      <Collapse in={showStructure}>
        <Stack spacing="md">
          <Stack spacing="xs">
            <Text size="sm">1. Upload a main file <Badge variant="light">agent.py</Badge> with the Agent as described in the implementation below</Text>
            <Text size="sm">2. Upload all the needed files models and helper</Text>
            <Text size="sm">3. Choose your runtime configuration</Text>
            <Text size="sm">4. When you feel ready go to next step clicking proceed to deployment</Text>
          </Stack>

          <Alert
            variant="light"
            color="blue"
            icon={<AlertCircle size={16} />}
            style={styles.alertContainer}
          >
            Make sure all imported modules are either included in your uploads or available in the runtime environment
          </Alert>
          
          <Box style={styles.editorContainer}>
            <Text size="sm" mb="xs">Implementation template:</Text>
            <Editor
              height="200px"
              language="python"
              theme="vs-dark"
              value={competitionInfo?.agent_template || '# Loading template...'}
              options={editorOptions}
            />
          </Box>

          <Stack spacing="xs" mt="lg">
            <Text weight={500}>Additional Guidelines:</Text>
            <Text size="sm">• Make sure your agent class follows the template structure exactly</Text>
            <Text size="sm">• Test your agent locally before uploading if possible</Text>
            <Text size="sm">• Keep your code well-organized and documented</Text>
            <Text size="sm">• Check that all dependencies are properly specified</Text>
          </Stack>
        </Stack>
      </Collapse>
    </div>
  );
};

export default AgentInstructions;