// src/theme/index.js
import { createTheme } from '@mantine/core';

export const theme = createTheme({
  colors: {
    // Brand Colors
    purple: [
      '#F5F3FF', // 0: Backgrounds, hover states
      '#EBE5FF', // 1: Light backgrounds
      '#D5CCFF', // 2: Disabled states
      '#B7A8FF', // 3: Borders
      '#9985FF', // 4: Secondary text
      '#7B61FF', // 5: Accents
      '#4318FF', // 6: Primary actions
      '#3311DB', // 7: Hover states
      '#2200B7', // 8: Active states
      '#130093', // 9: Dark accents
      '#C6C8F9', //10
      '#F2F1F9', //11
    ],
    
    // Secondary Colors
    blue: [
      '#F0F7FF', // 0: Light backgrounds
      '#E6F0FF', // 1: Hover states
      '#CCE0FF', // 2: Borders
      '#99C2FF', // 3: Icons
      '#66A3FF', // 4: Secondary text
      '#3385FF', // 5: Accents
      '#0066FF', // 6: Links
      '#0052CC', // 7: Hover states
      '#003D99', // 8: Active states
      '#002966', // 9: Dark accents
      '#176DE2', // 10: blue button
      '#001F54', // 11: dark blue
    ],
    
    // Text & UI Colors
    navy: [
      '#F5F5FF', // 0: Backgrounds
      '#EBEEFF', // 1: Light backgrounds
      '#000B3C', // 2: Primary text
      '#00082E', // 3: Secondary text
      '#000621', // 4: Tertiary text
      '#000413', // 5: Disabled text
      '#000310', // 6: Borders
      '#00020D', // 7: Separators
      '#00010A', // 8: Dark accents
      '#000007', // 9: Shadows
    ],

    background: [
      '#F7F7F7',
      '#FFFFFF',
      '#F5F5F5',
    ],
  },

  primaryColor: 'blue',
  fontFamily: 'Inter, sans-serif',
  
  components: {
    Button: {
      defaultProps: {
        size: 'md',
        color: 'blue.10'
      },
      styles: (theme) => ({
        root: {
          '&:hover': {
            transform: 'translateY(-1px)',
            transition: 'transform 0.2s ease',
          },
        },
      }),
    },
    TextInput: {
      defaultProps: {
        size: 'md',
      },
    },
    Title: {
      defaultProps: {
        color: 'navy.2',
      },
    },
    Text: {
      defaultProps: {
        color: 'navy.3',
      },
    },
  },

  shadows: {
    xs: '0 1px 2px 0 rgb(0 0 0 / 0.05)',
    sm: '0 1px 3px 0 rgb(0 0 0 / 0.1)',
    md: '0 4px 6px -1px rgb(0 0 0 / 0.1)',
    lg: '0 10px 15px -3px rgb(0 0 0 / 0.1)',
  },

  radius: {
    xs: '0.25rem',
    sm: '0.375rem',
    md: '0.5rem',
    lg: '0.75rem',
    xl: '1rem',
  },
});