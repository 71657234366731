import React, { useState, useEffect } from 'react';
import { Stack, Button, Text } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import axios from 'axios';
import { useAuth } from '../../context/AuthContext';
import YourTeam from './teams/YourTeam';
import SendInvitation from './teams/SendInvitation';
import TeamInfoSection from './teams/TeamInfoSection';
import ReceivedInvitations from './teams/ReceivedInvitations';
import { useNavigate } from 'react-router-dom';

const TeamTab = ({ competitionId }) => {
  const navigate = useNavigate();
  const { currentUser, isAuthenticated } = useAuth();
  const [loading, setLoading] = useState(true);
  const [team, setTeam] = useState(null);
  const [receivedInvitations, setReceivedInvitations] = useState([]);

  const fetchTeamData = async () => {
    if (!isAuthenticated) {
      setLoading(false);
      return;
    }

    try {
      const response = await axios.get(`${process.env.REACT_APP_PREFIX_BACKEND}/api/teams/competition/${competitionId}/team`);
      setTeam(response.data);
    } catch (error) {
      if (error.response?.status !== 404) {
        notifications.show({
          title: 'Error',
          message: 'Failed to fetch team data',
          color: 'red'
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const fetchInvitations = async () => {
    if (!isAuthenticated) return;

    try {
      const response = await axios.get(`${process.env.REACT_APP_PREFIX_BACKEND}/api/teams/invitations/received`);
      setReceivedInvitations(response.data);
    } catch (error) {
      notifications.show({
        title: 'Error',
        message: 'Failed to fetch invitations',
        color: 'red'
      });
    }
  };

  useEffect(() => {
    fetchTeamData();
    fetchInvitations();
  }, [competitionId, isAuthenticated]);

  const handleCreateTeam = async (name) => {
    if (!isAuthenticated) {
      navigate('/login');
      return;
    }

    try {
      await axios.post(`${process.env.REACT_APP_PREFIX_BACKEND}/api/teams/competition/${competitionId}/team`, {
        name
      });
      notifications.show({
        title: 'Success',
        message: 'Team created successfully',
        color: 'green'
      });
      fetchTeamData();
    } catch (error) {
      throw error;
    }
  };

  const handleLeaveTeam = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_PREFIX_BACKEND}/api/teams/${team.id}/leave`);
      notifications.show({
        title: 'Success',
        message: 'Left team successfully',
        color: 'green'
      });
      setTeam(null);
    } catch (error) {
      notifications.show({
        title: 'Error',
        message: error.response?.data?.error || 'Failed to leave team',
        color: 'red'
      });
    }
  };

  const handleUpdateTeam = async (updatedTeam) => {
    try {
      await axios.put(`${process.env.REACT_APP_PREFIX_BACKEND}/api/teams/${updatedTeam.id}`, {
        name: updatedTeam.name,
        looking_for_members: updatedTeam.looking_for_members
      });
      fetchTeamData();
    } catch (error) {
      throw error;
    }
  };

  const handleDeleteTeam = async (teamId) => {
    try {
      await axios.delete(`${process.env.REACT_APP_PREFIX_BACKEND}/api/teams/${teamId}`);
      setTeam(null);
    } catch (error) {
      throw error;
    }
  };

  const handleRemoveMember = async (memberId) => {
    try {
      await axios.delete(`${process.env.REACT_APP_PREFIX_BACKEND}/api/teams/${team.id}/members/${memberId}`);
      notifications.show({
        title: 'Success',
        message: 'Member removed successfully',
        color: 'green'
      });
      fetchTeamData();
    } catch (error) {
      notifications.show({
        title: 'Error',
        message: error.response?.data?.error || 'Failed to remove member',
        color: 'red'
      });
    }
  };

  const handleSearch = async (query) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_PREFIX_BACKEND}/api/teams/search?q=${query}&competition_id=${competitionId}`
      );
      return response.data.users;
    } catch (error) {
      notifications.show({
        title: 'Error',
        message: 'Search failed',
        color: 'red'
      });
      return [];
    }
  };

  const handleSendInvitation = async (userId) => {
    try {
      await axios.post(`${process.env.REACT_APP_PREFIX_BACKEND}/api/teams/${team.id}/invitations`, {
        user_id: userId
      });
      notifications.show({
        title: 'Success',
        message: 'Invitation sent successfully',
        color: 'green'
      });
    } catch (error) {
      notifications.show({
        title: 'Error',
        message: error.response?.data?.error || 'Failed to send invitation',
        color: 'red'
      });
    }
  };

  const handleInvitationResponse = async (invitationId, accept) => {
    try {
      await axios.post(`${process.env.REACT_APP_PREFIX_BACKEND}/api/teams/invitations/${invitationId}/respond`, {
        accept
      });
      notifications.show({
        title: 'Success',
        message: `Invitation ${accept ? 'accepted' : 'declined'} successfully`,
        color: 'green'
      });
      fetchInvitations();
      if (accept) {
        fetchTeamData();
      }
    } catch (error) {
      notifications.show({
        title: 'Error',
        message: error.response?.data?.error || 'Failed to respond to invitation',
        color: 'red'
      });
    }
  };

  const isLeader = team?.members?.find(
    member => member.user.id === currentUser?.user_id && member.role === 'leader'
  );

  if (!isAuthenticated) {
    return (
      <Stack className="w-full max-w-3xl mx-auto p-4" align="center" spacing="lg">
        <Text size="lg" weight={500}>
          Join or create a team to participate in this competition
        </Text>
        <Button 
          onClick={() => navigate('/login')}
          size="md"
          variant="filled"
        >
          Login to Access Teams
        </Button>
      </Stack>
    );
  }

  return (
    <Stack className="w-full max-w-3xl mx-auto p-4">
      <TeamInfoSection/>
      <YourTeam 
        team={team}
        loading={loading}
        currentUser={currentUser}
        onCreateTeam={handleCreateTeam}
        onLeaveTeam={handleLeaveTeam}
        onRemoveMember={handleRemoveMember}
        onUpdateTeam={handleUpdateTeam}
        onDeleteTeam={handleDeleteTeam}
      />

      {team && isLeader && (
        <SendInvitation
          team={team}
          onSendInvitation={handleSendInvitation}
          onSearch={handleSearch}
        />
      )}

      {isAuthenticated && (
        <ReceivedInvitations
          invitations={receivedInvitations}
          onRespond={handleInvitationResponse}
        />
      )}
    </Stack>
  );
};

export default TeamTab;