import { useEffect, useState, useRef } from "react";
import { io } from "socket.io-client";

export const useVideoStream = (competitionId) => {
  const socket = useRef(null);
  const [isConnected, setIsConnected] = useState(false);
  useEffect(() => {
    const socketUrl = `${process.env.REACT_APP_PREFIX_SOCKET}/${competitionId}frontendio`;
    // console.log("Connecting to socket at URL:", socketUrl);
    socket.current = io(socketUrl, {
      path: `/socket/`,
      transports: ["websocket"],
    });

    socket.current.on("connect", () => {
      // console.log("Connected to WebSocket server", socket.current);
      setIsConnected(true);
    });

    socket.current.on("disconnect", () => {
      // console.log("Disconnected from WebSocket server");
      setIsConnected(false);
    });

    return () => {
      // console.log("Closing socket");
      socket.current.close();
      setIsConnected(false);
    };
  }, [competitionId]);

  return { socket_current: socket.current, isConnected };
};
