import { Stack, Title, Group, Button, Modal, Paper, Text, Select, Autocomplete } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import useDiscussionsCompetition from 'hooks/competition/useDiscussionsCompetition';
import { useState, useEffect } from 'react';
import { ArrowUpDown, MessageCircle, ExternalLink } from 'lucide-react';
import { useNavigate, useLocation } from 'react-router-dom';
import DiscussionDetail from 'components/Discussion/DiscussionDetail';
import DiscussionEditor from 'components/Discussion/DiscussionEditor';
import DiscussionCard from 'components/Discussion/DiscussionCard';

const Discussions = ({ 
  competitionId, 
  isAdmin, 
  isAuthenticated, 
  currentUser,
  isFromConfiguration = false,
  agentAttachedId = null
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const presetAgentId = location.state?.presetAgentId;
  
  const [selectedDiscussionId, setSelectedDiscussionId] = useState(null);
  const [opened, { open, close }] = useDisclosure(false);
  const [sortBy, setSortBy] = useState('recent');
  const [searchValue, setSearchValue] = useState('');
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  
  const {
    discussions,
    loading,
    error,
    createDiscussion,
    voteDiscussion,
    updateDiscussion,
    deleteDiscussion,
    fetchDiscussions,
    searchAgents
  } = useDiscussionsCompetition(isAuthenticated, competitionId);

  // Handle initial agent setup from either configuration or preset
  useEffect(() => {
    const initializeAgent = async () => {
      const agentId = isFromConfiguration ? agentAttachedId : presetAgentId;
      
      if (agentId) {
        setIsSearching(true);
        try {
          const agentDetails = await searchAgents('', agentId);
          if (agentDetails && agentDetails.length > 0) {
            const agent = agentDetails[0];
            setSelectedAgent(agent);
            setSearchValue(`${agent.agent_name} by ${agent.user_name}`);
          }
        } catch (error) {
          console.error('Error fetching agent details:', error);
        } finally {
          setIsSearching(false);
        }
      }
    };
  
    initializeAgent();
  }, [isFromConfiguration, agentAttachedId, presetAgentId]);

  useEffect(() => {
    if (competitionId) {
      const filterAgentId = isFromConfiguration ? agentAttachedId : selectedAgent?.id;
      fetchDiscussions(sortBy, filterAgentId);
    }
  }, [sortBy, selectedAgent, competitionId, isFromConfiguration, agentAttachedId]);

  // Handle agent search - only needed when not in configuration view
  useEffect(() => {
    if (isFromConfiguration || !competitionId || !searchValue) {
      setSearchResults([]);
      return;
    }

    const searchTimer = setTimeout(async () => {
      setIsSearching(true);
      try {
        const results = await searchAgents(searchValue);
        setSearchResults(results || []);
      } finally {
        setIsSearching(false);
      }
    }, 300);

    return () => clearTimeout(searchTimer);
  }, [searchValue, competitionId, isFromConfiguration]);

  const handleCreateDiscussion = async (title, content, agentAttachedId) => {
    await createDiscussion(title, content, agentAttachedId);
    close();
  };

  const handleViewAllDiscussions = () => {
    navigate(`/viewcompetition/${competitionId}`, { state: { defaultTab: 'discussions' } });
  };

  const selectedDiscussion = discussions.find(d => d.id === selectedDiscussionId);

  if (loading) return <Text>Loading discussions...</Text>;
  if (error) return <Text color="red">{error}</Text>;

  if (selectedDiscussionId && selectedDiscussion) {
    return (
      <DiscussionDetail
        isAuthenticated={isAuthenticated}
        currentUser={currentUser}
        competitionId={competitionId}
        discussion={selectedDiscussion}
        onBack={() => setSelectedDiscussionId(null)}
        updateDiscussion={updateDiscussion}
        deleteDiscussion={deleteDiscussion}
        voteDiscussion={voteDiscussion}
        isCompetitionDiscussion={true}
      />
    );
  }

  return (
    <Stack spacing="xl">
      <Group justify="space-between">
        <Title order={isFromConfiguration ? 3 : 2}>
          {isFromConfiguration ? 'Agent Discussions' : 'Discussions'}
        </Title>
        <Group>
          {isAuthenticated && (
            <Button 
              onClick={open}
              leftSection={<MessageCircle size={16} />}
            >
              New Discussion
            </Button>
          )}
          {isFromConfiguration && (
            <Button
              variant="light"
              onClick={handleViewAllDiscussions}
              leftSection={<ExternalLink size={16} />}
            >
              View All Discussions
            </Button>
          )}
        </Group>
      </Group>

      {/* Only show filters when not in configuration view */}
      {!isFromConfiguration && (
        <Group align="flex-start">
          <Select
            label="Sort by"
            value={sortBy}
            onChange={setSortBy}
            data={[
              { value: 'recent', label: 'Most Recent' },
              { value: 'trending', label: 'Trending' }
            ]}
            leftSection={<ArrowUpDown size={16} />}
            w={200}
          />
          <Autocomplete
            label="Filter by Agent"
            placeholder="Search for an agent"
            value={searchValue}
            onChange={setSearchValue}
            {...(isSearching ? { loading: true } : {})}
            data={searchResults.map(agent => ({
              value: agent.id.toString(),
              label: `${agent.agent_name} by ${agent.user_name}`
            }))}
            onOptionSubmit={(value) => {
              const agent = searchResults.find(a => a.id.toString() === value);
              if (agent) {
                setSelectedAgent(agent);
                setSearchValue(`${agent.agent_name} by ${agent.user_name}`);
              }
            }}
            w={300}
            rightSection={
              selectedAgent && (
                <Button 
                  variant="subtle" 
                  size="xs" 
                  onClick={() => {
                    setSelectedAgent(null);
                    setSearchValue('');
                    fetchDiscussions(sortBy, null);
                  }}
                >
                  Clear
                </Button>
              )
            }
          />
        </Group>
      )}

      {discussions.length === 0 ? (
        <Paper p="xl" withBorder>
          <Stack align="center" spacing="md">
            <MessageCircle size={32} />
            <Text align="center" c="dimmed">
              No discussions found
              {!isFromConfiguration && selectedAgent && ` for ${selectedAgent.agent_name}`}
            </Text>
          </Stack>
        </Paper>
      ) : (
        <Stack>
          {discussions.map(discussion => (
            <DiscussionCard
              key={discussion.id}
              discussion={discussion}
              onVote={() => voteDiscussion(discussion.id)}
              onClick={() => setSelectedDiscussionId(discussion.id)}
            />
          ))}
        </Stack>
      )}

      <Modal 
        opened={opened} 
        onClose={close} 
        title={isFromConfiguration ? "Create Discussion about this Agent" : "Create Discussion"}
        size="xl"
      >
        <DiscussionEditor
          onSubmit={handleCreateDiscussion}
          onCancel={close}
          isCompetitionDiscussion={true}
          competitionId={competitionId}
          defaultAgentId={isFromConfiguration ? agentAttachedId : undefined}
        />
      </Modal>
    </Stack>
  );
};

export default Discussions;