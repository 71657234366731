import React from 'react';
import { Group, Paper, Text, Badge, ActionIcon, Tooltip } from '@mantine/core';
import { IconEye } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';

const AgentInfoToggle = ({ competitionId, agentInfo, evaluationType }) => {
  const navigate = useNavigate();
  if (!agentInfo) return null;
  const getStatusColor = (status) => {
    const statusColors = {
      active: 'green',
      paused: 'yellow',
      deploying: 'blue',
      deploy_failed: 'red',
      upload_failed: 'red',
      created: 'gray'
    };
    return statusColors[status] || 'gray';
  };

  // Get the appropriate score based on evaluation type
  const getScoreDisplay = () => {
    if (evaluationType === 'elo_score') {
      return `ELO: ${agentInfo.elo_score || 'N/A'}`;
    }
    return `Score: ${agentInfo.mean_reward?.toFixed(2) || 'N/A'}`;
  };

  return (
    <Paper withBorder p="xs" radius="md">
      <Group justify="flex-start" gap="xs">
        <Text fw={500}>{agentInfo.agent_name}</Text>
        <Text size="sm" c="dimmed">by {agentInfo.user_name}</Text>
        <Text size="sm">{getScoreDisplay()}</Text>
        <Badge size="sm" color={getStatusColor(agentInfo.status)}>
          {agentInfo.status}
        </Badge>
        {agentInfo.rank && (
          <Text size="sm" c="dimmed">Rank #{agentInfo.rank}</Text>
        )}
        {agentInfo.is_public && (              <Tooltip label="View Public Agent">
                <ActionIcon 
                  color="gray" 
                  variant="subtle" 
                  onClick={() =>         navigate(`/attach-agent/view/${competitionId}/${agentInfo.id}`, {
                    state: { defaultTab: 'configuration' }
                  })}
                >
                  <IconEye size={16} />
                </ActionIcon>
              </Tooltip>)}
      </Group>
    </Paper>
  );
};

export default AgentInfoToggle;