import { useState, useEffect } from 'react';
import { Stack, TextInput, Button, Group, Autocomplete } from '@mantine/core';
import { RichTextEditor } from '@mantine/tiptap';
import { useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Link from '@tiptap/extension-link';
import useDiscussionsCompetition from 'hooks/competition/useDiscussionsCompetition';


const DiscussionEditor = ({
  onSubmit,
  onCancel,
  initialTitle = '',
  initialContent = '',
  submitLabel = 'Create Discussion',
  titlePlaceholder = 'Discussion title',
  contentPlaceholder = 'Share your thoughts, ask questions, or discuss ideas...',
  isCompetitionDiscussion = false,
  competitionId,
  defaultAgentId
}) => {
  const [title, setTitle] = useState(initialTitle);
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  
  const { searchAgents } = useDiscussionsCompetition(true, competitionId);
  // Handle default agent ID
  useEffect(() => {
    const fetchDefaultAgent = async () => {
      if (!competitionId || !defaultAgentId) return;
      
      setIsSearching(true);
      try {
        const results = await searchAgents('', defaultAgentId);
        if (results && results.length > 0) {
          const agent = results[0];
          setSelectedAgent(agent);

          setSearchValue(`${agent.agent_name} by ${agent.user_name}`);
        }
      } finally {
        setIsSearching(false);
      }
    };

    fetchDefaultAgent();
  }, [competitionId, defaultAgentId]);

  // Initial fetch of agents when component mounts (only if no defaultAgentId)
  useEffect(() => {
    const fetchInitialAgents = async () => {
      if (!competitionId || defaultAgentId) return;
      
      setIsSearching(true);
      try {
        const results = await searchAgents('');
        setSearchResults(results || []);
      } finally {
        setIsSearching(false);
      }
    };

    fetchInitialAgents();
  }, [competitionId, defaultAgentId]);

  // Handle search with debounce
  useEffect(() => {
    if (!competitionId) return;

    let timeoutId;
    
    const performSearch = async () => {
      setIsSearching(true);
      try {
        const results = await searchAgents(searchValue);
        setSearchResults(results || []);
      } finally {
        setIsSearching(false);
      }
    };

    // Only perform search if user is actively searching (not when default agent is set)
    if (searchValue !== `${selectedAgent?.agent_name} by ${selectedAgent?.user_name}`) {
      timeoutId = setTimeout(performSearch, 300);
    }

    return () => clearTimeout(timeoutId);
  }, [searchValue, competitionId]);


  const handleSubmit = () => {
    if (!title.trim() || !editor?.getText()?.trim()) return;
    onSubmit(title, editor.getHTML(), selectedAgent?.id);
  };
  
  const editor = useEditor({
    extensions: [
      StarterKit,
      Link.configure({
        openOnClick: false,
        HTMLAttributes: {
          class: 'text-blue-500 hover:underline cursor-pointer',
        },
      })
    ],
    content: initialContent,
    editorProps: {
      attributes: {
        class: 'prose max-w-none focus:outline-none',
        placeholder: contentPlaceholder,
      },
    },
  });

  return (
    <Stack spacing="md">
      <TextInput
        label="Title"
        required
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />



{isCompetitionDiscussion && competitionId && (
  <Autocomplete
    label="Link to Agent (Optional)"
    placeholder="Search for an agent"
    value={searchValue}
    onChange={setSearchValue}
    {...(isSearching ? { loading: true } : {})}
    data={searchResults.map(agent => ({
      value: agent.id.toString(),
      label: `${agent.agent_name} by ${agent.user_name}`
    }))}
    onOptionSubmit={(value) => {
      const agent = searchResults.find(a => a.id.toString() === value);
      if (agent) {
        setSelectedAgent(agent);
        setSearchValue(`${agent.agent_name} by ${agent.user_name}`);
      }
    }}
  />
)}
      <RichTextEditor editor={editor} className="min-h-[200px]">
        <Group justify="space-between" p="xs" className="bg-gray-50 border-b">
          <Group spacing="xs">
            <RichTextEditor.ControlsGroup>
              <RichTextEditor.Bold />
              <RichTextEditor.Italic />
            </RichTextEditor.ControlsGroup>

            <RichTextEditor.ControlsGroup>
              <RichTextEditor.H2 />
              <RichTextEditor.H3 />
            </RichTextEditor.ControlsGroup>

            <RichTextEditor.ControlsGroup>
              <RichTextEditor.BulletList />
              <RichTextEditor.OrderedList />
            </RichTextEditor.ControlsGroup>

            <RichTextEditor.ControlsGroup>
              <RichTextEditor.CodeBlock />
              <RichTextEditor.Blockquote />
            </RichTextEditor.ControlsGroup>

            <RichTextEditor.ControlsGroup>
              <RichTextEditor.Link />
            </RichTextEditor.ControlsGroup>
          </Group>
        </Group>

        <RichTextEditor.Content />
      </RichTextEditor>

      <Group justify="flex-end" mt="md">
        {onCancel && (
          <Button variant="subtle" onClick={onCancel}>
            Cancel
          </Button>
        )}
        <Button
          onClick={handleSubmit}
          disabled={!title.trim() || !editor?.getText()?.trim()}
        >
          {submitLabel}
        </Button>
      </Group>
    </Stack>
  );
};

export default DiscussionEditor;