import React from 'react';
import { Container, Title, Text, Button, Group, Card, Image, SimpleGrid, List, Grid, ThemeIcon, Avatar } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { landingStyles } from 'theme/landingStyles';
import { StyledButton } from 'theme/buttonStyles';
import { Info } from 'lucide-react';
import Footer from "components/Footer";

const HeroSection = () => {
  const navigate = useNavigate();

  return (
    <div style={landingStyles.heroSection.root}>
      <Container size="xl" style={landingStyles.container}>
        <Grid align="center" pt={80}>
          <Grid.Col span={{ base: 12, md: 6 }}>
            <Title style={landingStyles.sectionTitle}>
              Elevate Your Skills and Advance in Machine Learning
            </Title>
            <Text style={landingStyles.sectionText}>
              Join a global hub for data experts and AI enthusiasts. Upload your agents, 
              test their skills, and climb the leaderboard in real-time machine learning competitions.
            </Text>
            <Group>
            <StyledButton
              size="lg"
              variant="primary"
              onClick={() => navigate('/listcompetition')}
            >
              Join competition
            </StyledButton>
            
            <StyledButton
              size="lg"
              variant="secondary"
              onClick={() => navigate('/login')}
            >
              Sign in
            </StyledButton>
          </Group>
          </Grid.Col>
          
          <Grid.Col span={{ base: 12, md: 6 }} style={{ display: 'flex', justifyContent: 'center' }}>
            <Image
              src="/ai_brain.png"
              alt="AI Brain Visualization"
              style={landingStyles.images.section}
              fit="contain"
            />
          </Grid.Col>
        </Grid>
      </Container>
    </div>
  );
};

const WhatIsSection = () => {
  return (
    <div style={landingStyles.sections.light}>
      <Container size="xl" style={landingStyles.container}>
        <Grid align="center" gutter={64}>
          <Grid.Col span={{ base: 12, md: 6 }}>
            <Title style={landingStyles.sectionTitle}>
              What is ML-Arena?
            </Title>
            <Text style={landingStyles.sectionText}>
              ML-Arena is a dynamic platform designed for data experts and AI 
              enthusiasts, offering multiplayer real-time machine learning challenges where 
              users can deploy intelligent agents, test their strategies, and compete 
              to solve exciting, cutting-edge problems while improving their skills.
            </Text>
          </Grid.Col>
          
          <Grid.Col span={{ base: 12, md: 6 }} style={{ display: 'flex', justifyContent: 'center' }}>
            <Image
              src="/ai_chess.jpeg"
              alt="AI Chess Robot"
              style={landingStyles.images.section}
              fit="contain"
            />
          </Grid.Col>
        </Grid>
      </Container>
    </div>
  );
};

const ForSection = () => {
  const items = [
    {
      title: 'AI Professionals',
      description: 'Showcase your expertise and solve cutting-edge challenges.',
      icon: '/ds.jpeg'
    },
    {
      title: 'Researchers',
      description: 'Benchmark algorithms and validate hypotheses in reproducible environments.',
      icon: '/entou.jpeg'
    },
    {
      title: 'Students & Beginners',
      description: 'Build foundational skills with step-by-step guidance.',
      icon: '/stud.jpeg'
    }
  ];

  return (
    <div style={landingStyles.sections.light}>
      <Container size="xl" style={landingStyles.container}>
        <Title style={landingStyles.sectionTitle} className="text-center md:text-left">
          Who is ML Arena for?
        </Title>
        <SimpleGrid 
          cols={{ base: 1, sm: 2, md: 3 }} 
          spacing={{ base: 24, md: 48 }}
          verticalSpacing={{ base: 24, md: 48 }}
        >
          {items.map((item, index) => (
            <Card 
              key={index} 
              styles={{ root: landingStyles.card.root }}
              className="flex flex-col items-center md:items-start text-center md:text-left"
            >
              <div style={landingStyles.card.content} className="w-full">
                <Image
                  src={item.icon}
                  alt={item.title}
                  style={landingStyles.images.icon}
                  className="mx-auto md:mx-0"
                />
                <Title 
                  order={3} 
                  style={landingStyles.card.title}
                  className="mt-4 mb-2"
                >
                  {item.title}
                </Title>
                <Text 
                  style={landingStyles.card.description}
                  className="max-w-sm mx-auto md:mx-0"
                >
                  {item.description}
                </Text>
              </div>
            </Card>
          ))}
        </SimpleGrid>
      </Container>
    </div>
  );
};

const HowSection = () => {
  const steps = [
    {
      title: 'Join a Competition',
      description: 'Browse exciting real-time, multiplayer, meta-learning challenges and pick the one that suits your skills and interests.'
    },
    {
      title: 'Create your Agent',
      description: 'Upload your code and models to define your agent\'s behavior and strategy.'
    },
    {
      title: 'Track Your Progress on the Leaderboard',
      description: 'Monitor your agent interaction, performance, climb the ranks, and compete for the top spot.'
    }
  ];

  return (
    <div style={landingStyles.sections.light}>
      <Container size="xl" style={landingStyles.container}>
        <Grid gutter={64} align="center">
          {/* Left column with image */}
          <Grid.Col span={{ base: 12, md: 6 }}>
            <Image
              src="/robot.jpeg"
              alt="Robot Assistant"
              style={landingStyles.images.robot}
            />
          </Grid.Col>
          
          {/* Right column with content */}
          <Grid.Col span={{ base: 12, md: 6 }}>
            <div style={landingStyles.how.titleWrapper}>
              <Title style={landingStyles.how.title}>
                How does it work?
              </Title>
              <Info style={landingStyles.how.infoIcon} />
            </div>

            <div style={landingStyles.how.steps}>
              {steps.map((step, index) => (
                <div key={index} style={landingStyles.how.step}>
                  <div style={landingStyles.how.stepNumber}>
                    {index + 1}
                  </div>
                  <div style={landingStyles.how.stepContent}>
                    <Text style={landingStyles.how.stepTitle}>
                      {step.title}
                    </Text>
                    <Text style={landingStyles.how.stepDescription}>
                      {step.description}
                    </Text>
                  </div>
                </div>
              ))}
            </div>
          </Grid.Col>
        </Grid>
      </Container>
    </div>
  );
};



const WhySection = () => {
  const features = [
    {
      title: 'Learn and Grow',
      description: 'Perfect your skills through hands-on experience and real-time feedback.',
      icon: '/learn-grow.jpeg'
    },
    {
      title: 'Compete and Win',
      description: 'Join thrilling challenges and climb the leaderboards.',
      icon: '/compete-win.jpeg'
    },
    {
      title: 'Advance your Career',
      description: 'Showcase your talent to recruiters and industry leaders.',
      icon: '/career.jpeg'
    }
  ];

  return (
    <div style={landingStyles.why.section}>
      <Container size="xl">
        <Title style={landingStyles.why.title}>
          Why should different users join it?
        </Title>
        
        <Text style={landingStyles.why.subtitle}>
          Users join ML-Arena to compete in machine learning challenges, 
          enhance their skills, connect with a vibrant community, and showcase their 
          expertise to advance their careers.
        </Text>

        <SimpleGrid 
          cols={{ base: 1, sm: 2, md: 3 }} 
          spacing="xl"
          style={landingStyles.why.grid}
        >
          {features.map((feature, index) => (
            <Card 
              key={index}
              style={landingStyles.why.card.root}
            >
              <Avatar 
                size={128} 
                src={feature.icon}
                alt={feature.title}
                radius="50%"
                style={{ marginBottom: '1.5rem' }}
              />
              
              <Title 
                order={3} 
                style={landingStyles.why.feature.title}
              >
                {feature.title}
              </Title>
              
              <Text style={landingStyles.why.feature.description}>
                {feature.description}
              </Text>
            </Card>
          ))}
        </SimpleGrid>
      </Container>
    </div>
  );
};

const CompetitionsSection = () => {
  const navigate = useNavigate();
  return (
    <div style={landingStyles.sections.light}>
      <Container size="xl" style={landingStyles.container}>
        <Grid gutter={64} align="center">
          {/* Left column with content */}
          <Grid.Col span={{ base: 12, md: 6 }}>
            <Title style={landingStyles.sectionTitle}>
              Our Competitions
            </Title>
            
            <Text style={landingStyles.sectionText}>
              Explore our exciting games designed to challenge your AI skills. 
              Choose a competition, deploy your agent, and see how it performs 
              against others in real-time!
            </Text>
            
            <StyledButton
  size="lg"
  variant="primary"
  onClick={() => navigate('/listcompetition')}
>
  Explore competitions
</StyledButton>
          </Grid.Col>
          
          {/* Right column with competition previews */}
          <Grid.Col span={{ base: 12, md: 6 }}>
            <Grid gutter="lg">
              <Grid.Col span={6}>
                <div className="relative rounded-lg overflow-hidden border-2 border-gray-700">
                  <Image
                    src="/classic_chess.gif"
                    alt="Chess Competition"
                    className="w-full h-48 object-cover"
                  />
                  <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-75 p-3">
                    <Text className="text-white font-medium">Classic Chess</Text>
                  </div>
                </div>
              </Grid.Col>
              
              <Grid.Col span={6}>
                <div className="relative rounded-lg overflow-hidden border-2 border-gray-700">
                  <Image
                    src="/atari_pong.gif"
                    alt="Pong Competition"
                    className="w-full h-48 object-cover"
                  />
                  <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-75 p-3">
                    <Text className="text-white font-medium">Atari Pong</Text>
                  </div>
                </div>
              </Grid.Col>
            </Grid>
          </Grid.Col>
        </Grid>
      </Container>
    </div>
  );
};


const YourSolutionsSection = () => {
  const navigate = useNavigate();

  return (
    <div style={landingStyles.sections.light}>
      <Container size="xl" style={landingStyles.container}>
        <Grid gutter={64} align="center">
          {/* Left column with image */}
          <Grid.Col span={{ base: 12, md: 6 }}>
            <div className="relative rounded-xl overflow-hidden shadow-lg">
              <Image
                src="/solution.jpeg"
                alt="Data Visualization"
                className="w-full h-96 object-cover"
                styles={{
                  image: {
                    filter: 'brightness(1.1) contrast(1.1)'
                  }
                }}
              />
              <div className="absolute inset-0 bg-gradient-to-tr from-blue-500/20 to-purple-500/20" />
            </div>
          </Grid.Col>
          
          {/* Right column with content */}
          <Grid.Col span={{ base: 12, md: 6 }}>
            <div className="space-y-6">
              <Title style={landingStyles.sectionTitle}>
                Your Solutions
              </Title>
              
              <Text style={landingStyles.sectionText}>
                Browse the highest voted solutions to the forums in our community. 
                Learn from others' approaches and share your own innovative strategies 
                to solve complex AI challenges.
              </Text>
              
              <StyledButton
  size="lg"
  variant="primary"
  onClick={() => navigate('/general-discussions')}
>
  Explore discussions
</StyledButton>
            </div>
          </Grid.Col>
        </Grid>
      </Container>
    </div>
  );
};

const UserRankingSection = () => {
  const navigate = useNavigate();

  return (
    <div style={landingStyles.sections.light}>
      <Container size="xl" style={landingStyles.container}>
        <Grid gutter={64} align="center">
          {/* Left column with content */}
          <Grid.Col span={{ base: 12, md: 6 }}>
            <Title style={landingStyles.sectionTitle}>
              User Rankings
            </Title>
            
            <Text style={landingStyles.sectionText}>
              Track your progress and compete with other developers! Check the rankings 
              of your agents in the Leaderboard and see how your strategies stack up.
            </Text>
            
            <StyledButton
  size="lg"
  variant="primary"
  onClick={() => navigate('/ranking')}
>
  Check your User Ranking
</StyledButton>
          </Grid.Col>
          
          {/* Right column with achievement icons */}
          <Grid.Col span={{ base: 12, md: 6 }}>
            <Grid gutter="xl">
              <Grid.Col span={6}>
                <div className="bg-white rounded-xl p-6 shadow-lg text-center">
                  <div className="flex justify-center mb-4">
                    <Image
                      src="/Trophy.jpeg"
                      alt="Achievement Trophy"
                      className="w-32 h-32 object-contain"
                    />
                  </div>
                  <Text className="font-medium text-gray-900">
                    Compete for Top Rankings
                  </Text>
                  <Text className="text-gray-600 mt-2">
                    Rise through the ranks and showcase your AI expertise
                  </Text>
                </div>
              </Grid.Col>
              
              <Grid.Col span={6}>
                <div className="bg-white rounded-xl p-6 shadow-lg text-center">
                  <div className="flex justify-center mb-4">
                    <Image
                      src="/Handshake.jpeg"
                      alt="Community Handshake"
                      className="w-32 h-32 object-contain"
                    />
                  </div>
                  <Text className="font-medium text-gray-900">
                    Join the Community
                  </Text>
                  <Text className="text-gray-600 mt-2">
                    Connect with fellow AI enthusiasts and share strategies
                  </Text>
                </div>
              </Grid.Col>
            </Grid>
          </Grid.Col>
        </Grid>
      </Container>
    </div>
  );
};





const Landing = () => {

  return (
    <div className="min-h-screen">
<HeroSection/>

<WhatIsSection/>

<ForSection/>

<HowSection/>

<WhySection/>

<CompetitionsSection/>

<YourSolutionsSection/>

<UserRankingSection/>

<Footer/>
    </div>

  );
};

export default Landing;