import React from 'react';
import { Box } from '@mantine/core';
import styles from 'theme/CompetitionProgress.module.css';


const CompetitionProgress = ({ startTime, endTime }) => {
  const now = new Date();
  const start = new Date(startTime);
  const end = new Date(endTime);
  
  const total = end.getTime() - start.getTime();
  const elapsed = now.getTime() - start.getTime();
  const progress = Math.min(Math.max(elapsed / total, 0), 1);

  const formatTimeAgo = (date) => {
    const monthsAgo = Math.abs(Math.round((now - date) / (1000 * 60 * 60 * 24 * 30)));
    return `${monthsAgo} month${monthsAgo !== 1 ? 's' : ''} ${now > date ? 'ago' : 'to go'}`;
  };

  // Determine if "now" is near the start or end
  const isNearStart = progress < 0.1;
  const isNearEnd = progress > 0.9;

  const getNowStyles = () => {
    let leftPosition = `${progress * 100}%`;
    let additionalClass = '';

    if (isNearStart) {
      additionalClass = styles.nowNearStart;
    } else if (isNearEnd) {
      additionalClass = styles.nowNearEnd;
    }

    return {
      position: leftPosition,
      className: additionalClass
    };
  };

  const nowStyles = getNowStyles();

  return (
    <Box className={styles.progressContainer}>
      {/* Labels and Dates */}
      {/* Start */}
      <span className={styles.labelStart}>Start</span>
      <span className={styles.dateStart}>{formatTimeAgo(start)}</span>

      {/* Now - above the line */}
      <span 
        className={`${styles.labelNow} ${nowStyles.className}`} 
        style={{ left: nowStyles.position }}
      >
        {Math.round(progress * 100)}%
      </span>
      <span 
        className={`${styles.dateNow} ${nowStyles.className}`}
        style={{ left: nowStyles.position }}
      >
        Now
      </span>

      {/* End */}
      <span className={styles.labelEnd}>End</span>
      <span className={styles.dateEnd}>{formatTimeAgo(end)}</span>

      {/* Progress Bar */}
      <div className={styles.progressLine}>
        {/* Filled line */}
        <div 
          className={styles.progressFill} 
          style={{ width: `${progress * 100}%` }} 
        />
        
        {/* Dashed line */}
        <div 
          className={styles.dashedLine} 
          style={{ width: `${100 - (progress * 100)}%` }} 
        />

        {/* Dots */}
        <div className={styles.startDot} />
        <div 
          className={styles.nowDot} 
          style={{ left: nowStyles.position }} 
        />
        <div className={styles.endDot} />
      </div>
    </Box>
  );
};

export default CompetitionProgress;