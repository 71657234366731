import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Container, 
  SimpleGrid, 
  Card, 
  Text, 
  Button, 
  Group,  
  Badge, 
  Image, 
  AspectRatio,
  Title,
  Paper,
  Stack
} from '@mantine/core';
import { 
  Plus, 
  Users, 
  Clock,
  Trophy,
  Archive
} from 'lucide-react';
import { useAuth } from '../../context/AuthContext';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

const CompetitionCard = ({ competition, onClick }) => {
  const { 
    name, 
    description, 
    miniature, 
    number_of_agents, 
    programmed_end_at_ts, 
    tags = []
  } = competition;

  const getTimeRemaining = (endTime) => {
    if (!endTime) return 'No end time set';
    const now = dayjs();
    const end = dayjs(endTime);
    if (end.isBefore(now)) return 'Ended';
    return end.from(now, true) + ' left';
  };

  return (
    <Card 
      shadow="sm" 
      padding="lg" 
      radius="md" 
      withBorder 
      onClick={onClick} 
      className="hover:shadow-lg transition-shadow duration-200 cursor-pointer"
    >
      <Card.Section>
        <AspectRatio ratio={16/9}>
          <Image
            src={miniature ? `${process.env.REACT_APP_PREFIX_BACKEND}${miniature}` : '/placeholder-competition.png'}
            alt={name}
            className="object-cover"
          />
        </AspectRatio>
      </Card.Section>

      <Stack spacing="xs" mt="md">
        <Text weight={500} size="lg" lineClamp={1}>{name}</Text>
        
        <Text size="sm" color="dimmed" lineClamp={2}>
          {description}
        </Text>

        <Group spacing={5}>
          {tags.slice(0, 3).map((tag, index) => (
            <Badge key={index} variant="light" size="sm" color="blue">
              {tag.name}
            </Badge>
          ))}
          {tags.length > 3 && (
            <Badge variant="light" size="sm" color="blue">+{tags.length - 3}</Badge>
          )}
        </Group>

        <Group spacing="xs" position="apart">
          <Group spacing={4}>
            <Users size={16} />
            <Text size="sm">{number_of_agents || 'N/A'} players</Text>
          </Group>
          <Group spacing={4}>
            <Clock size={16} />
            <Text size="sm">{getTimeRemaining(programmed_end_at_ts)}</Text>
          </Group>
        </Group>
      </Stack>
    </Card>
  );
};

const ListCompetition = () => {
  const [selectedTags, setSelectedTags] = useState([]);
  const [competitions, setCompetitions] = useState({ active: [], recently_ended: [] });
  const [allTags, setAllTags] = useState([]);
  const navigate = useNavigate();
  const { isAdmin } = useAuth();

  useEffect(() => {
    const fetchCompetitions = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_PREFIX_BACKEND}/api/competitions/`, {
          credentials: 'include',
        });
        const data = await response.json();
        setCompetitions(data);

        // Extract unique tags
        const tags = new Set();
        [...data.active, ...data.recently_ended].forEach(comp => 
          comp.tags.forEach(tag => tags.add(tag.name))
        );
        setAllTags(Array.from(tags));
      } catch (error) {
        console.error('Error fetching competitions:', error);
      }
    };

    fetchCompetitions();
  }, []);

  const handleTagToggle = (tag) => {
    setSelectedTags(prev => 
      prev.includes(tag)
        ? prev.filter(t => t !== tag)
        : [...prev, tag]
    );
  };

  const filterCompetitionsByTags = (comps) => {
    if (selectedTags.length === 0) return comps;
    return comps.filter(comp => 
      comp.tags.some(tag => selectedTags.includes(tag.name))
    );
  };

  return (
    <Container size="xl" py="xl">
      <Stack spacing="xl">
        {/* Hero Section */}
        <Paper radius="md" p="xl">
        <Group align="flex-start" justify="space-between">
          <Stack spacing="lg" style={{ width: '50%' }}>
              <div>
                <Title order={1}>Competitions</Title>
                <Text size="lg" mt="sm" color="dimmed">
                  Dive into a thrilling competition where you can showcase your agent's abilities. 
                  Each challenge is designed to test your agent's performance. 
                  Read the competition details to understand the rules and objectives.
                </Text>
              </div>

              <Group spacing={8}>
                {allTags.map((tag) => (
                  <Badge
                    key={tag}
                    size="lg"
                    color="blue"
                    variant={selectedTags.includes(tag) ? "filled" : "light"}
                    onClick={() => handleTagToggle(tag)}
                    className="cursor-pointer"
                  >
                    {tag}
                  </Badge>
                ))}
              </Group>

              {isAdmin && (
                <Button
                  leftSection={<Plus size={18} />}
                  onClick={() => navigate('/editcompetition')}
                >
                  Create New
                </Button>
              )}
            </Stack>

            <Image 
              src="/comps.png"
              alt="Competitions"
              radius="md"
              style={{ width: '30%', height: 'auto' }}
            />
          </Group>
        </Paper>

        {/* Active Competitions */}
        {filterCompetitionsByTags(competitions.active).length > 0 && (
          <Stack spacing="md">
            <Group spacing="xs">
              <Trophy size={24} className="text-blue-600" />
              <Title order={2}>Live Challenges</Title>
            </Group>
            
            <SimpleGrid
              cols={3}
              spacing="lg"
              breakpoints={[
                { maxWidth: 'md', cols: 2, spacing: 'md' },
                { maxWidth: 'sm', cols: 2, spacing: 'sm' },
                { maxWidth: 'xs', cols: 1, spacing: 'sm' },
              ]}
            >
              {filterCompetitionsByTags(competitions.active).map((competition) => (
                <CompetitionCard
                  key={competition.id}
                  competition={competition}
                  onClick={() => navigate(`/viewcompetition/${competition.id}`)}
                />
              ))}
            </SimpleGrid>
          </Stack>
        )}

        {/* Past Competitions */}
        {filterCompetitionsByTags(competitions.recently_ended).length > 0 && (
          <Stack spacing="md">
            <Group spacing="xs">
              <Archive size={24} className="text-gray-600" />
              <Title order={2}>Past Challenges</Title>
            </Group>
            
            <SimpleGrid
              cols={3}
              spacing="lg"
              breakpoints={[
                { maxWidth: 'md', cols: 2, spacing: 'md' },
                { maxWidth: 'sm', cols: 2, spacing: 'sm' },
                { maxWidth: 'xs', cols: 1, spacing: 'sm' },
              ]}
            >
              {filterCompetitionsByTags(competitions.recently_ended).map((competition) => (
                <CompetitionCard
                  key={competition.id}
                  competition={competition}
                  onClick={() => navigate(`/viewcompetition/${competition.id}`)}
                />
              ))}
            </SimpleGrid>
          </Stack>
        )}
      </Stack>
    </Container>
  );
};

export default ListCompetition;