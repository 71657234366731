import React, { useState, useEffect } from 'react';
import { 
  Card,
  Title,
  Group,
  MultiSelect,
  Stack,
  Button,
  LoadingOverlay,
  Text
} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { Tags } from 'lucide-react';
import axios from 'axios';

const CompetitionTagsSection = ({ competitionId }) => {
  const [loading, setLoading] = useState(false);
  const [availableTags, setAvailableTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);

  // Fetch all available tags and competition's current tags
  const fetchData = async () => {
    try {
      setLoading(true);
      const [tagsResponse, competitionTagsResponse] = await Promise.all([
        axios.get('/api/competition_tags/tags'),
        axios.get(`/api/competition_tags/competitions/${competitionId}/tags`)
      ]);

      setAvailableTags(tagsResponse.data.map(tag => ({
        value: tag.id.toString(),
        label: tag.name,
        description: tag.description
      })));

      setSelectedTags(competitionTagsResponse.data.map(tag => 
        tag.id.toString()
      ));
    } catch (error) {
      notifications.show({
        title: 'Error',
        message: 'Failed to fetch tags',
        color: 'red'
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (competitionId) {
      fetchData();
    }
  }, [competitionId]);

  const handleSave = async () => {
    try {
      setLoading(true);
      await axios.post(`/api/competition_tags/competitions/${competitionId}/tags`, {
        tag_ids: selectedTags.map(id => parseInt(id))
      });
      
      notifications.show({
        title: 'Success',
        message: 'Competition tags updated successfully',
        color: 'green'
      });
    } catch (error) {
      notifications.show({
        title: 'Error',
        message: 'Failed to update competition tags',
        color: 'red'
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card withBorder>
      <div style={{ position: 'relative' }}>
        <LoadingOverlay visible={loading} blur={2} />
        
        <Stack spacing="md">
          <Group position="apart">
            <Title order={4}>Competition Tags</Title>
          </Group>

          <Text size="sm" color="dimmed">
            Select tags that best describe this competition. Tags help users find and filter competitions.
          </Text>

          <MultiSelect
            data={availableTags}
            value={selectedTags}
            onChange={setSelectedTags}
            label="Tags"
            placeholder="Select tags"
            searchable
            clearable
            icon={<Tags size={14} />}
          />

          <Group position="right">
            <Button onClick={handleSave}>
              Save Tags
            </Button>
          </Group>
        </Stack>
      </div>
    </Card>
  );
};

export default CompetitionTagsSection;