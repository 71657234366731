// src/theme/competitionViewStyles.js
export const competitionViewStyles = {
  tabsContainer: {
    backgroundColor: 'var(--mantine-color-gray-0)',
    width: '100%',
    padding: '1rem 0',
    borderBottom: '1px solid var(--mantine-color-gray-2)'
  },
  tabsList: {
    flex: 1,
    gap: 0
  },
  editButton: {
    marginLeft: 'auto'
  },
  contentContainer: {
    backgroundColor: 'var(--mantine-color-white)',
    minHeight: '25rem',
    paddingTop: '2rem'
  }
};