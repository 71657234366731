import { useEffect, useState, useRef } from "react";
import { io } from "socket.io-client";
import axios from 'axios';

export const useVideoReplayStream = (competitionId) => {
  const socket = useRef(null);
  const [isConnected, setIsConnected] = useState(false);
  const [replaySessionId, setReplaySessionId] = useState(null);
  const [verifyStatus, setVerifyStatus] = useState({ 
    loading: false, 
    error: null,
    details: null
  });

  const verifyAndStartStream = async (gcsPath) => {
    if (!gcsPath) {
      throw new Error('GCS path is required');
    }

    setVerifyStatus({ loading: true, error: null, details: null });
    
    try {
      // Step 1: Verify file access and get replay session ID
      const verifyResponse = await axios({
        method: 'get',
        url: `${process.env.REACT_APP_PREFIX_SOCKET}/node-api/files/verify`,
        params: { path: gcsPath },
        withCredentials: true,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        timeout: 30000,
      });

      if (verifyResponse.data?.status !== 'success') {
        throw new Error(verifyResponse.data?.message || 'Verification failed');
      }

      const { replaySessionId } = verifyResponse.data;
      setReplaySessionId(replaySessionId);

      // Step 2: Connect to unique replay namespace
      const socketUrl = `${process.env.REACT_APP_PREFIX_SOCKET}/replay-${replaySessionId}`;
      socket.current = io(socketUrl, {
        path: '/socket/',
        transports: ['websocket'],
      });

      // Step 3: Set up socket event handlers
      socket.current.on('connect', () => {
        setIsConnected(true);
      });

      socket.current.on('disconnect', () => {
        setIsConnected(false);
      });

      // Step 4: Initiate streaming
      const streamResponse = await axios({
        method: 'post',
        url: `${process.env.REACT_APP_PREFIX_SOCKET}/node-api/files/stream`,
        data: {
          filePath: gcsPath,
          competitionId,
          replaySessionId
        },
        withCredentials: true,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      });

      setVerifyStatus({ 
        loading: false, 
        error: null,
        details: {
          request: verifyResponse.data.request,
          metadata: verifyResponse.data.metadata,
          path: gcsPath,
          replaySessionId
        }
      });

      return {
        status: 'success',
        replaySessionId,
        socket: socket.current
      };

    } catch (error) {
      const errorMessage = error.response?.data?.message || 
                          error.message || 
                          'Failed to verify file access';
      
      setVerifyStatus({ 
        loading: false, 
        error: errorMessage, 
        details: null 
      });
      throw error;
    }
  };

  const cleanup = () => {
    if (socket.current) {
      socket.current.close();
      setIsConnected(false);
    }
  };

  useEffect(() => {
    return cleanup;
  }, []);

  return {
    verifyAndStartStream,
    socket: socket.current,
    isConnected,
    verifyStatus,
    cleanup
  };
};