import React, { useEffect, useState } from 'react';
import { Box, Container, Group, Stack, Text, Image, Badge, Skeleton, Button } from '@mantine/core';
import { Users, Clock } from 'lucide-react';
import { StyledButton } from 'theme/buttonStyles';
import { headerStyles as styles } from 'theme/competitionHeaderStyles';
import axios from 'axios';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

const CompetitionHeader = ({ competitionId, handleJoinCompetition, isAdmin, handleEditCompetition }) => {
  const [competition, setCompetition] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCompetition = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_PREFIX_BACKEND}/api/competitions/${competitionId}`);
        setCompetition(response.data);
      } catch (error) {
        console.error('Error fetching competition:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchCompetition();
  }, [competitionId]);

  const getTimeRemaining = (endTime) => {
    if (!endTime) return 'No end time set';
    const now = dayjs();
    const end = dayjs(endTime);
    if (end.isBefore(now)) return 'Ended';
    return end.from(now, true) + ' left';
  };

  if (loading) {
    return (
      <Box style={styles.wrapper}>
        <Container size="lg">
          <Group justify="space-between" align="flex-start">
            <Skeleton height={160} width="70%" />
            <Box style={styles.rightContent}>
              <Skeleton height={220} width={220} radius="md" />
              <Skeleton height={42} width={220} radius="md" />
            </Box>
          </Group>
        </Container>
      </Box>
    );
  }

  if (!competition) return null;

  return (
    <Box style={styles.wrapper}>
      <Container size="lg">
        <Group justify="space-between" align="flex-start">
          {/* Left side - Competition Info */}
          <Stack spacing="md" className="flex-1">
            <Text style={styles.title}>
              {competition.name}
            </Text>
            
            <Group spacing="lg">
              <Group spacing="xs">
                <Users size={24} className="text-blue-600" /> {/* Increased icon size */}
                <Text size="lg" c="dimmed">
                  {competition.number_of_agents === 1 ? 'Single-Player' : 'Multi-Player'}
                </Text>
              </Group>
              
              <Group spacing="xs">
                <Clock size={24} className="text-blue-600" /> {/* Increased icon size */}
                <Text size="lg" c="dimmed">
                  {getTimeRemaining(competition.programmed_end_at_ts)}
                </Text>
              </Group>
            </Group>
  
            {competition.tags && (
              <Group mt="xs">
                {competition.tags.map((tag) => (
                  <Badge 
                    key={tag.id}
                    variant="light"
                    color="blue"
                    size="lg"
                  >
                    {tag.name}
                  </Badge>
                ))}
              </Group>
            )}
          </Stack>

          {/* Right side - Updated structure */}
          <Box style={styles.rightContent}>
            <Box style={styles.miniatureCard}>
              <Image
                src={competition.miniature 
                  ? `${process.env.REACT_APP_PREFIX_BACKEND}${competition.miniature}`
                  : "/api/placeholder/220/220"
                }
                alt={competition.name}
                style={styles.miniatureImage}
              />
            </Box>
            <Box style={styles.submitButtonWrapper}>
              <StyledButton
                size="md"
                variant="primary"
                onClick={handleJoinCompetition}
              >
                Submit Agent
              </StyledButton>
            </Box>
            <Box style={styles.submitButtonWrapper}>
                        {isAdmin && (
                          <Button 
                            onClick={handleEditCompetition}
                            variant="outline"
                            style={styles.editButton}
                          >
                            Edit Competition
                          </Button>
                        )}
                        </Box>
          </Box>
        </Group>
      </Container>
    </Box>
  );
};

export default CompetitionHeader;