import { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';

const POLLING_STATUSES = ['uploading', 'deploying', 'deploy_queue', 'deploy_run'];
const POLLING_INTERVAL = 1000;

const initialAttachmentState = {
  name: "",
  status: "",
  last_status_message: "",
  queue_info: {},
  run_info: {}
};

export const useAgentAttachment = (competitionId, attachmentId) => {
  const [isFetching, setIsFetching] = useState(true);
  const [error, setError] = useState(null);
  const [attachment, setAttachment] = useState(initialAttachmentState);
  const [temporaryStatus, setTemporaryStatus] = useState(null);
  const [competitionInfo, setCompetitionInfo] = useState(null);
  
  // Use useRef instead of state for the timer
  const pollTimerRef = useRef(null);

  // Cleanup function
  const cleanupPolling = useCallback(() => {
    if (pollTimerRef.current) {
      clearTimeout(pollTimerRef.current);
      pollTimerRef.current = null;
    }
  }, []);

  const handleTemporaryStatus = useCallback((isUploading) => {
    if (isUploading) {
      setTemporaryStatus({
        status: 'uploading',
        last_status_message: 'Uploading files...'
      });
    } else {
      setTemporaryStatus(null);
    }
  }, []);

  const getCurrentAttachment = useCallback(() => {
    if (temporaryStatus) {
      return {
        ...attachment,
        status: temporaryStatus.status,
        last_status_message: temporaryStatus.last_status_message
      };
    }
    return attachment;
  }, [temporaryStatus, attachment]);

  const fetchAttachment = useCallback(async () => {
    try {
      const response = await axios.get(
        `/api/direct_attache_agents/competition/${competitionId}/${attachmentId}/status`
      );
      
      const attachmentData = response.data.attachment || response.data;
      setAttachment(attachmentData);
      setError(null);

      // Handle polling based on status
      if (POLLING_STATUSES.includes(attachmentData?.status)) {
        cleanupPolling(); // Clean up existing timer before setting new one
        pollTimerRef.current = setTimeout(() => {
          fetchAttachment();
        }, POLLING_INTERVAL);
      } else {
        cleanupPolling();
      }

      return attachmentData;
    } catch (err) {
      setError(err.response?.data?.error || 'Failed to fetch attachment status');
      cleanupPolling();
      throw err;
    } finally {
      setIsFetching(false);
    }
  }, [competitionId, attachmentId, cleanupPolling]);

  const handleDeleteAttach = async () => {
    try {
      await axios.delete(
        `/api/direct_attache_agents/competition/${competitionId}/${attachmentId}`
      );
      window.location.href = `/viewcompetition/${competitionId}`;
    } catch (err) {
      setError(err.response?.data?.error || 'Failed to delete attachment');
      throw err;
    }
  };

  // Fetch competition info
  useEffect(() => {
    const fetchCompetition = async () => {
      try {
        setIsFetching(true);
        const response = await axios.get(`/api/competitions/${competitionId}`);
        setCompetitionInfo(response.data);
      } catch (error) {
        console.error('Error fetching competition:', error);
      } finally {
        setIsFetching(false);
      }
    };

    fetchCompetition();
  }, [competitionId]);

  // Initial fetch and cleanup
  useEffect(() => {
    fetchAttachment();

    // Cleanup on unmount
    return () => {
      cleanupPolling();
    };
  }, [fetchAttachment, cleanupPolling]);

  return {
    attachment: getCurrentAttachment(),
    isFetching,
    error,
    fetchAttachment,
    handleDeleteAttach,
    handleTemporaryStatus,
    competitionInfo
  };
};

export default useAgentAttachment;