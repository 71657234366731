import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Container, Button, Group, Stack, Tabs, Text, Box } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useAuth } from 'context/AuthContext';
import NameYourAgentModal from 'components/DirectAgentAttach/NameYourAgentModal';
import CompetitionHeader from 'components/CompetitionHeader';
import Overview from './Overview';
import GettingStarted from './GettingStarted';
import LeaderboardTab from './LeaderboardTab';
import Discussions from './Discussions';
import TeamTab from './TeamTab';
import { competitionViewStyles as styles } from 'theme/competitionViewStyles';
import 'theme/competitionTabs.css';

const ViewCompetition = () => {

  
  const { currentUser, isAdmin, isAuthenticated, refreshAuthState } = useAuth();
  const { competitionId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [opened, { open, close }] = useDisclosure(false);
  const [competitionInfo, setCompetitionData] = useState({});
  const [environmentInfo, setEnvironmentInfo] = useState({});
  const [selectedImage, setSelectedImage] = useState(null);
  
  // Get the default tab from navigation state or use 'overview'
  const defaultTab = location.state?.defaultTab || 'overview';
  const [activeTab, setActiveTab] = useState(defaultTab);

  useEffect(() => {
    // Update active tab when location state changes
    if (location.state?.defaultTab) {
      setActiveTab(location.state.defaultTab);
    }
  }, [location.state]);

  useEffect(() => {
    const checkAuth = async () => {
      await refreshAuthState();
    };
    checkAuth();
  }, [refreshAuthState]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch competition data
        const competitionResponse = await fetch(
          `${process.env.REACT_APP_PREFIX_BACKEND}/api/competitions/${competitionId}`,
          {
            credentials: 'include',
          }
        );
        const competitionData = await competitionResponse.json();
  
        // Set initial selected image if available
        if (competitionData.runtime_options?.length > 0 && !selectedImage) {
          setSelectedImage(competitionData.runtime_options[0].id.toString());
        }
        
        setCompetitionData(competitionData);
  
        // Fetch environment data
        const envResponse = await fetch(
          `${process.env.REACT_APP_PREFIX_BACKEND}/api/attaches/environment/competition/${competitionId}`,
          {
            credentials: 'include',
          }
        );
        const envData = await envResponse.json();
        setEnvironmentInfo(envData);
  
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, [competitionId, selectedImage]);



  const handleEditCompetition = () => {
    navigate(`/editcompetition/${competitionId}`);
  };

  const handleJoinCompetition = () => {
    if (isAuthenticated) {
      open();
    } else {
      navigate('/login');
    }
  };

  return (
    <>
      <CompetitionHeader 
        competitionId={competitionId} 
        handleJoinCompetition={handleJoinCompetition} 
        isAdmin={isAdmin} 
        handleEditCompetition={handleEditCompetition}
      />
      
      <Box style={styles.tabsContainer}>
        <Container size="lg">
          <Group align="flex-end" spacing={0} w="100%">
            <Tabs 
              value={activeTab} 
              onChange={setActiveTab}
              classNames={{
                root: 'competition-tabs',
                list: 'competition-tabs-list',
                tab: 'competition-tab',
                panel: 'competition-panel'
              }}
              className="flex-1"
            >
              <Tabs.List>
                <Tabs.Tab value="overview">Overview</Tabs.Tab>
                <Tabs.Tab value="getting-started">Getting Started</Tabs.Tab>
                <Tabs.Tab value="leaderboard">Leaderboard</Tabs.Tab>
                <Tabs.Tab value="team">Team</Tabs.Tab>
                <Tabs.Tab value="discussions">Discussions</Tabs.Tab>
              </Tabs.List>
            </Tabs>
          </Group>
        </Container>
      </Box>

      <Box style={styles.contentContainer}>
        <Container size="lg">
        <Tabs 
  value={activeTab} 
  onChange={setActiveTab}
  classNames={{
    root: 'competition-tabs',
    list: 'competition-tabs-list',
    tab: 'competition-tab',
    panel: 'competition-panel',
  }}
>
            <Tabs.Panel value="overview">
              <Overview
                competitionId={competitionId}
                startTime={new Date(competitionInfo.start_date_ts)}
                endTime={new Date(competitionInfo.programmed_end_at_ts)}
                renderDelay={competitionInfo.render_delay_second}
                agentMemoryLimit={competitionInfo.agent_memory_limit}
                agentCpuLimit={competitionInfo.agent_cpu_limit}
                agentMaxTimePerStepSecond={competitionInfo.agent_max_time_per_step_second}
                competitionInfo={competitionInfo}
                environmentInfo={environmentInfo}
                handleJoinCompetition={handleJoinCompetition}
              />
            </Tabs.Panel>

            <Tabs.Panel value="getting-started">
              {competitionInfo.id && environmentInfo.id ? (
                <GettingStarted 
                  runtimeOptions={competitionInfo?.runtime_options || []}
                  selectedImage={selectedImage}
                  setSelectedImage={setSelectedImage}
                  competitionInfo={competitionInfo}
                  environmentInfo={environmentInfo}
                  handleJoinCompetition={handleJoinCompetition}
                />
              ) : (
                <Text>Loading...</Text>
              )}
            </Tabs.Panel>

            <Tabs.Panel value="leaderboard">
              <LeaderboardTab competitionId={competitionId} />
            </Tabs.Panel>

            <Tabs.Panel value="team">
              <TeamTab competitionId={competitionId} />
            </Tabs.Panel>

            <Tabs.Panel value="discussions">
              <Discussions 
                competitionId={competitionId} 
                isAdmin={isAdmin} 
                isAuthenticated={isAuthenticated} 
                currentUser={currentUser}
              />
            </Tabs.Panel>
            </Tabs>
        </Container>
      </Box>

      <NameYourAgentModal
        opened={opened}
        close={close}
        competitionId={competitionId}
        selectedImage={selectedImage}
      />
    </>
  );
};

export default ViewCompetition;