import React, { useState } from 'react';
import { Card, Group, Text, Stack, Modal, List, ThemeIcon, Button, Tooltip } from '@mantine/core';
import { IconTrash, IconFileText, IconEdit, IconDeviceFloppy, IconUpload } from '@tabler/icons-react';
import { Editor } from '@monaco-editor/react';
import { Dropzone } from '@mantine/dropzone';
import classes from 'theme/FileManagement.module.css';

const FileManagement = ({
  files = {},
  loading = false,
  disabled = false,
  onDelete,
  onEdit,
  onUpload
}) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState('');
  const [showCodeEditor, setShowCodeEditor] = useState(false);
  const [tempCode, setTempCode] = useState('');
  const [currentEditingFile, setCurrentEditingFile] = useState(null);

  const editorOptions = {
    minimap: { enabled: false },
    lineNumbers: 'on',
    readOnly: disabled || loading,
    automaticLayout: true,
  };

  const handleCodeChange = (newValue) => {
    setTempCode(newValue);
  };

  const handleOpenEditor = (filename) => {
    setShowCodeEditor(true);
    setCurrentEditingFile(filename);
    setTempCode(files[filename].content);
  };

  const handleCloseEditor = () => {
    setShowCodeEditor(false);
    setCurrentEditingFile(null);
    setTempCode('');
  };

  const handleSaveAndClose = () => {
    onEdit(currentEditingFile, tempCode);
    handleCloseEditor();
  };

  const confirmDelete = (file) => {
    setSelectedFile(file);
    setShowConfirmModal(true);
  };

  const deleteFile = () => {
    onDelete(selectedFile);
    setShowConfirmModal(false);
  };

  const handleDrop = (droppedFiles) => {
    droppedFiles.forEach(file => onUpload(file));
  };

  const handleFileSelect = (event) => {
    const selectedFiles = Array.from(event.target.files || []);
    selectedFiles.forEach(file => onUpload(file));
  };

  const renderFileList = () => (
    <List spacing="xs" className={classes.fileList}>
      {Object.entries(files).map(([filename, fileData]) => (
        <List.Item
          key={filename}
          icon={
            <ThemeIcon color={filename === 'agent.py' ? 'blue' : 'gray'} size={24} radius="xl">
              <IconFileText size={16} />
            </ThemeIcon>
          }
          className={`${classes.fileItem} ${filename === 'agent.py' ? classes.mainFile : ''}`}
        >
          <Group position="apart" wrap="nowrap">
            <Text size="sm">
              {filename}
              {filename === 'agent.py' && ' (Main File)'}
            </Text>
            <Group spacing={8} wrap="nowrap" className={classes.actionButtons}>
              {!fileData.is_binary && (
                <Tooltip label="Edit Code">
                  <Button
                    variant="light"
                    size="xs"
                    color="blue"
                    onClick={() => handleOpenEditor(filename)}
                    disabled={disabled || loading}
                  >
                    <IconEdit size={14} />
                  </Button>
                </Tooltip>
              )}
              <Tooltip label="Delete File">
                <Button
                  variant="light"
                  size="xs"
                  color="red"
                  onClick={() => confirmDelete(filename)}
                  disabled={disabled || loading}
                >
                  <IconTrash size={14} />
                </Button>
              </Tooltip>
            </Group>
          </Group>
        </List.Item>
      ))}
    </List>
  );

  const renderCodeEditorModal = () => (
    <Modal
      opened={showCodeEditor}
      onClose={handleCloseEditor}
      title={`Edit ${currentEditingFile}`}
      size="xl"
      padding="md"
    >
      <Stack spacing="md">
        <div className={classes.editorContainer}>
          <Editor
            height="600px"
            language={files[currentEditingFile]?.language || 'plaintext'}
            theme="vs-dark"
            value={tempCode}
            options={editorOptions}
            onChange={handleCodeChange}
          />
        </div>
        <Group position="right" className={classes.modalActions}>
          <Button variant="light" onClick={handleCloseEditor}>
            Cancel
          </Button>
          <Button
            variant="filled"
            onClick={handleSaveAndClose}
            leftSection={<IconDeviceFloppy size={14} />}
          >
            Save & Close
          </Button>
        </Group>
      </Stack>
    </Modal>
  );

  return (
    <div className={classes.container}>
      <Stack spacing="md">
        {/* Upload Section */}
        <div className={classes.uploadSection}>
          <Group position="apart">
            <Text className={classes.sectionTitle}>Upload Files</Text>
            <Tooltip label="Select Files">
              <Button
                component="label"
                leftSection={<IconUpload size={16} />}
                disabled={disabled || loading}
                variant="light"
              >
                Browse Files
                <input
                  type="file"
                  onChange={handleFileSelect}
                  multiple
                  className={classes.hiddenInput}
                  disabled={disabled || loading}
                />
              </Button>
            </Tooltip>
          </Group>

          <Dropzone
            onDrop={handleDrop}
            onReject={(files) => console.log('rejected files', files)}
            maxSize={50 * 1024 ** 2}
            accept={[]}
            disabled={loading || disabled}
            className={classes.dropzone}
          >
            <div className={classes.dropzoneContent}>
              <Text size="md" inline>
                Drag files here or click to select
              </Text>
              <Text size="sm" color="dimmed" inline mt={7}>
                Upload your model files and any additional resources
              </Text>
            </div>
          </Dropzone>
        </div>

        {/* File List */}
        {Object.keys(files).length > 0 && (
          <>
            <Text className={classes.sectionTitle}>Uploaded Files</Text>
            {renderFileList()}
          </>
        )}

        {/* Delete Confirmation Modal */}
        <Modal
          opened={showConfirmModal}
          onClose={() => setShowConfirmModal(false)}
          title="Confirm Delete"
          size="sm"
        >
          <Stack>
            <Text>Are you sure you want to delete "{selectedFile}"?</Text>
            <Group position="right" className={classes.modalActions}>
              <Button variant="light" onClick={() => setShowConfirmModal(false)}>
                Cancel
              </Button>
              <Button color="red" onClick={deleteFile}>
                Delete
              </Button>
            </Group>
          </Stack>
        </Modal>

        {/* Code Editor Modal */}
        {renderCodeEditorModal()}
      </Stack>
    </div>
  );
};

export default FileManagement;