export const agentMonitorStyles = {
    tabsContainer: {
      backgroundColor: 'var(--mantine-color-gray-0)',
      width: '100%',
      padding: '1rem 0',
      borderBottom: '1px solid var(--mantine-color-gray-2)'
    },
    contentContainer: {
      backgroundColor: 'var(--mantine-color-white)',
      minHeight: '25rem',
      paddingTop: '2rem'
    },
    tabIcon: {
      marginRight: '0.5rem',
      display: 'flex',
      alignItems: 'center'
    }
  };