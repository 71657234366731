import React, { useState } from 'react';
import { 
  Stack, Group, Title, ActionIcon, Button, Paper, 
  Modal, Text, TextInput, Avatar, LoadingOverlay,
  Divider
} from '@mantine/core';
import { Settings, Users, Trash, LogOut, X } from 'lucide-react';
import { notifications } from '@mantine/notifications';


const YourTeam = ({ 
  team, 
  loading, 
  currentUser, 
  onCreateTeam, 
  onLeaveTeam, 
  onRemoveMember, 
  onUpdateTeam,
  onDeleteTeam
}) => {
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [newTeamName, setNewTeamName] = useState('');
  const [editedTeamName, setEditedTeamName] = useState(team?.name || '');

  const isLeader = team?.members?.find(
    member => member.user.id === currentUser.user_id && member.role === 'leader'
  );

  const handleCreateTeam = async () => {
    try {
      if (!newTeamName.trim()) {
        notifications.show({
          title: 'Error',
          message: 'Team name cannot be empty',
          color: 'red'
        });
        return;
      }
      await onCreateTeam(newTeamName);
      setShowCreateModal(false);
      setNewTeamName('');
    } catch (error) {
      notifications.show({
        title: 'Error',
        message: error.response?.data?.error || 'Failed to create team',
        color: 'red'
      });
    }
  };

  const handleUpdateTeam = async () => {
    try {
      if (!editedTeamName.trim()) {
        notifications.show({
          title: 'Error',
          message: 'Team name cannot be empty',
          color: 'red'
        });
        return;
      }
      await onUpdateTeam({ ...team, name: editedTeamName });
      setShowSettingsModal(false);
      notifications.show({
        title: 'Success',
        message: 'Team updated successfully',
        color: 'green'
      });
    } catch (error) {
      notifications.show({
        title: 'Error',
        message: error.response?.data?.error || 'Failed to update team',
        color: 'red'
      });
    }
  };

  const handleDeleteTeam = async () => {
    try {
      await onDeleteTeam(team.id);
      setShowDeleteConfirmModal(false);
      setShowSettingsModal(false);
      notifications.show({
        title: 'Success',
        message: 'Team deleted successfully',
        color: 'green'
      });
    } catch (error) {
      notifications.show({
        title: 'Error',
        message: error.response?.data?.error || 'Failed to delete team',
        color: 'red'
      });
    }
  };

  return (
    <>
      <LoadingOverlay visible={loading} />
      
      

      {team ? (
<>
          <Group position="apart" mb="md">
            <Title order={3}>Your Team {team.name}</Title>
            {isLeader && (
              <ActionIcon 
                variant="light" 
                onClick={() => {
                  setEditedTeamName(team.name);
                  setShowSettingsModal(true);
                }}
              >
                <Settings size={20} />
              </ActionIcon>
            )}
          </Group>

          <Stack spacing="sm">
          <Title order={4} mb="md">Team Members</Title>
            {team.members.map((member) => (
              <Group key={member.id} position="apart">
                <Group>
                  <Avatar radius="xl" src={null} alt={member.user.username}>
                    {member.user.username[0].toUpperCase()}
                  </Avatar>
                  <div>
                    <Text>
                      {member.user.username}
                      {member.user.id === currentUser.user_id && " (You)"}
                    </Text>
                    <Text size="sm" color="dimmed">
                      {member.role === 'leader' ? 'Team Leader' : 'Member'}
                    </Text>
                  </div>
                </Group>
                {isLeader && member.user.id !== currentUser.user_id && (
                  <ActionIcon 
                    color="red" 
                    variant="light"
                    onClick={() => onRemoveMember(member.id)}
                  >
                    <X size={20} />
                  </ActionIcon>
                )}
              </Group>
            ))}
          </Stack>

          {!isLeader && (
            <Button
              color="red"
              variant="light"
              leftSection={<LogOut size={20} />}
              onClick={onLeaveTeam}
              mt="xl"
            >
              Leave Team
            </Button>
          )}
        </>
      ) : (
        <Button
          leftSection={<Users size={20} />}
          onClick={() => setShowCreateModal(true)}
        >
          Create Team
        </Button>
      )}

      {/* Create Team Modal */}
      <Modal
        opened={showCreateModal}
        onClose={() => setShowCreateModal(false)}
        title="Create Team"
      >
        <Stack>
          <TextInput
            label="Team Name"
            placeholder="Enter team name"
            value={newTeamName}
            onChange={(e) => setNewTeamName(e.target.value)}
          />
          <Button onClick={handleCreateTeam}>
            Create Team
          </Button>
        </Stack>
      </Modal>

      {/* Settings Modal */}
      <Modal
        opened={showSettingsModal}
        onClose={() => setShowSettingsModal(false)}
        title="Team Settings"
      >
        <Stack>
          <TextInput
            label="Team Name"
            value={editedTeamName}
            onChange={(e) => setEditedTeamName(e.target.value)}
          />
          <Button onClick={handleUpdateTeam}>
            Save Changes
          </Button>
          
          <Divider my="md" />
          
          <Button 
            color="red" 
            leftSection={<Trash size={20} />}
            variant="light"
            onClick={() => setShowDeleteConfirmModal(true)}
          >
            Delete Team
          </Button>
        </Stack>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal
        opened={showDeleteConfirmModal}
        onClose={() => setShowDeleteConfirmModal(false)}
        title="Delete Team"
      >
        <Stack>
          <Text>Are you sure you want to delete this team? This action cannot be undone.</Text>
          <Group position="apart">
            <Button variant="light" onClick={() => setShowDeleteConfirmModal(false)}>
              Cancel
            </Button>
            <Button color="red" onClick={handleDeleteTeam}>
              Delete Team
            </Button>
          </Group>
        </Stack>
      </Modal>
    </>
  );
};

export default YourTeam;