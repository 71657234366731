import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextInput, Loader, Popover, Stack, Text, Group } from '@mantine/core';
import { Search } from 'lucide-react';
import { useDebouncedValue } from '@mantine/hooks';
import axios from 'axios';

const SearchInput = ({ className }) => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState({ competitions: [], users: [] });
  const [opened, setOpened] = useState(false);
  const [debouncedSearch] = useDebouncedValue(searchTerm, 300);

  useEffect(() => {
    const fetchResults = async () => {
      if (debouncedSearch.trim().length < 2) {
        setResults({ competitions: [], users: [] });
        setOpened(false);
        return;
      }

      setLoading(true);
      try {
        const { data } = await axios.get(`/api/search?q=${encodeURIComponent(debouncedSearch)}&limit=3`);
        setResults(data);
        setOpened(true);
      } catch (error) {
        console.error('Search failed:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchResults();
  }, [debouncedSearch]);

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && searchTerm.trim()) {
      navigate(`/search?q=${encodeURIComponent(searchTerm.trim())}`);
      setOpened(false);
    }
  };

  const handleCompetitionClick = (id) => {
    navigate(`/viewcompetition/${id}`);
    setOpened(false);
    setSearchTerm('');
  };

  const hasResults = results.competitions.length > 0 || results.users.length > 0;

  const searchStyles = {
    root: { 
      flex: 1,
      width: '100%',
    },
    input: {
      height: '2.75rem',
      backgroundColor: 'var(--mantine-color-white)',
      border: '1px solid var(--mantine-color-gray-2)',
      borderRadius: '6.25rem',
      paddingLeft: '2.75rem',
      '&:focus': { 
        borderColor: 'var(--mantine-color-purple-6)'
      }
    },
    section: {
      color: 'var(--mantine-color-gray-6)',
      paddingLeft: '1rem'
    }
  };

  const dropdownStyles = {
    dropdown: {
      padding: 'var(--mantine-spacing-xs)',
      backgroundColor: 'var(--mantine-color-white)',
      border: '1px solid var(--mantine-color-gray-2)',
      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
    }
  };

  const resultItemStyles = {
    padding: 'var(--mantine-spacing-xs)',
    borderRadius: 'var(--mantine-radius-sm)',
    '&:hover': {
      backgroundColor: 'var(--mantine-color-gray-0)',
    }
  };

  return (
    <Popover
      opened={opened && hasResults}
      position="bottom"
      width="target"
      shadow="md"
      styles={dropdownStyles}
    >
      <Popover.Target>
        <TextInput
          placeholder="Search competitions and users..."
          leftSection={loading ? <Loader size="xs" /> : <Search size={18} strokeWidth={1.5} />}
          value={searchTerm}
          onChange={handleSearch}
          onKeyPress={handleKeyPress}
          className={className}
          styles={searchStyles}
        />
      </Popover.Target>

      <Popover.Dropdown>
        <Stack spacing="xs">
          {results.competitions.map(comp => (
            <Group 
              key={comp.id}
              onClick={() => handleCompetitionClick(comp.id)}
              style={{ cursor: 'pointer' }}
              sx={resultItemStyles}
              spacing="xs"
            >
              <Search size={16} />
              <div>
                <Text size="sm" weight={500}>{comp.name}</Text>
                <Text size="xs" color="dimmed" lineClamp={1}>
                  {comp.description}
                </Text>
              </div>
            </Group>
          ))}
        </Stack>
      </Popover.Dropdown>
    </Popover>
  );
};

export default SearchInput;