import React, { useState, useEffect } from 'react';
import { useNavigate, NavLink, useLocation } from 'react-router-dom';
import { useAuth } from 'context/AuthContext';
import axios from 'axios';
import { AppShell, TextInput, Group, Button, Menu, ActionIcon, Avatar, Container, Burger, Loader } from '@mantine/core';
import { IconChevronDown } from '@tabler/icons-react';
import { Search, Trophy, Package2, UserSquare2, MessageSquare, Users, LogOut, ShieldHalf, Bot  } from 'lucide-react';
import { shellStyles } from 'theme/shellStyles';
import { useMediaQuery, useDebouncedValue } from '@mantine/hooks';
import { StyledButton } from 'theme/buttonStyles';
import 'theme/NavItem.css';
import SearchInput from 'components/SearchInput';
import ResponsiveLayout from './ResponsiveLayout';

const Shell = ({ children }) => {
  const { currentUser, isAdmin, isAuthenticated, setIsAuthenticated, setCurrentUser } = useAuth();
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width: 768px)');
  const [navExpanded, setNavExpanded] = useState(!isMobile);
  // Update nav state when screen size changes
  useEffect(() => {
    setNavExpanded(!isMobile);
  }, [isMobile]);

  const handleLogout = async () => {
    try {
      await axios.post('/api/auth/logout');
      setCurrentUser(null);
      setIsAuthenticated(false);
      navigate('/login');
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  const NavItem = ({ to, icon: Icon, children }) => {
    const location = useLocation();
    
    const isActive = () => {
      if (to === '/listcompetition') {
        // Check if we're on listcompetition or viewcompetition/* paths
        return location.pathname === to || location.pathname.startsWith('/viewcompetition');
      }
      if (to === '/my-agents') {
        // Check if we're on my-agents or attach-agent/* paths
        return location.pathname === to || location.pathname.startsWith('/attach-agent');
      }
      return location.pathname === to;
    };
  
    return (
      <NavLink 
        to={to}
        className={({ isActive: exactMatch }) => `nav-item ${isActive() ? 'active' : ''}`}
      >
        {Icon && <Icon size={20} strokeWidth={1.5} />}
        {children}
      </NavLink>
    );
  };

  const VerticalNav = () => (
    <div className="nav-items-container">
      {isMobile && navExpanded && (
        <SearchInput/>)}
      <NavItem to="/listcompetition" icon={ShieldHalf}>Competition</NavItem>
      <NavItem to="/my-agents" icon={Bot}>My Agents</NavItem>
      <NavItem to="/ranking" icon={Trophy}>User Ranking</NavItem>
      <NavItem to="/general-discussions" icon={MessageSquare}>Discussion</NavItem>
      {isAdmin && navExpanded && (
        <Menu shadow="md" width={200} position="right-start" className="admin-menu">
          <Menu.Target>
            <button className="admin-button">
              <UserSquare2 size={20} strokeWidth={1.5} />
              Admin
              <IconChevronDown size={14} style={{ marginLeft: 'auto' }} />
            </button>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item component={NavLink} to="/listagent">Agents</Menu.Item>
            <Menu.Item component={NavLink} to="/listenvironment">Environments</Menu.Item>
            <Menu.Item component={NavLink} to="/tags">Tags</Menu.Item>
          </Menu.Dropdown>
        </Menu>
      )}
    </div>
  );

  return (
    <AppShell padding={0}>
      {/* Burger Button */}
      <div style={shellStyles.burgerWrapper}>
        <Burger 
          opened={false} 
          onClick={() => setNavExpanded(!navExpanded)} 
          size="sm" 
        />
      </div>

      {/* Fixed Logo */}
      <div style={shellStyles.logoWrapper}>
        <NavLink to="/" style={shellStyles.logo}>
          ML-Arena
        </NavLink>
      </div>

      <div style={{
  ...shellStyles.mainHeader,
}}>
  <Group 
    justify="space-between" 
    align="center" 
    h="100%" 
    px="xl" // Adds horizontal padding instead of using Container
  >
    {!isMobile && <SearchInput style={{ flex: 1 }} />}
    <Group gap="xs" ml="auto">
      {isAuthenticated ? (
        <>
          <Avatar
            src={currentUser?.avatar}
            radius="xl"
            size="md"
            onClick={() => navigate('/my-profile')}
            style={{ cursor: 'pointer' }}
          />
          <ActionIcon variant="subtle" onClick={handleLogout}>
            <LogOut size={18} />
          </ActionIcon>
        </>
      ) : (
        <StyledButton
          size="lg"
          variant="secondary"
          onClick={() => navigate('/login')}
        >
          Login
        </StyledButton>
      )}
    </Group>
  </Group>
</div>

      {/* Side Navigation */}
      <nav 
  style={{ 
    ...shellStyles.navbar,
    width: navExpanded ? '18rem' : '0',
    padding: navExpanded ? '4.5rem 1rem 1.5rem' : '4.5rem 0 1.5rem',
    opacity: navExpanded ? 1 : 0,
    pointerEvents: navExpanded ? 'auto' : 'none'
  }}
  data-collapsed={!navExpanded}
>
  <VerticalNav />
</nav>

      {/* Main Content */}
      <main style={{
        ...shellStyles.mainContent,
        marginLeft: navExpanded ? '18rem' : '0'
      }}>
        <ResponsiveLayout>{children}</ResponsiveLayout>
        
      </main>
    </AppShell>
  );
};

export default Shell;