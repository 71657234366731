import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Text, Button, TextInput, Textarea, Checkbox, Stack, Group, Modal } from '@mantine/core';
import { DateTimePicker } from '@mantine/dates';
import { saveCompetition, deleteCompetition } from 'services/apiService';
import { useDisclosure } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';

const CompetitionSection = ({ competitionId, competitionData, updateCompetition }) => {
  const navigate = useNavigate();
  const [deleteModalOpened, { open: openDeleteModal, close: closeDeleteModal }] = useDisclosure(false);
  const isNewCompetition = !competitionId;

  const handleSave = async () => {
    try {
      const response = await saveCompetition(competitionId, competitionData);
      notifications.show({
        title: 'Success',
        message: 'Competition saved successfully',
        color: 'green'
      });
      
      if (isNewCompetition) {
        navigate(`/editcompetition/${response.id}`);
      }
    } catch (error) {
      notifications.show({
        title: 'Error',
        message: error.message || 'Failed to save competition',
        color: 'red'
      });
    }
  };

  const handleDelete = async () => {
    try {
      await deleteCompetition(competitionId);
      notifications.show({
        title: 'Success',
        message: 'Competition deleted successfully',
        color: 'green'
      });
      navigate('/listcompetition');
    } catch (error) {
      notifications.show({
        title: 'Error',
        message: error.message || 'Failed to delete competition',
        color: 'red'
      });
    }
    closeDeleteModal();
  };

  const handleChange = (name, value) => {
    updateCompetition({ [name]: value });
  };

  // Input fields based on competition state (new or existing)
  const renderFields = () => {
    if (isNewCompetition) {
      return (
        <>
          <TextInput
            label="Name"
            placeholder="Enter competition name"
            value={competitionData.name || ''}
            onChange={(e) => handleChange('name', e.target.value)}
            required
          />
          <Textarea
            label="Description"
            placeholder="Enter competition description"
            value={competitionData.description || ''}
            onChange={(e) => handleChange('description', e.target.value)}
            minRows={3}
          />
          <DateTimePicker
            label="Start Date"
            placeholder="Select start date and time"
            value={competitionData.start_date_ts ? new Date(competitionData.start_date_ts) : null}
            onChange={(date) => handleChange('start_date_ts', date?.toISOString())}
          />
          <DateTimePicker
            label="Programmed End Date"
            placeholder="Select end date and time"
            value={competitionData.programmed_end_at_ts ? new Date(competitionData.programmed_end_at_ts) : null}
            onChange={(date) => handleChange('programmed_end_at_ts', date?.toISOString())}
          />
        </>
      );
    }

    return (
      <>
        <Text size="sm" c="dimmed">Name: {competitionData.name}</Text>
        <Textarea
          label="Description"
          placeholder="Enter competition description"
          value={competitionData.description || ''}
          onChange={(e) => handleChange('description', e.target.value)}
          minRows={3}
        />
        <DateTimePicker
          label="Start Date"
          placeholder="Select start date and time"
          value={competitionData.start_date_ts ? new Date(competitionData.start_date_ts) : null}
          onChange={(date) => handleChange('start_date_ts', date?.toISOString())}
        />
        <DateTimePicker
          label="Programmed End Date"
          placeholder="Select end date and time"
          value={competitionData.programmed_end_at_ts ? new Date(competitionData.programmed_end_at_ts) : null}
          onChange={(date) => handleChange('programmed_end_at_ts', date?.toISOString())}
        />
        <Group>
          <Checkbox
            label="Is Started"
            checked={competitionData.is_started || false}
            onChange={(e) => handleChange('is_started', e.currentTarget.checked)}
          />
          <Checkbox
            label="Is Public"
            checked={competitionData.is_public || false}
            onChange={(e) => handleChange('is_public', e.currentTarget.checked)}
          />
        </Group>
      </>
    );
  };

  // Display read-only fields
  const renderReadOnlyFields = () => (
    <Stack spacing="xs" mt="md">
      {competitionData.created_at_ts && (
        <Text size="sm" c="dimmed">
          Created At: {new Date(competitionData.created_at_ts).toLocaleString()}
        </Text>
      )}
      {competitionData.user_id && (
        <Text size="sm" c="dimmed">
          User ID: {competitionData.user_id}
        </Text>
      )}
    </Stack>
  );

  return (
    <Card shadow="sm" p="lg" radius="md" withBorder>
      <Card.Section withBorder inheritPadding py="xs">
        <Text fw={500} size="lg">Competition Details</Text>
      </Card.Section>

      <Stack spacing="md" mt="md">
        {renderFields()}
        {renderReadOnlyFields()}
        
        <Group position="right" mt="xl">
          <Button variant="filled" onClick={handleSave}>
            Save Changes
          </Button>
          
          {!isNewCompetition && (
            <Button variant="light" color="red" onClick={openDeleteModal}>
              Delete Competition
            </Button>
          )}
        </Group>
      </Stack>

      <Modal 
        opened={deleteModalOpened}
        onClose={closeDeleteModal}
        title="Delete Competition"
        centered
      >
        <Stack>
          <Text>Are you sure you want to delete this competition? This action cannot be undone.</Text>
          <Group position="right">
            <Button variant="subtle" onClick={closeDeleteModal}>Cancel</Button>
            <Button variant="filled" color="red" onClick={handleDelete}>
              Delete
            </Button>
          </Group>
        </Stack>
      </Modal>
    </Card>
  );
};

export default CompetitionSection;