import React from 'react';
import { Box, Text, Group, Badge, Container } from '@mantine/core';
import { Link as RouterLink } from 'react-router-dom';
import { Trophy } from 'lucide-react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import AllocationControl from 'components/DirectAgentAttach/Monitor/AllocationControl';
import { attachAgentHeaderStyles as styles } from 'theme/attachAgentHeaderStyles';
import { StyledButton } from 'theme/buttonStyles';
import DeleteAttach from 'components/DirectAgentAttach/DeleteAttach';
import DeploymentNavigationButton from './DeploymentNavigationButton';



dayjs.extend(relativeTime);


const EditAttachAgentHeader = ({
  competitionId,
  agentId,
  attachment,
  competitionInfo,
  currentPage,
  onPageChange,
  currentUser,
  onDeleteAgent
}) => {
  return (
    <Box style={styles.wrapper}>
      <Container size="lg">
        <Box style={styles.content}>
          {/* Top section with title and Go to competition button */}
          <Group position="apart" align="flex-start" style={{ width: '100%' }}>
            <Box style={{ flex: 1 }}>
              <Group spacing="md">
                <Text size="xl" fw={700} style={styles.title}>{attachment.name}</Text>
                <Badge 
            color={attachment.status === 'active' ? "green" : "blue"} 
            variant="filled"
            style={styles.badge}
          >
            {attachment.status === 'active' ? "Active" : attachment.status}
          </Badge>
              </Group>
              
              <Text size="sm" c="dimmed" style={styles.timestamp}>
                Created {dayjs(attachment.created_at).fromNow()}
              </Text>
            </Box>

            <Group position="right">
              <StyledButton
                component={RouterLink}
                to={`/viewcompetition/${competitionId}`}
                variant="subtle"
                style={styles.competitionButton}
              >
                Go to competition {competitionInfo?.name}
              </StyledButton>
            </Group>
          </Group>



          {/* Bottom section with navigation and delete button */}
          <Box style={styles.content}>
            <Group position="apart" align="center" style={{ width: '100%' }}>
            <Box style={{ flex: 1 }}>
              <DeploymentNavigationButton
                currentPage={currentPage}
                attachmentStatus={attachment.status}
                onPageChange={onPageChange}
              />
              </Box>
              <Group position="right">
                <DeleteAttach 
                  onDelete={onDeleteAgent} 
                  agentOwnerId={currentUser.user_id === attachment.user_id} 
                />
              </Group>
            </Group>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default EditAttachAgentHeader;