// src/theme/competitionGettingStartedStyles.js

export const competitionGettingStartedStyles = {
    root: {
      padding: 0,
      paddingBottom: 'calc(var(--mantine-spacing-xl) * 4)'
    },
  
    section: {
      marginBottom: 'calc(var(--mantine-spacing-xl) * 2)'
    },
  
    sectionTitle: {
      color: 'var(--mantine-color-blue-11)',
      fontSize: '2.2rem',
      fontWeight: 700,
      marginBottom: 'var(--mantine-spacing-xl)'
    },
  
    diagram: {
      marginTop: 'var(--mantine-spacing-xl)',
      marginBottom: 'var(--mantine-spacing-xl)',
      width: '100%',
      display: 'flex',
      justifyContent: 'center'
    },
  
    constraintsWrapper: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
      gap: 'var(--mantine-spacing-lg)',
      marginTop: 'var(--mantine-spacing-lg)'
    },
  
    constraintItem: {
      padding: 'var(--mantine-spacing-md)',
      transition: 'transform 150ms ease',
      '&:hover': {
        transform: 'translateY(-2px)'
      }
    },
  
    codeWrapper: {
      marginTop: 'var(--mantine-spacing-lg)',
      marginBottom: 'var(--mantine-spacing-xl)',
      background: 'var(--mantine-color-gray-0)',
      padding: 'var(--mantine-spacing-md)',
      borderRadius: 'var(--mantine-radius-md)'
    },
  
    resourcesGrid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
      gap: 'var(--mantine-spacing-lg)',
      marginTop: 'var(--mantine-spacing-xl)'
    },
  
    resourceItem: {
      padding: 'var(--mantine-spacing-md)',
      transition: 'transform 150ms ease',
      '&:hover': {
        transform: 'translateY(-2px)'
      }
    },
  
    lastSection: {
      marginBottom: 'calc(var(--mantine-spacing-xl) * 10)'
    }
  };