import React from 'react';
import { ArrowLeft, Rocket } from 'lucide-react';
import { Button } from '@mantine/core';

const DeploymentNavigationButton = ({ 
  currentPage,
  attachmentStatus,
  onPageChange,
  variant = "light",
  size = "sm"
}) => {
  // Check if we should show deploy or back button
  const showDeployButton = currentPage === 'upload' && 
    ['upload_validated', 'deploying', 'deploy_queue', 'deploy_run', 'deploy_failed'].includes(attachmentStatus);
  
  const showBackToUpload = currentPage === 'deploy' && 
    ['upload_validated', 'deploying', 'deploy_queue', 'deploy_run', 'deploy_failed'].includes(attachmentStatus);

  if (!showDeployButton && !showBackToUpload) {
    return null;
  }

  const buttonStyles = {
    root: {
      padding: '6px 12px',
      height: 'auto',
      minHeight: '32px',
      display: 'inline-flex',
      alignItems: 'center',
      gap: '8px',
      lineHeight: '1.2'
    },
    section: {
      marginRight: '4px',
      display: 'flex',
      alignItems: 'center'
    }
  };

  if (showBackToUpload) {
    return (
      <Button
        variant={variant}
        size={size}
        leftSection={<ArrowLeft size={16} />}
        onClick={() => onPageChange('upload')}
        className="transition-transform hover:-translate-x-1"
        styles={buttonStyles}
      >
        Back to Upload
      </Button>
    );
  }

  if (showDeployButton) {
    return (
      <Button
        variant={variant}
        size={size}
        leftSection={<Rocket size={16} />}
        onClick={() => onPageChange('deploy')}
        className="transition-transform hover:translate-x-1"
        styles={buttonStyles}
      >
        Proceed to Deployment
      </Button>
    );
  }

  return null;
};

export default DeploymentNavigationButton;