import React from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { AppShell, Group, Button, Container, ActionIcon, Avatar, Burger, Drawer } from '@mantine/core';
import { Trophy, Package2, Users, MessageSquare, LogOut } from 'lucide-react';
import { useDisclosure } from '@mantine/hooks';
import axios from 'axios';
import { useAuth } from 'context/AuthContext';
import { StyledButton } from 'theme/buttonStyles';
import { landingShellStyles } from 'theme/landingShellStyles';
import { useMediaQuery } from '@mantine/hooks';

const LandingShell = ({ children }) => {
  const [opened, { toggle, close }] = useDisclosure(false);
  const isMobile = useMediaQuery('(max-width: 768px)');
  const navigate = useNavigate();
  const { currentUser, isAdmin, isAuthenticated, setIsAuthenticated, setCurrentUser } = useAuth();
  
  const handleLogout = async () => {
    try {
      await axios.post('/api/auth/logout');
      setCurrentUser(null);
      setIsAuthenticated(false);
      navigate('/login');
      close();
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  const NavItem = ({ to, icon: Icon, children }) => (
    <Button
      component={NavLink}
      to={to}
      variant="subtle"
      color="dark"
      leftSection={Icon && <Icon size={20} strokeWidth={1.5} />}
      styles={landingShellStyles.navItem}
    >
      {children}
    </Button>
  );

  return (
    <AppShell header={{ height: 80 }} padding={0}>
      <AppShell.Header style={landingShellStyles.header}>
        <Container size="xl" h="100%" className="px-6">
          <Group gap="sm" justify="space-between" style={landingShellStyles.navGroup} h="100%" align="center">
            <NavLink to="/" style={landingShellStyles.logo}>
              ML-Arena
            </NavLink>

            {!isMobile ? (
              <>
                <NavItem to="/listcompetition">Competition</NavItem>
                <NavItem to="/my-agents">My Agents</NavItem>
                <NavItem to="/ranking">User Ranking</NavItem>
                <NavItem to="/general-discussions">Discussion</NavItem>
                
                {isAuthenticated ? (
                  <Group gap="xs">
                    <Avatar
                      src={currentUser?.avatar}
                      radius="xl"
                      size="md"
                      className="cursor-pointer"
                      component={NavLink}
                      to="/my-profile"
                    />
                    <ActionIcon 
                      variant="subtle" 
                      color="gray"
                      className="hover:bg-purple-0"
                    >
                      <LogOut size={18} onClick={handleLogout}/>
                    </ActionIcon>
                  </Group>
                ) : (
                  <StyledButton
                    size="lg"
                    variant="secondary"
                    onClick={() => navigate('/login')}
                  >
                    Login
                  </StyledButton>
                )}
              </>
            ) : (
              <Burger opened={opened} onClick={toggle} />
            )}
          </Group>
        </Container>
      </AppShell.Header>

      {isMobile && (
        <Drawer
          opened={opened}
          onClose={close}
          size="100%"
          position="right"
        >
          <div className="p-4 pt-16">
            <Button
              component={NavLink}
              to="/listcompetition"
              variant="subtle"
              color="dark"
              fullWidth
              onClick={close}
              className="mb-2"
            >
              Competition
            </Button>
            <Button
              component={NavLink}
              to="/my-agents"
              variant="subtle"
              color="dark"
              fullWidth
              onClick={close}
              className="mb-2"
            >
              My Agents
            </Button>
            <Button
              component={NavLink}
              to="/ranking"
              variant="subtle"
              color="dark"
              fullWidth
              onClick={close}
              className="mb-2"
            >
              User Ranking
            </Button>
            <Button
              component={NavLink}
              to="/general-discussions"
              variant="subtle"
              color="dark"
              fullWidth
              onClick={close}
              className="mb-2"
            >
              Discussion
            </Button>
            
            {isAuthenticated ? (
              <>
                <Button
                  component={NavLink}
                  to="/my-profile"
                  variant="subtle"
                  color="dark"
                  fullWidth
                  onClick={close}
                  className="mb-2"
                >
                  Profile
                </Button>
                <Button
                  fullWidth
                  variant="subtle"
                  color="red"
                  onClick={() => {
                    handleLogout();
                    close();
                  }}
                >
                  Logout
                </Button>
              </>
            ) : (
              <Button
                fullWidth
                variant="filled"
                onClick={() => {
                  navigate('/login');
                  close();
                }}
              >
                Login
              </Button>
            )}
          </div>
        </Drawer>
      )}

      <AppShell.Main>
        {children}
      </AppShell.Main>
    </AppShell>
  );
};

export default LandingShell;