import React from 'react';
import { Stack, Text, Box, Alert, Timeline } from '@mantine/core';
import { Trophy, Clock, Bot, Medal, Crown, Target } from 'lucide-react';
import { competitionOverviewStyles as styles } from "theme/competitionOverviewStyles";

const EvaluationSection = ({ competitionInfo }) => {
  const evaluationRules = [
    {
      title: "Competition Period",
      description: `Agents will compete daily against others of similar skill level. The leaderboard freezes when the competition ends.`,
      icon: Clock
    },
    {
      title: "Ranking System",
      description: competitionInfo.is_multiplayer ? 
        "Uses an ELO rating system that adjusts based on win/loss outcomes against other agents. Initial rating is determined after deployment validation runs." :
        "Rankings are based on mean rewards achieved across all experiment runs, with consistency being a key factor.",
      icon: Crown
    },
    {
      title: "Daily Operations",
      description: `Each agent is allocated a daily_run_limit per day. And you can deploy with a limit of deployments per week.`,
      icon: Bot
    },
    {
      title: "Performance Metrics",
      description: `Agents are evaluated on:
        • Primary metric: ${competitionInfo.is_multiplayer ? 'ELO Score' : 'Mean Reward'}
        • Consistency across multiple runs
        • Resource efficiency (CPU/Memory usage)
        • Response time within allocated limits`,
      icon: Target
    }
  ];

  if (competitionInfo.is_award_points) {
    evaluationRules.push({
      title: "Awards",
      description: "Upon competition completion, top performers will receive:• Points contributing to global ranking• Achievement medals•",
      icon: Trophy
    });
  }

  return (
    <section id="evaluation" style={styles.section}>
      <Text style={styles.sectionTitle}>Evaluation Rules</Text>
      
      <Alert 
        mb="xl"
        variant="light"
        color="blue"
      >
        Understanding these evaluation rules is crucial for optimizing your agent's performance and competition strategy.
      </Alert>
      
      <Timeline active={-1} bulletSize={24} lineWidth={2}>
        {evaluationRules.map((rule, index) => (
          <Timeline.Item 
            key={index}
            bullet={<rule.icon size={16} />}
            title={
              <Text weight={600} size="lg" mb="xs">
                {rule.title}
              </Text>
            }
          >
            <Box 
              p="md" 
              style={styles.evaluationRule}
              sx={(theme) => ({
                backgroundColor: theme.colorScheme === 'dark' ? 
                  theme.colors.dark[6] : 
                  theme.colors.gray[0],
                borderRadius: theme.radius.md
              })}
            >
              <Text>{rule.description}</Text>
            </Box>
          </Timeline.Item>
        ))}
      </Timeline>
      
      <Box mt="xl" p="md" style={styles.evaluationSummary}>
        <Stack spacing="sm">
          <Text weight={600} size="lg">
            <Medal size={16} style={{ marginRight: 8, verticalAlign: 'middle' }} />
            Final Rankings
          </Text>
          <Text>
            Final rankings will be determined based on performance up until the competition end date. 
            {competitionInfo.is_award_points ? 
              " Points and medals will be distributed after the competition concludes and results are verified." : 
              " The final leaderboard will be archived for reference."}
          </Text>
        </Stack>
      </Box>
    </section>
  );
};

export default EvaluationSection;