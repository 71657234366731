import React, { useState, useEffect } from 'react';
import { 
  Container, 
  Title, 
  Card, 
  TextInput, 
  Textarea, 
  Button, 
  Group, 
  Stack,
  Table,
  Text,
  Badge,
  ActionIcon,
  LoadingOverlay
} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { Edit, Trash, Plus } from 'lucide-react';
import axios from 'axios';

const TagsManagement = () => {
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editingTag, setEditingTag] = useState(null);
  const [formData, setFormData] = useState({ name: '', description: '' });

  const fetchTags = async () => {
    try {
      setLoading(true);
      const response = await axios.get('/api/competition_tags/tags');
      setTags(response.data);
    } catch (error) {
      notifications.show({
        title: 'Error',
        message: 'Failed to fetch tags',
        color: 'red'
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTags();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      if (editingTag) {
        await axios.put(`/api/competition_tags/tags/${editingTag.id}`, formData);
        notifications.show({
          title: 'Success',
          message: 'Tag updated successfully',
          color: 'green'
        });
      } else {
        await axios.post('/api/competition_tags/tags', formData);
        notifications.show({
          title: 'Success',
          message: 'Tag created successfully',
          color: 'green'
        });
      }
      
      setFormData({ name: '', description: '' });
      setEditingTag(null);
      fetchTags();
    } catch (error) {
      notifications.show({
        title: 'Error',
        message: error.response?.data?.error || 'Operation failed',
        color: 'red'
      });
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (tag) => {
    setEditingTag(tag);
    setFormData({ name: tag.name, description: tag.description || '' });
  };

  const handleDelete = async (tagId) => {
    if (!window.confirm('Are you sure you want to delete this tag?')) return;

    try {
      setLoading(true);
      await axios.delete(`/api/competition_tags/tags/${tagId}`);
      notifications.show({
        title: 'Success',
        message: 'Tag deleted successfully',
        color: 'green'
      });
      fetchTags();
    } catch (error) {
      notifications.show({
        title: 'Error',
        message: 'Failed to delete tag',
        color: 'red'
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container size="lg" py="xl">
      <div style={{ position: 'relative' }}>
        <LoadingOverlay visible={loading} blur={2} />
        
        <Stack spacing="xl">
          <Title order={2}>Competition Tags Management</Title>

          <Card withBorder p="md">
            <form onSubmit={handleSubmit}>
              <Stack spacing="md">
                <TextInput
                  required
                  label="Tag Name"
                  value={formData.name}
                  onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                  placeholder="Enter tag name"
                />
                
                <Textarea
                  label="Description"
                  value={formData.description}
                  onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                  placeholder="Enter tag description"
                />

                <Group position="right">
                  {editingTag && (
                    <Button 
                      variant="subtle" 
                      onClick={() => {
                        setEditingTag(null);
                        setFormData({ name: '', description: '' });
                      }}
                    >
                      Cancel
                    </Button>
                  )}
                  <Button type="submit" leftSection={<Plus size={16} />}>
                    {editingTag ? 'Update Tag' : 'Create Tag'}
                  </Button>
                </Group>
              </Stack>
            </form>
          </Card>

          <Card withBorder>
            <Table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Description</th>
                  <th style={{ width: 120 }}>Actions</th>
                </tr>
              </thead>
              <tbody>
                {tags.map((tag) => (
                  <tr key={tag.id}>
                    <td>
                      <Badge size="lg" variant="dot">
                        {tag.name}
                      </Badge>
                    </td>
                    <td>
                      <Text size="sm" color="dimmed">
                        {tag.description || '-'}
                      </Text>
                    </td>
                    <td>
                      <Group spacing={4}>
                        <ActionIcon
                          color="blue"
                          onClick={() => handleEdit(tag)}
                        >
                          <Edit size={16} />
                        </ActionIcon>
                        <ActionIcon
                          color="red"
                          onClick={() => handleDelete(tag.id)}
                        >
                          <Trash size={16} />
                        </ActionIcon>
                      </Group>
                    </td>
                  </tr>
                ))}
                {tags.length === 0 && (
                  <tr>
                    <td colSpan={3} style={{ textAlign: 'center' }}>
                      <Text color="dimmed" size="sm">No tags found</Text>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Card>
        </Stack>
      </div>
    </Container>
  );
};

export default TagsManagement;