import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { 
  Container, 
  Tabs, 
  Text, 
  Card, 
  Group,
  SimpleGrid,
  Skeleton,
  Avatar,
  Alert
} from '@mantine/core';
import { Search, AlertCircle } from 'lucide-react';
import axios from 'axios';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

const SearchResults = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [results, setResults] = useState({
    competitions: [],
    users: []
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const query = searchParams.get('q') || '';
  const category = searchParams.get('category') || 'all';

  useEffect(() => {
    const fetchResults = async () => {
      if (!query) {
        setResults({ competitions: [], users: [] });
        setLoading(false);
        return;
      }

      setLoading(true);
      setError(null);
      
      try {
        const { data } = await axios.get(`/api/search?q=${encodeURIComponent(query)}&category=${category}`);
        // Ensure we always have arrays, even if the backend doesn't provide them
        setResults({
          competitions: data?.competitions || [],
          users: data?.users || []
        });
      } catch (error) {
        console.error('Search failed:', error);
        setError('Failed to load search results. Please try again later.');
        setResults({ competitions: [], users: [] });
      } finally {
        setLoading(false);
      }
    };

    fetchResults();
  }, [query, category]);

  const handleTabChange = (value) => {
    const newParams = new URLSearchParams(searchParams);
    newParams.set('category', value);
    navigate(`/search?${newParams.toString()}`);
  };

  const CompetitionCard = ({ competition }) => (
    <Card shadow="sm" padding="lg" radius="md" withBorder onClick={() => navigate(`/viewcompetition/${competition.id}`)}>
      <Text weight={500} size="lg">{competition.name}</Text>
      <Text size="sm" color="dimmed" mt="xs" lineClamp={2}>{competition.description}</Text>
      <Group mt="md" spacing="xs">
        <Text size="sm" color="dimmed">
          {competition.engine_name || 'No engine specified'}
        </Text>
        {competition.is_started && <Text size="sm" color="green">Active</Text>}
      </Group>
    </Card>
  );

  const UserCard = ({ user }) => (
    <Card shadow="sm" padding="lg" radius="md" withBorder>
      <Group>
        <Avatar radius="xl" size="lg" />
        <div>
          <Text weight={500}>{user.username}</Text>
          <Text size="sm" color="dimmed" lineClamp={2}>{user.bio || 'No bio provided'}</Text>
        </div>
      </Group>
    </Card>
  );

  const ResultsSection = ({ items = [], Component, emptyMessage }) => {
    if (loading) {
      return (
        <SimpleGrid cols={3} spacing="lg" breakpoints={[
          { maxWidth: 'md', cols: 2 },
          { maxWidth: 'sm', cols: 1 }
        ]}>
          {[1, 2, 3].map(i => (
            <Skeleton key={i} height={200} radius="md" />
          ))}
        </SimpleGrid>
      );
    }

    if (!items.length) {
      return <Text color="dimmed" align="center" mt="xl">{emptyMessage}</Text>;
    }

    return (
      <SimpleGrid cols={3} spacing="lg" breakpoints={[
        { maxWidth: 'md', cols: 2 },
        { maxWidth: 'sm', cols: 1 }
      ]}>
        {items.map((item) => (
          <Component key={item.id} {...{ [Component.name.toLowerCase().replace('card', '')]: item }} />
        ))}
      </SimpleGrid>
    );
  };

  if (!query) {
    return (
      <Container size="xl" py="xl">
        <Text color="dimmed" align="center">Enter a search term to see results</Text>
      </Container>
    );
  }

  if (error) {
    return (
      <Container size="xl" py="xl">
        <Alert icon={<AlertCircle size={16} />} title="Error" color="red">
          {error}
        </Alert>
      </Container>
    );
  }

  const hasResults = results.competitions.length > 0 || results.users.length > 0;

  return (
    <Container size="xl" py="xl">
      <Text size="xl" weight={700} mb="lg">
        Search Results for "{query}"
      </Text>

      <Tabs value={category} onChange={handleTabChange} mb="xl">
        <Tabs.List>
          <Tabs.Tab value="all">All Results</Tabs.Tab>
          <Tabs.Tab value="competitions">Competitions</Tabs.Tab>
          <Tabs.Tab value="users">Users</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="all">
          {loading && (
            <ResultsSection 
              Component={CompetitionCard}
              emptyMessage="Loading..."
            />
          )}

          {!loading && !hasResults && (
            <Text color="dimmed" align="center" mt="xl">
              No results found for "{query}"
            </Text>
          )}

          {!loading && results.competitions.length > 0 && (
            <>
              <Text weight={500} size="lg" mt="xl" mb="md">Competitions</Text>
              <ResultsSection 
                items={results.competitions} 
                Component={CompetitionCard} 
                emptyMessage="No competitions found" 
              />
            </>
          )}
          
          {!loading && results.users.length > 0 && (
            <>
              <Text weight={500} size="lg" mt="xl" mb="md">Users</Text>
              <ResultsSection 
                items={results.users} 
                Component={UserCard} 
                emptyMessage="No users found" 
              />
            </>
          )}
        </Tabs.Panel>

        <Tabs.Panel value="competitions">
          <ResultsSection 
            items={results.competitions} 
            Component={CompetitionCard} 
            emptyMessage="No competitions found" 
          />
        </Tabs.Panel>

        <Tabs.Panel value="users">
          <ResultsSection 
            items={results.users} 
            Component={UserCard} 
            emptyMessage="No users found" 
          />
        </Tabs.Panel>
      </Tabs>
    </Container>
  );
};

export default SearchResults;