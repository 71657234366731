import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Container, Grid, Card, Button, Group, Text, Title } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';

const ListPage = ({ apiEndpoint, basePath }) => {
  const [items, setItems] = useState([]);
  const navigate = useNavigate();

  const handleCreateNew = () => {
    navigate(`/edit${basePath}`);
  };

  const handleClick = (itemId) => {
    navigate(
      basePath === "competition"
        ? `/view${basePath}/${itemId}`
        : `/edit${basePath}/${itemId}`
    );
  };

  useEffect(() => {
    const fetchItems = async () => {
      const fetchURL = `${process.env.REACT_APP_PREFIX_BACKEND}${apiEndpoint}`;
      try {
        const response = await fetch(fetchURL);
        const data = await response.json();
        setItems(data);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchItems();
  }, [apiEndpoint]);

  return (
    <Container size="xl" mt="xl">
      <Group position="apart" mb="xl">
        <Button leftSection={<IconPlus size="1rem" />} onClick={handleCreateNew}>
          Create New
        </Button>
      </Group>

      <Grid>
        {items.map((item) => (
          <Grid.Col lg={4} md={6} sm={12} key={item.id}>
            <Card shadow="sm" p="lg" radius="md" withBorder onClick={() => handleClick(item.id)}>
              <Card.Section withBorder inheritPadding py="xs">
                <Title order={3}>{item.name || `Item ${item.id}`}</Title>
              </Card.Section>
              {Object.entries(item).map(([key, value]) => (
                <Text key={key} mt="sm">
                  <Text span fw={700}>{key.replace(/_/g, " ")}: </Text>
                  {value}
                </Text>
              ))}
            </Card>
          </Grid.Col>
        ))}
      </Grid>
    </Container>
  );
};

ListPage.propTypes = {
  apiEndpoint: PropTypes.string.isRequired,
  basePath: PropTypes.string.isRequired,
};

export default ListPage;
