import React, { useState, useEffect } from 'react';
import { 
  Paper, Title, Text, TextInput, ScrollArea, 
  Stack, Group, Button, Divider,
  Badge
} from '@mantine/core';
import { Search, X } from 'lucide-react';
import axios from 'axios';
import { notifications } from '@mantine/notifications';

const SendInvitation = ({ team, onSendInvitation, onSearch }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [pendingInvitations, setPendingInvitations] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchPendingInvitations = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_PREFIX_BACKEND}/api/teams/${team.id}/invitations/pending`);
      setPendingInvitations(response.data);
    } catch (error) {
      notifications.show({
        title: 'Error',
        message: 'Failed to fetch pending invitations',
        color: 'red'
      });
    }
  };

  useEffect(() => {
    fetchPendingInvitations();
  }, [team.id]);

  const handleSearch = async (query) => {
    if (!query.trim()) {
      setSearchResults([]);
      return;
    }
    setLoading(true);
    try {
      const results = await onSearch(query);
      // Filter out users who already have pending invitations
      const filteredResults = results.filter(
        user => !pendingInvitations.some(inv => inv.user_id === user.id)
      );
      setSearchResults(filteredResults);
    } finally {
      setLoading(false);
    }
  };

  const handleSendInvite = async (userId) => {
    try {
      await onSendInvitation(userId);
      setSearchResults([]);
      setSearchQuery('');
      await fetchPendingInvitations();
      notifications.show({
        title: 'Success',
        message: 'Invitation sent successfully',
        color: 'green'
      });
    } catch (error) {
      notifications.show({
        title: 'Error',
        message: error.response?.data?.error || 'Failed to send invitation',
        color: 'red'
      });
    }
  };

  const handleCancelInvitation = async (invitationId) => {
    try {
      await axios.delete(`${process.env.REACT_APP_PREFIX_BACKEND}/api/teams/invitations/${invitationId}`);
      notifications.show({
        title: 'Success',
        message: 'Invitation cancelled successfully',
        color: 'green'
      });
      await fetchPendingInvitations();
    } catch (error) {
      notifications.show({
        title: 'Error',
        message: error.response?.data?.error || 'Failed to cancel invitation',
        color: 'red'
      });
    }
  };

  return (
    <>
      <Stack spacing="md">
        {/* Search and Invite Section */}
        <div>
          <Title order={3} mb="md">Send Invitation</Title>
          
          <TextInput
            icon={<Search size={20} />}
            placeholder="Search for user name"
            value={searchQuery}
            onChange={(e) => {
              setSearchQuery(e.target.value);
              handleSearch(e.target.value);
            }}
            mb="sm"
          />

          {searchResults.length > 0 && (
            <ScrollArea.Autosize maxHeight={200}>
              <Stack spacing="xs">
                {searchResults.map((user) => (
                  <Group key={user.id} position="apart">
                    <Text>{user.name}</Text>
                    <Button
                      size="xs"
                      variant="light"
                      onClick={() => handleSendInvite(user.id)}
                      loading={loading}
                    >
                      Invite
                    </Button>
                  </Group>
                ))}
              </Stack>
            </ScrollArea.Autosize>
          )}
        </div>

        {/* Pending Invitations Section */}
        {pendingInvitations.length > 0 && (
          <>
            <Divider />
            <div>
              <Title order={3} mb="md">Pending Invitations</Title>
              <ScrollArea.Autosize maxHeight={200}>
                <Stack spacing="xs">
                  {pendingInvitations.map((invitation) => (
                    <Group key={invitation.id} position="apart">
                      <div>
                        <Text>{invitation.user_name}</Text>
                        <Text size="xs" color="dimmed">
                          Sent {new Date(invitation.created_at).toLocaleDateString()}
                        </Text>
                      </div>
                      <Group spacing="xs">
                        <Badge color="yellow">Pending</Badge>
                        <Button
                          size="xs"
                          variant="subtle"
                          color="red"
                          onClick={() => handleCancelInvitation(invitation.id)}
                        >
                          <X size={16} />
                        </Button>
                      </Group>
                    </Group>
                  ))}
                </Stack>
              </ScrollArea.Autosize>
            </div>
          </>
        )}
      </Stack>
      </>
  );
};

export default SendInvitation;