// src/theme/buttonStyles.js
import { Button } from '@mantine/core';
import './buttonStyles.css';

export const StyledButton = ({ variant = 'primary', ...props }) => {
  return (
    <Button
      {...props}
      className={`button-${variant}`}
    />
  );
};