import React, { useState, useEffect } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { Container, Stack, Alert, Box } from '@mantine/core';
import { AlertCircle } from 'lucide-react';
import { useAgentAttachment } from 'hooks/DirectAgentAttach/useAgentAttachment';
import EditAttachAgentHeader from 'components/DirectAgentAttach/EditAttachAgentHeader';
import AgentUpload from 'components/DirectAgentAttach/Upload/AgentUpload';
import AgentDeploy from 'components/DirectAgentAttach/Deploy/AgentDeploy';
import { useAuth } from 'context/AuthContext';
import DeploymentNavigationButton from 'components/DirectAgentAttach/DeploymentNavigationButton';
import { agentAttachedStyles as styles } from 'theme/agentAttachedStyles';

const getInitialPage = (status) => {
  if (['created', 'uploading', 'upload_failed', 'upload_validated'].includes(status)) return 'upload';
  if (['deploying', 'deploy_queue', 'deploy_run', 'deploy_failed'].includes(status)) return 'deploy';
  return 'upload'; // fallback
};

const AgentAttachedEdit = () => {
  const { competitionId, attacheAgentId } = useParams();
  const {
    attachment,
    isFetching,
    error,
    fetchAttachment,
    handleDeleteAttach,
    handleTemporaryStatus,
    competitionInfo
  } = useAgentAttachment(competitionId, attacheAgentId);

  const { currentUser, refreshAuthState } = useAuth();
  const [currentPage, setCurrentPage] = useState('upload');

  useEffect(() => {
    refreshAuthState();
  }, [refreshAuthState]);

  useEffect(() => {
    if (attachment?.status) {
      setCurrentPage(getInitialPage(attachment.status));
    }
  }, [attachment?.status]);

  if (attachment?.status === 'active') {
    return <Navigate to={`/attach-agent/view/${competitionId}/${attacheAgentId}`} replace />;
  }

  if (isFetching || !attachment) {
    return (
      <Container size="lg" style={styles.root}>
        <Alert icon={<AlertCircle size={16} />} color="blue">
          Loading agent information...
        </Alert>
      </Container>
    );
  }

  const renderPageContent = () => {
    if (currentPage === 'deploy') {
      return (
        <Box style={styles.deploySection}>
          <AgentDeploy
            competitionId={competitionId}
            attacheAgentId={attacheAgentId}
            status={attachment.status}
            statusMessage={attachment.last_status_message}
            queueInfo={attachment.queue_info}
            runInfo={attachment.run_info}
            onStatusChange={fetchAttachment}
          />
        </Box>
      );
    }

    return (
      <Box style={styles.uploadSection}>
        <AgentUpload
          competitionId={competitionId}
          attacheAgentId={attacheAgentId}
          status={attachment.status}
          statusMessage={attachment.last_status_message}
          onStatusChange={fetchAttachment}
          handleTemporaryStatus={handleTemporaryStatus}
          competitionInfo={competitionInfo}
        />
      </Box>
    );
  };

  return (
    <Box style={styles.root}>
        <EditAttachAgentHeader
          competitionId={competitionId}
          agentId={attacheAgentId}
          onDeleteAgent={handleDeleteAttach}
          isOwner={currentUser?.user_id === attachment.user_id}
          currentUser={currentUser}
          attachment={attachment}
          currentPage={currentPage}
          onPageChange={setCurrentPage}
          competitionInfo={competitionInfo}
        />
      
      <Container size="lg" style={styles.section}>
        <Stack spacing="xl">
          {error && (
            <Alert 
              icon={<AlertCircle size={16} />} 
              color="red" 
              title="Error"
              style={styles.alertContainer}
            >
              {error}
            </Alert>
          )}

          {renderPageContent()}
          
          <Box style={styles.navigationButton}>
            <DeploymentNavigationButton
              currentPage={currentPage}
              attachmentStatus={attachment.status}
              onPageChange={setCurrentPage}
              variant="light"
              size="sm"
            />
          </Box>
        </Stack>
      </Container>
    </Box>
  );
};

export default AgentAttachedEdit;