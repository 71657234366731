import React, { useState } from 'react';
import { Stack, Tabs, Box, Container } from '@mantine/core';
import { LayoutDashboard, Trophy, Settings, History } from 'lucide-react';
import { useLocation } from 'react-router-dom';
import AgentResultsDashboard from 'components/DirectAgentAttach/Monitor/AgentResultsDashboard';
import Configuration from 'components/DirectAgentAttach/Monitor/Configuration';
import AgentResultOverview from 'components/DirectAgentAttach/Monitor/AgentResultOverview';
import LeaderboardTab from 'pages/Competition/LeaderboardTab';
import AgentGamesHistory from './AgentGamesHistory';
import { agentMonitorStyles as styles } from 'theme/agentMonitorStyles';
import 'theme/agentMonitorTabs.css';

const AgentMonitor = ({
  competitionId,
  attacheAgentId,
  status,
  onStatusChange,
  currentUser,
  isAdmin,
  isAuthenticated,
  agentOwnerId,
  initialVisibility
}) => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(location.state?.defaultTab || 'dashboard');
  
  return (
    <>
      <Box style={styles.tabsContainer}>
        <Container size="lg">
          <Tabs 
            value={activeTab} 
            onChange={setActiveTab}
            classNames={{
              root: 'agent-monitor-tabs',
              list: 'agent-monitor-tabs-list',
              tab: 'agent-monitor-tab',
              panel: 'agent-monitor-panel'
            }}
          >
            <Tabs.List>
              <Tabs.Tab 
                value="dashboard" 
                leftSection={<LayoutDashboard size={16} />}
              >
                Dashboard
              </Tabs.Tab>
              <Tabs.Tab 
                value="games"
                leftSection={<History size={16} />}
              >
                Games History
              </Tabs.Tab>
              <Tabs.Tab 
                value="leaderboard"
                leftSection={<Trophy size={16} />}
              >
                Leaderboard
              </Tabs.Tab>
              <Tabs.Tab 
                value="configuration"
                leftSection={<Settings size={16} />}
              >
                Configuration
              </Tabs.Tab>
            </Tabs.List>
          </Tabs>
        </Container>
      </Box>

      <Box style={styles.contentContainer}>
        <Container size="lg">
          <Tabs 
            value={activeTab} 
            onChange={setActiveTab}
            classNames={{
              root: 'agent-monitor-tabs',
              list: 'agent-monitor-tabs-list',
              tab: 'agent-monitor-tab',
              panel: 'agent-monitor-panel'
            }}
          >
            <Tabs.Panel value="dashboard">
              <Stack spacing="md">
                <AgentResultOverview 
                  competitionId={competitionId} 
                  attacheAgentId={attacheAgentId} 
                />
                <AgentResultsDashboard 
                  competitionId={competitionId} 
                  attacheAgentId={attacheAgentId} 
                />
              </Stack>
            </Tabs.Panel>
            
            <Tabs.Panel value="games">
              <AgentGamesHistory 
                competitionId={competitionId} 
                attacheAgentId={attacheAgentId} 
              />
            </Tabs.Panel>
            
            <Tabs.Panel value="leaderboard">
              <LeaderboardTab competitionId={competitionId} />
            </Tabs.Panel>
            
            <Tabs.Panel value="configuration">
              <Configuration 
                competitionId={competitionId} 
                attacheAgentId={attacheAgentId} 
                currentUser={currentUser} 
                isAdmin={isAdmin} 
                isAuthenticated={isAuthenticated} 
                agentOwnerId={agentOwnerId}
                initialVisibility={initialVisibility}
              />
            </Tabs.Panel>
          </Tabs>
        </Container>
      </Box>
    </>
  );
};

export default AgentMonitor;