import React, { useEffect } from "react";
import '@mantine/notifications/styles.css';
import '@mantine/tiptap/styles.css';
import '@mantine/core/styles.css';
import 'katex/dist/katex.min.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
  Outlet
} from "react-router-dom";

import ReactGA from "react-ga4";

import { AuthProvider, useAuth } from "./context/AuthContext";
import ListCompetition from "./pages/Competition/List";
import ViewCompetition from "./pages/Competition/View";
import EditCompetition from "./pages/Competition/Edit";
import ListAgent from "./pages/Agent/List";
import EditAgent from "./pages/Agent/Edit";
import ListEnvironment from "./pages/Environment/List";
import EditEnvironment from "./pages/Environment/Edit";
import CompanyInfo from "./pages/CompanyInfo";
import MyAgents from "./pages/MyAgents";

import Profile from "./pages/Profile";
import Login from "./pages/Login";
import LoginMail from "./pages/LoginMail";
import Shell from "./components/Shell";

import AgentAttachedEdit from "./pages/AgentAttachedEdit";
import AgentAttachedView from "./pages/AgentAttachedView"
import NotFound from "./pages/NotFound";
import axios from "axios";
import { MantineProvider } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import { Notifications } from '@mantine/notifications';
import { theme } from './theme';
import GeneralDiscussions from "pages/GeneralDiscussions";

import Landing from './pages/Landing';
import LandingShell from './components/LandingShell';

import SearchResults from './pages/SearchResults';

import TagsManagement from './pages/TagsManagement'

import UserRankings from './pages/UserRankings'

import "./App.css"

axios.defaults.baseURL = `${process.env.REACT_APP_PREFIX_BACKEND}`;

axios.defaults.withCredentials = true;

ReactGA.initialize('G-F4L73Q591C'); 



function App() {
  return (
    <MantineProvider theme={theme} withGlobalStyles withNormalizeCSS>
      <Notifications position="top-right" />
      <ModalsProvider>
        <AuthProvider>
          <Router>
            <GoogleAnalyticsRouteTracker />
            <Routes>
              <Route 
                path="/" 
                element={
                  <LandingShell>
                    <Landing />
                  </LandingShell>
                } 
              />
              
              <Route element={<Shell><Outlet /></Shell>}>
                <Route path="/company" element={<CompanyInfo />} />
                <Route path="/listcompetition" element={<ListCompetition />} />
                <Route path="/general-discussions" element={<GeneralDiscussions />} />
                <Route path="/viewcompetition/:competitionId" element={<ViewCompetition />} />
                <Route path="/login" element={<Login />} />
                <Route path="/login-mail/*" element={<LoginMail />} />
                <Route path="/search" element={<SearchResults />} />
                <Route path="/ranking" element={<UserRankings />} 
/>
                {/* Protected Routes */}
                {/* Agent Attachment Routes */}
                <Route path="/attach-agent/edit/:competitionId/:attacheAgentId" 
                  element={<RequireAuth redirectTo="/login"><AgentAttachedEdit /></RequireAuth>} />
                <Route path="/attach-agent/view/:competitionId/:attacheAgentId" 
                  element={<RequireAuth redirectTo="/login"><AgentAttachedView /></RequireAuth>} />
                <Route path="/my-agents" 
                  element={<RequireAuth redirectTo="/login"><MyAgents /></RequireAuth>} />
                <Route path="/my-profile" 
                  element={<RequireAuth redirectTo="/login"><Profile /></RequireAuth>} />

                {/* Admin Routes */}
                <Route path="/editcompetition" 
                  element={<RequireAdmin><EditCompetition /></RequireAdmin>} />
                <Route path="/editcompetition/:competitionId" 
                  element={<RequireAdmin><EditCompetition /></RequireAdmin>} />
                <Route path="/listenvironment" 
                  element={<RequireAdmin><ListEnvironment /></RequireAdmin>} />
                <Route path="/editenvironment/:objectId" 
                  element={<RequireAdmin><EditEnvironment /></RequireAdmin>} />
                <Route path="/editenvironment" 
                  element={<RequireAdmin><EditEnvironment /></RequireAdmin>} />
                <Route path="/listagent" 
                  element={<RequireAdmin><ListAgent /></RequireAdmin>} />
                <Route path="/editagent/:objectId" 
                  element={<RequireAdmin><EditAgent /></RequireAdmin>} />
                <Route path="/editagent" 
                  element={<RequireAdmin><EditAgent /></RequireAdmin>} />
                                <Route path="/tags" 
                  element={<RequireAdmin><TagsManagement /></RequireAdmin>} />
                <Route path="*" element={<NotFound />} />
              </Route>
            </Routes>
          </Router>
        </AuthProvider>
      </ModalsProvider>
    </MantineProvider>
  );
}

// Component to track route changes
function GoogleAnalyticsRouteTracker() {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);

  return null;
}

function RequireAuth({ children, redirectTo }) {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? children : <Navigate to={redirectTo} />;
}

function RequireAdmin({ children }) {
  const { isAdmin, isAuthenticated } = useAuth();
  return isAuthenticated && isAdmin ? children : <Navigate to="/login" />;
}

export default App;
