import React from 'react';
import { Paper, Title, Text, List, Group, ActionIcon, Container } from '@mantine/core';
import { IconUserCircle, IconTrophy, IconRobot, IconBook, IconEye } from '@tabler/icons-react';
import { Info } from 'lucide-react';

const IconIntroduction = () => (
  <Container size="xl" py="md">
    <List 
      spacing="md"
      className="ml-2"
    >
      <List.Item
        icon={
          <ActionIcon 
            color="blue" 
            variant="subtle" 
            className="pointer-events-none"
          >
            <IconUserCircle size={16} />
          </ActionIcon>
        }
      >
        <Text size="sm" className="leading-relaxed">
          View your agent details, including configuration and performance metrics
        </Text>
      </List.Item>
      
      <List.Item
        icon={
          <ActionIcon 
            color="gray" 
            variant="subtle" 
            className="pointer-events-none"
          >
            <IconEye size={16} />
          </ActionIcon>
        }
      >
        <Text size="sm" className="leading-relaxed">
          Explore public agents to learn from their approaches and strategies
        </Text>
      </List.Item>
      
      <List.Item
        icon={
          <ActionIcon 
            color="violet" 
            variant="subtle" 
            className="pointer-events-none"
          >
            <IconBook size={16} />
          </ActionIcon>
        }
      >
        <Text size="sm" className="leading-relaxed">
          Access agent discussions to share insights and participate in the community
        </Text>
      </List.Item>
    </List>
  </Container>
);

export default IconIntroduction;