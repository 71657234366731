export const landingStyles = {
  container: {
    maxWidth: '75rem',    // 1200px -> 75rem
    width: '100%',
    margin: '0 auto',
    padding: '0 1rem'     // 16px -> 1rem
  },
  
  images: {
    hero: {
      maxWidth: '100%',
      maxHeight: '20.5rem',  // 440px -> 27.5rem
      objectFit: 'contain',
      margin: '0 auto'
    },
    section: {
      maxWidth: '100%',
      maxHeight: '25.5rem',  // 360px -> 22.5rem
      objectFit: 'contain',
      margin: '0 auto'
    },
    icon: {
      width: '8rem',         // 64px -> 4rem
      height: '8rem',        // 64px -> 4rem
      objectFit: 'contain',
      margin: '0 auto 1.5rem'
    },
    robot: {
      maxWidth: '100%',
      maxHeight: '20rem',    // 320px -> 20rem
      objectFit: 'contain',
      margin: '0 auto'
    },
    trophy: {
      width: '7.5rem',       // 120px -> 7.5rem
      height: '7.5rem',      // 120px -> 7.5rem
      objectFit: 'contain'
    }
  },

  heroSection: {
    root: {
      alignItems: 'center',
      // minHeight: 'calc(100vh - 20rem)',  // 80px -> 5rem
      backgroundColor: 'var(--mantine-color-purple-10)',
      padding: '0 0 5rem 0',
      margin: '0',
      width: '100%'
    }
  },




  sectionTitle: {
    fontSize: '2.25rem',     // 36px -> 2.25rem
    fontWeight: 700,
    color: 'var(--mantine-color-dark-9)',
    marginBottom: '1.5rem'   // 24px -> 1.5rem
  },

  sectionText: {
    fontSize: '1.125rem',    // 18px -> 1.125rem
    lineHeight: 1.6,
    color: 'var(--mantine-color-gray-7)',
    marginBottom: '2rem'     // 32px -> 2rem
  },

  // How section specific styles
  how: {
    titleWrapper: {
      display: 'flex',
      alignItems: 'center',
      gap: '0.75rem',
      marginBottom: '2rem'
    },
    title: {
      fontSize: '2.25rem',    // 36px -> 2.25rem
      fontWeight: 700,
      color: 'var(--mantine-color-dark-9)',
      margin: 0,
      lineHeight: 1.2
    },
    infoIcon: {
      width: '1.5rem',       // 24px -> 1.5rem
      height: '1.5rem',      // 24px -> 1.5rem
      color: 'var(--mantine-color-gray-6)',
      marginTop: '0.5rem'    // Align with title text
    },
    steps: {
      display: 'flex',
      flexDirection: 'column',
      gap: '2rem'
    },
    step: {
      display: 'flex',
      alignItems: 'flex-start',
      gap: '1.5rem'
    },
    stepNumber: {
      width: '2rem',         // 32px -> 2rem
      height: '2rem',        // 32px -> 2rem
      borderRadius: '50%',
      backgroundColor: 'var(--mantine-color-gray-2)',
      color: 'var(--mantine-color-gray-7)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '1rem',
      fontWeight: 600,
      marginTop: '0.25rem'   // Align with text
    },
    stepContent: {
      flex: 1
    },
    stepTitle: {
      fontSize: '1.25rem',   // 20px -> 1.25rem
      fontWeight: 800,       // Extra bold for titles
      color: 'var(--mantine-color-dark-9)',
      marginBottom: '0.5rem',
      lineHeight: 1.2
    },
    stepDescription: {
      fontSize: '1rem',
      lineHeight: 1.6,
      color: 'var(--mantine-color-gray-7)'
    }
  },

  card: {
    root: {
      padding: '2rem',
      borderRadius: '1rem',
      border: '1px solid var(--mantine-color-gray-2)',
      backgroundColor: 'var(--mantine-color-white)',
      transition: 'transform 0.2s ease, box-shadow 0.2s ease',
      height: '100%',
      '&:hover': {
        transform: 'translateY(-0.25rem)',
        boxShadow: '0 0.75rem 1.5rem rgba(0, 0, 0, 0.1)'
      }
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      textAlign: 'center'
    },
    title: {
      fontSize: '1.25rem',
      fontWeight: 700,
      color: 'var(--mantine-color-dark-9)',
      marginBottom: '1rem',
      textAlign: 'center'
    },
    description: {
      fontSize: '1rem',
      lineHeight: 1.6,
      color: 'var(--mantine-color-gray-7)',
      textAlign: 'center'
    }
  },

  list: {
    item: {
      fontSize: '1rem',      // 16px -> 1rem
      lineHeight: 1.6,
      color: 'var(--mantine-color-gray-7)',
      marginBottom: '1rem',  // 16px -> 1rem
      paddingLeft: '1.5rem', // 24px -> 1.5rem
      position: 'relative'
    },
    number: {
      width: '1.5rem',       // 24px -> 1.5rem
      height: '1.5rem',      // 24px -> 1.5rem
      borderRadius: '50%',
      backgroundColor: 'var(--mantine-color-purple-6)',
      color: 'var(--mantine-color-white)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      left: 0,
      top: '0.125rem'        // 2px -> 0.125rem
    }
  },
// Add to your landingStyles object
why: {
  section: {
    backgroundColor: 'var(--mantine-color-white)',
    padding: '5rem 0',
  },
  title: {
    fontSize: '2.25rem',
    fontWeight: 700,
    color: 'var(--mantine-color-dark-8)',
    textAlign: 'center',
    marginBottom: '1rem'
  },
  subtitle: {
    fontSize: '1.125rem',
    color: 'var(--mantine-color-gray-7)',
    textAlign: 'center',
    maxWidth: '48rem',
    margin: '0 auto 3rem',
    lineHeight: 1.6
  },
  grid: {
    gap: '2rem'
  },
  card: {
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '2rem',
      height: '100%',
      backgroundColor: 'var(--mantine-color-white)',
      border: '1px solid var(--mantine-color-gray-3)',
      borderRadius: 'var(--mantine-radius-md)',
      textAlign: 'center'
    }
  },
  feature: {
    title: {
      fontSize: '1.25rem',
      fontWeight: 600,
      color: 'var(--mantine-color-dark-8)',
      marginBottom: '0.75rem',
      textAlign: 'center'
    },
    description: {
      fontSize: '1rem',
      lineHeight: 1.6,
      color: 'var(--mantine-color-gray-7)',
      textAlign: 'center'
    }
  }
},
  sections: {
    light: {
      backgroundColor: 'var(--mantine-color-white)',
      padding: '5rem 0'      // 80px -> 5rem
    },
    dark: {
      backgroundColor: 'var(--mantine-color-purple-0)',
      padding: '5rem 0'      // 80px -> 5rem
    }
  }
};