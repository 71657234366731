import { 
  Stack, 
  Title, 
  Group, 
  Button, 
  Modal, 
  Paper, 
  Text, 
  Select,
  Container,
  Image
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import useGeneralDiscussions from 'hooks/useGeneralDiscussions';
import { useState } from 'react';
import { ArrowUpDown, MessageCircle, MessageSquare, ThumbsUp, TrendingUp } from 'lucide-react';
import DiscussionDetail from 'components/Discussion/DiscussionDetail';
import DiscussionEditor from 'components/Discussion/DiscussionEditor';
import DiscussionCard from 'components/Discussion/DiscussionCard';
import { useAuth } from 'context/AuthContext';

const GeneralDiscussions = () => {
  const { isAuthenticated, currentUser, isAdmin } = useAuth();
  const [selectedDiscussionId, setSelectedDiscussionId] = useState(null);
  const [opened, { open, close }] = useDisclosure(false);
  const [sortBy, setSortBy] = useState('recent');
  
  const {
    discussions,
    loading,
    error,
    createDiscussion,
    voteDiscussion,
    updateDiscussion,
    deleteDiscussion,
    fetchDiscussions
  } = useGeneralDiscussions();

  const handleSortChange = (value) => {
    setSortBy(value);
    fetchDiscussions(value);
  };

  const handleCreateDiscussion = async (title, content) => {
    await createDiscussion(title, content);
    close();
  };

  const selectedDiscussion = discussions.find(d => d.id === selectedDiscussionId);

  if (loading) return <Text>Loading discussions...</Text>;
  if (error) return <Text color="red">{error}</Text>;

  if (selectedDiscussionId && selectedDiscussion) {
    return (
      <DiscussionDetail
        isAuthenticated={isAuthenticated}
        currentUser={currentUser}
        discussion={selectedDiscussion}
        onBack={() => setSelectedDiscussionId(null)}
        updateDiscussion={updateDiscussion}
        deleteDiscussion={deleteDiscussion}
        voteDiscussion={voteDiscussion}
      />
    );
  }

  return (
    <Container size="xl" py="xl">
      <Stack spacing="xl">
        {/* Hero Section */}
        <Paper radius="md" p="xl">
          <Group align="flex-start" justify="space-between">
            <Stack spacing="lg" style={{ width: '50%' }}>
              <div>
                <Title order={1}>Discussions</Title>
                <Text size="lg" mt="sm" color="dimmed">
                  Join the conversation with fellow AI enthusiasts and competitors. Share your insights,
                  ask questions, and learn from others' experiences. From competition strategies to
                  technical discussions, this is your space to connect with the community.
                </Text>
              </div>
  
              {isAuthenticated && (
                <Button 
                  onClick={open}
                  leftSection={<MessageCircle size={16} />}
                >
                  Share Your Thoughts
                </Button>
              )}
            </Stack>
  
            <Image 
              src="/discussion.jpg"
              alt="Discussions"
              radius="md"
              style={{ width: '30%', height: 'auto' }}
            />
          </Group>
        </Paper>

        {/* Main Content */}
        <Stack spacing="xl">
          <Group justify="space-between" align="flex-end">
            <Select
              value={sortBy}
              onChange={handleSortChange}
              data={[
                { value: 'recent', label: 'Most Recent' },
                { value: 'trending', label: 'Trending' }
              ]}
              leftSection={<ArrowUpDown size={16} />}
              w={200}
            />
          </Group>

          {discussions.length === 0 ? (
            <Paper p="xl" withBorder>
              <Stack align="center" spacing="md">
                <MessageCircle size={32} />
                <Text align="center" c="dimmed">
                  No discussions found. Be the first to start a conversation!
                </Text>
                {isAuthenticated && (
                  <Button variant="light" onClick={open}>
                    Create Discussion
                  </Button>
                )}
              </Stack>
            </Paper>
          ) : (
            <Stack>
              {discussions.map(discussion => (
                <DiscussionCard
                  key={discussion.id}
                  discussion={discussion}
                  onVote={() => voteDiscussion(discussion.id)}
                  onClick={() => setSelectedDiscussionId(discussion.id)}
                />
              ))}
            </Stack>
          )}
        </Stack>
      </Stack>

      <Modal 
        opened={opened} 
        onClose={close} 
        title="Create Discussion"
        size="xl"
      >
        <DiscussionEditor
          onSubmit={handleCreateDiscussion}
          onCancel={close}
        />
      </Modal>
    </Container>
  );
};

export default GeneralDiscussions;