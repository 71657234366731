// src/theme/attachAgentHeaderStyles.js
export const attachAgentHeaderStyles = {
    wrapper: {
      backgroundColor: 'var(--mantine-color-background-0)',
      width: '100%',
      padding: '2rem 0'
    },
    container: {
      maxWidth: '75rem',
      margin: '0 auto',
      padding: 'var(--mantine-spacing-md)'
    },
    mainContent: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: 'var(--mantine-spacing-lg)'
    },
    agentInfo: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      width: '100%'
    },
    titleSection: {
      display: 'flex',
      gap: 'var(--mantine-spacing-md)',
      alignItems: 'center'
    },
    title: {
      color: 'var(--mantine-color-blue-11)',
      fontSize: '2.5rem',
      fontWeight: 700,
      lineHeight: 1.2
    },
    badge: {
      padding: '0.5rem 0.75rem',
      fontSize: 'var(--mantine-font-size-md)'
    },
    stats: {
      display: 'flex',
      gap: 'var(--mantine-spacing-xl)',
      alignItems: 'center',
      marginTop: 'var(--mantine-spacing-md)'
    },
    statItem: {
      display: 'flex',
      alignItems: 'center',
      gap: 'var(--mantine-spacing-xs)',
      color: 'var(--mantine-color-gray-6)'
    },
    icon: {
      color: 'var(--mantine-color-blue-6)'
    },
    deleteButton: {
      color: 'var(--mantine-color-red-6)',
      '&:hover': {
        backgroundColor: 'var(--mantine-color-red-0)',
        color: 'var(--mantine-color-red-7)'
      }
    },
    allocationSection: {
      backgroundColor: 'var(--mantine-color-white)',
      borderRadius: 'var(--mantine-radius-md)',
      padding: 'var(--mantine-spacing-lg)',
      border: '1px solid var(--mantine-color-gray-2)'
    },
    competitionLink: {
      fontSize: 'var(--mantine-font-size-sm)',
      padding: 'var(--mantine-spacing-xs) var(--mantine-spacing-sm)',
      '&:hover': {
        backgroundColor: 'var(--mantine-color-blue-0)'
      }
    }
  };