import React, { useState, useEffect } from 'react';
import { Box, Text, Stack, Group, ThemeIcon } from '@mantine/core';
import { List } from 'lucide-react';

const TableOfContents = ({ sections }) => {
  const [activeSection, setActiveSection] = useState('');

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveSection(entry.target.id);
          }
        });
      },
      {
        rootMargin: '-20% 0px -75% 0px'
      }
    );

    sections.forEach(({ id }) => {
      const element = document.getElementById(id);
      if (element) observer.observe(element);
    });

    return () => observer.disconnect();
  }, [sections]);

  const handleClick = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      const headerHeight = 72;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - headerHeight;
      
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  };

  return (
    <Box 
      pos="sticky" 
      top="5.5rem"
      style={{ 
        zIndex: 1,
        paddingLeft: 'var(--mantine-spacing-md)',
        borderLeft: '1px solid var(--mantine-color-gray-2)'
      }}
    >
      <Group spacing="sm" mb="md">
        <Text size="lg" fw={700}>Table of Contents</Text>
      </Group>

      <Stack spacing={4}>
        {sections.map(({ id, label }) => (
          <Text
            key={id}
            component="button"
            onClick={() => handleClick(id)}
            size="sm"
            lh={1.5}
            style={{
              background: 'none',
              border: 'none',
              cursor: 'pointer',
              textAlign: 'left',
              padding: '4px 8px',
              width: '100%',
              fontWeight: activeSection === id ? 600 : 400,
              color: activeSection === id ? 'var(--mantine-color-blue-6)' : 'inherit',
              '&:hover': {
                backgroundColor: 'var(--mantine-color-gray-0)'
              }
            }}
          >
            {label}
          </Text>
        ))}
      </Stack>
    </Box>
  );
};

export default TableOfContents;