import React, { useState } from 'react';
import { Paper, Title, Stack, Group, Text, Loader, Modal, Divider } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconFileText, IconEye, IconDownload, IconBrandCodesandbox } from '@tabler/icons-react';
import { List, ThemeIcon, Button, Tooltip } from '@mantine/core';

const getFileActions = (file, handlers, onColabOpen) => {
  const isNotebook = file.endsWith('.ipynb');
  const isPythonMain = file.endsWith('.py');

  return (
    <Group spacing={8} wrap="nowrap">
      {isPythonMain && (
        <Tooltip label="View Code">
          <Button
            variant="light"
            size="xs"
            color="blue"
            onClick={() => handlers.handleViewCode(file)}
          >
            <IconEye size={14} />
          </Button>
        </Tooltip>
      )}
      {isNotebook && (
        <Tooltip label="Open in Colab">
          <Button
            variant="light"
            size="xs"
            color="violet"
            onClick={() => onColabOpen(file)}
            loading={handlers.isColabLoading}
          >
            <IconBrandCodesandbox size={14} />
          </Button>
        </Tooltip>
      )}
      <Tooltip label="Download File">
        <Button
          variant="light"
          size="xs"
          color="blue"
          onClick={() => handlers.handleDownload(file)}
        >
          <IconDownload size={14} />
        </Button>
      </Tooltip>
    </Group>
  );
};

export default getFileActions;