import React, { useState, useCallback } from 'react';
import { Paper, Title, Stack, Group, Text, FileButton, Button, Modal, Divider, Loader } from '@mantine/core';
import { Upload, BookOpen, ChevronDown, ChevronUp, Download, Trash } from 'lucide-react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import rehypeRaw from 'rehype-raw';
import axios from 'axios';
import { notifications } from '@mantine/notifications';

const DocumentationManager = ({
  competitionId,
  attacheAgentId,
  files,
  onUpdate,
  fileContents,
  onFetchContent,
  onDownload,
  isOwner = false
}) => {
  const [expandedFile, setExpandedFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  const handleFileUpload = async (file) => {
    if (!file) return;
    setIsUploading(true);
    
    try {
      const formData = new FormData();
      formData.append('file', file);
      
      await axios.put(
        `/api/direct_attache_agents/competition/${competitionId}/${attacheAgentId}/docs`,
        formData
      );

      notifications.show({
        title: 'Success',
        message: 'Documentation file uploaded successfully',
        color: 'green'
      });
      
      if (onUpdate) onUpdate();
    } catch (error) {
      notifications.show({
        title: 'Error',
        message: error.response?.data?.error || 'Failed to upload documentation',
        color: 'red'
      });
    } finally {
      setIsUploading(false);
    }
  };

  const handleFileDelete = async (filename) => {
    try {
      await axios.delete(
        `/api/direct_attache_agents/competition/${competitionId}/${attacheAgentId}/docs`,
        { params: { filename } }
      );

      notifications.show({
        title: 'Success',
        message: 'Documentation file deleted successfully',
        color: 'green'
      });
      
      if (onUpdate) onUpdate();
    } catch (error) {
      notifications.show({
        title: 'Error',
        message: error.response?.data?.error || 'Failed to delete documentation',
        color: 'red'
      });
    }
  };

  const handleExpand = useCallback((fileName) => {
    if (expandedFile === fileName) {
      setExpandedFile(null);
    } else {
      setExpandedFile(fileName);
      if (!fileContents[fileName]) {
        onFetchContent(fileName);
      }
    }
  }, [expandedFile, fileContents, onFetchContent]);

  const mdFiles = Object.keys(files || {}).filter(f => f.endsWith('.md'));

  return (
    <Paper shadow="xs" p="md">
      <Stack spacing="md">
        <Group position="apart" align="center">
          <Group spacing="xs">
            <BookOpen className="text-blue-500" size={24} />
            <Title order={3}>Documentation</Title>
          </Group>
          
          {isOwner && (
            <FileButton 
              onChange={handleFileUpload}
              accept=".md"
              disabled={isUploading}
            >
              {(props) => (
                <Button
                  {...props}
                  leftSection={<Upload className="w-4 h-4" />}
                  loading={isUploading}
                  size="sm"
                >
                  Upload Documentation
                </Button>
              )}
            </FileButton>
          )}
        </Group>

        {mdFiles.length === 0 ? (
          <Text color="dimmed" size="sm">
            No documentation files available.
            {isOwner && " Add markdown files to document your agent."}
          </Text>
        ) : (
          <Stack spacing="md">
            {mdFiles.map((fileName) => (
              <Paper 
                key={fileName}
                shadow="xs" 
                p="md" 
                radius="md"
                className="border border-gray-200"
              >
                <Stack spacing="sm">
                  {/* File Header */}
                  <Group position="apart" className="items-center">
                    <Text weight={500}>{fileName}</Text>
                    <Group spacing="xs">
                      <Button
                        variant="subtle"
                        size="xs"
                        color="gray"
                        leftSection={<Download size={14} />}
                        onClick={() => onDownload(fileName)}
                      >
                        Download
                      </Button>
                      <Button
                        variant="subtle"
                        size="xs"
                        color="blue"
                        onClick={() => handleExpand(fileName)}
                        rightSection={expandedFile === fileName ? <ChevronUp size={14} /> : <ChevronDown size={14} />}
                      >
                        {expandedFile === fileName ? 'Collapse' : 'Preview'}
                      </Button>
                      {isOwner && (
                        <Button
                          variant="subtle"
                          size="xs"
                          color="red"
                          leftSection={<Trash size={14} />}
                          onClick={() => handleFileDelete(fileName)}
                          disabled={isUploading}
                        >
                          Delete
                        </Button>
                      )}
                    </Group>
                  </Group>

                  {/* Preview Content */}
                  {expandedFile === fileName && (
                    <>
                      <Divider my="xs" />
                      <Paper 
                        className="bg-gray-50 p-4 rounded-md max-h-[32rem] overflow-y-auto"
                        withBorder
                      >
                        {fileContents[fileName] ? (
                          <div className="markdown-body">
                            <ReactMarkdown
                              remarkPlugins={[remarkGfm]}
                              rehypePlugins={[rehypeRaw]}
                              components={{
                                code({node, inline, className, children, ...props}) {
                                  const match = /language-(\w+)/.exec(className || '');
                                  return !inline && match ? (
                                    <SyntaxHighlighter
                                      style={oneDark}
                                      language={match[1]}
                                      PreTag="div"
                                      {...props}
                                    >
                                      {String(children).replace(/\n$/, '')}
                                    </SyntaxHighlighter>
                                  ) : (
                                    <code className={className} {...props}>
                                      {children}
                                    </code>
                                  );
                                }
                              }}
                            >
                              {fileContents[fileName]}
                            </ReactMarkdown>
                          </div>
                        ) : (
                          <Group position="center" py="xl">
                            <Loader size="sm" />
                            <Text size="sm">Loading content...</Text>
                          </Group>
                        )}
                      </Paper>
                    </>
                  )}
                </Stack>
              </Paper>
            ))}
          </Stack>
        )}
      </Stack>
    </Paper>
  );
};

export default DocumentationManager;