export const shellStyles = {
  mainHeader: {
    height: '4.5rem',
    backgroundColor: 'var(--mantine-color-white)',
    width: 'calc(100% - 18rem)',
    top: 0,
    zIndex: 100,
    position: 'fixed',
    left: '18rem',
  },
  
  navbar: {
    backgroundColor: 'var(--mantine-color-purple-11)',
    border: 'none',
    borderRight: '1px solid var(--mantine-color-gray-2)',
    position: 'fixed',
    top: 0,
    left: 0,
    height: '100vh',
    zIndex: 110,
    transition: 'width 200ms ease, padding 200ms ease, opacity 200ms ease',
    opacity: 1
  },

  logoWrapper: {
    position: 'fixed',
    top: 0,
    left: '3rem',
    height: '4.5rem',
    zIndex: 120,
    display: 'flex',
    alignItems: 'center',
    padding: '0 1.5rem',
    backgroundColor: 'transparent'
  },

  burgerWrapper: {
    height: '4.5rem',
    width: '4rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 130,
    backgroundColor: 'transparent',
    cursor: 'pointer'
  },

  logo: {
    color: 'var(--mantine-color-blue-11)',
    fontSize: '2.5rem',
    fontWeight: 700,
    textDecoration: 'none',
    fontFamily: 'var(--mantine-font-family)',
    whiteSpace: 'nowrap'
  },

  searchInput: {
    root: { 
      flex: 1,
      width: '100%',
    },
    input: {
      height: '2.75rem',
      backgroundColor: 'var(--mantine-color-white)',
      border: '1px solid var(--mantine-color-gray-2)',
      borderRadius: '6.25rem',
      paddingLeft: '2.75rem',
      '&:focus': { 
        borderColor: 'var(--mantine-color-purple-6)'
      }
    },
    section: {
      color: 'var(--mantine-color-gray-6)',
      paddingLeft: '1rem'
    }
  },

  mainContent: {
    marginTop: '4.5rem',
    transition: 'margin-left 200ms ease',

    '@media (maxWidth: 768px)': {
      marginLeft: '0',
      overflowX: 'auto',     // Allow horizontal scrolling when needed
      overflowY: 'visible',  // Maintain vertical scroll behavior
      minHeight: '100vh',    // Ensure full height content
      WebkitOverflowScrolling: 'touch',
    }
  }
};