// theme/agentAttachedStyles.js

export const agentAttachedStyles = {
    root: {
      padding: 0,
      paddingBottom: 'calc(var(--mantine-spacing-xl) * 2)'
    },
  
    section: {
      marginBottom: 'calc(var(--mantine-spacing-xl) * 1.5)',
      padding: 0
    },
  
    sectionTitle: {
      color: 'var(--mantine-color-blue-11)',
      fontSize: '1.8rem',
      fontWeight: 700,
      marginBottom: 'var(--mantine-spacing-lg)'
    },
  
    uploadSection: {
      borderRadius: 'var(--mantine-radius-md)',
      padding: 0
    },
  
    instructionsContainer: {
      marginBottom: 0
    },
  
    runtimeSection: {
      borderRadius: 'var(--mantine-radius-md)',
      padding: 0,
      marginTop: 0
    },
  
    fileManagementSection: {
      marginTop: 'var(--mantine-spacing-xl)'
    },
  
    deploySection: {
      borderRadius: 'var(--mantine-radius-md)',
      padding: 'var(--mantine-spacing-xl)',
      marginTop: 'var(--mantine-spacing-xl)'
    },
  
  
    alertContainer: {
      marginBottom: 'var(--mantine-spacing-lg)'
    },
  
    navigationButton: {
      marginTop: 'var(--mantine-spacing-xl)',
      display: 'flex',
      justifyContent: 'flex-end'
    },
  
    editorContainer: {
      marginTop: 'var(--mantine-spacing-md)',
      border: '1px solid var(--mantine-color-gray-3)',
      borderRadius: 'var(--mantine-radius-md)',
      overflow: 'hidden'
    },
  
    statusBadge: {
      textTransform: 'capitalize'
    }
  };