export const headerStyles = {
  wrapper: {
    backgroundColor: 'var(--mantine-color-background-0)',
    width: '100%',
    padding: '2rem 0'
  },
  container: {
    maxWidth: '75rem',
    margin: '0 auto',
    padding: 'var(--mantine-spacing-md)'
  },
  rightContent: {
    width: '13.75rem',
    display: 'flex',
    flexDirection: 'column'
  },
  miniatureCard: {
    width: '13.75rem',
    height: '13.75rem',
    backgroundColor: 'var(--mantine-color-white)',
    border: '1px solid var(--mantine-color-gray-2)',
    borderRadius: 'var(--mantine-radius-md)',
    overflow: 'hidden',
    marginBottom: 'var(--mantine-spacing-sm)'
  },
  miniatureImage: {
    width: '13.75rem',
    height: '13.75rem',
    objectFit: 'cover'
  },
  submitButtonWrapper: {
    display: 'flex',
    justifyContent: 'center'
  },
  title: {
    color: 'var(--mantine-color-blue-11)',
    fontSize: '2.5rem',
    fontWeight: 700,
    lineHeight: 1.2,
    marginBottom: '1.5rem'
  }
};