import React from 'react';
import { Box, Text, Group, Button, Stack, Checkbox, Paper, Image } from '@mantine/core';
import { Link, useNavigate } from 'react-router-dom';
import { ExternalLink } from 'lucide-react';
import { competitionOverviewStyles as styles } from "theme/competitionOverviewStyles";
import { StyledButton } from 'theme/buttonStyles';
const Instructions = ({ competitionId, handleJoinCompetition }) => {
  const navigate = useNavigate();

  const navigateToTab = (tabName) => {
    navigate(`/viewcompetition/${competitionId}`, { 
      state: { defaultTab: tabName } 
    });
  };

  return (
    <Stack spacing="xl">
      <Group position="apart" align="flex-start">
        <Text size="lg" style={{ flex: 1 }}>
          You're ready to create an agent that will interact with others.
          Here are the key things you need to know before starting:
        </Text>
        <Box style={{ width: 200 }}>
          <Image
            src="/instruction.jpeg"
            alt="Instructions illustration"
            radius="md"
          />
        </Box>
      </Group>

        <Stack spacing="md">
          {/* Submit Agent */}


          {/* Code Guidelines */}
          <Group position="apart" align="center">
            <Group spacing="md">
              <Checkbox size="md" />
              <Text size="sm">Review code templates and submission guidelines</Text>
            </Group>
            <Button 
              variant="light"
              onClick={() => navigateToTab('getting-started')}
            >
              Getting Started
            </Button>
          </Group>

          {/* Teams */}
          <Group position="apart" align="center">
            <Group spacing="md">
              <Checkbox size="md" />
              <Text size="sm">Join or create a team to collaborate</Text>
            </Group>
            <Button 
              variant="light"
              onClick={() => navigateToTab('teams')}
            >
              Teams
            </Button>
          </Group>

          {/* Leaderboard */}
          <Group position="apart" align="center">
            <Group spacing="md">
              <Checkbox size="md" />
              <Text size="sm">Check the competition rankings</Text>
            </Group>
            <Button 
              variant="light"
              onClick={() => navigateToTab('leaderboard')}
            >
              Leaderboard
            </Button>
          </Group>

          {/* My Agents */}
          <Group position="apart" align="center">
            <Group spacing="md">
              <Checkbox size="md" />
              <Text size="sm">Monitor your agents and resource limits</Text>
            </Group>
            <Button 
              component={Link} 
              to="/my-agents" 
              variant="light"
            >
              My Agents <ExternalLink size={14} />
            </Button>
          </Group>

          {/* User Ranking */}
          <Group position="apart" align="center">
            <Group spacing="md">
              <Checkbox size="md" />
              <Text size="sm">Check your global ranking and medals</Text>
            </Group>
            <Button 
              component={Link} 
              to="/ranking" 
              variant="light"
            >
              User Ranking <ExternalLink size={14} />
            </Button>
          </Group>

          {/* Community */}
          <Group position="apart" align="center">
            <Group spacing="md">
              <Checkbox size="md" />
              <Text size="sm">Join the community discussions</Text>
            </Group>
            <Group spacing="xs">
              <Button 
                variant="light"
                onClick={() => navigateToTab('discussions')}
              >
                Discussions
              </Button>
              <Button 
                component={Link} 
                to="/general-discussions" 
                variant="light"
              >
                Forums <ExternalLink size={14} />
              </Button>
            </Group>
          </Group>
        </Stack>
        <Group position="apart" align="center">
              <Text size="sm">When you feel ready, Submit your agent to this competition</Text>

            <StyledButton
            size="md"
            variant="primary"
            onClick={handleJoinCompetition}
          >
            Submit Agent
          </StyledButton>
          </Group>
    </Stack>
  );
};

export default Instructions;