import React from 'react';
import { Container } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

const ResponsiveLayout = ({ children }) => {
    const isMobile = useMediaQuery('(max-width: 768px)');
  
    if (isMobile) {
      return (
        <div 
          className="overflow-x-auto"
          style={{
            minWidth: '1024px', // Minimum width to maintain laptop layout
            maxWidth: '1200px', // Maximum width for larger screens
            paddingLeft: '1rem',
            paddingRight: '1rem',
          }}
        >
          {children}
        </div>
      );
    }
  
    // On desktop, just render children directly to preserve existing layout
    return children;
  };
  
  export default ResponsiveLayout;