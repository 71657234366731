import React, { useState } from 'react';
import { Paper, Title, Stack, Group, Text, Loader, Modal, Divider, List, ThemeIcon } from '@mantine/core';
import { useRuntimeOptions } from 'hooks/DirectAgentAttach/useRuntimeOptions';
import { useAgentFileManagement } from 'hooks/DirectAgentAttach/useAgentFileManagement';
import getFileActions from 'components/DirectAgentAttach/Monitor/getFileActions';
import useColabIntegration from 'hooks/DirectAgentAttach/useColabIntegration';
import RuntimeView from 'components/DirectAgentAttach/RuntimeView';
import { Editor } from '@monaco-editor/react';
import { IconFileText } from '@tabler/icons-react';
import AgentVisibilityToggle from './AgentVisibilityToggle';
import Discussions from 'pages/Competition/Discussions';
import DocumentationManager from './DocumentationManager';

const Configuration = ({ 
  competitionId, 
  attacheAgentId,
  currentUser,
  isAdmin,
  isAuthenticated,
  agentOwnerId,
  initialVisibility
}) => {
  const [modalState, setModalState] = useState({
    isOpen: false,
    fileName: null,
    content: null,
    isLoading: false
  });

  const { 
    options: runtime, 
    isLoadingRuntime,
    runtimeError 
  } = useRuntimeOptions(competitionId, false, attacheAgentId);

  const colabIntegration = useColabIntegration(competitionId, attacheAgentId);

  const {
    fileState: { files, isLoading: isLoadingFiles, fileContents },
    handlers, 
    fetchFiles
  } = useAgentFileManagement(competitionId, attacheAgentId);

  const handleViewCode = async (fileName) => {
    setModalState({
      isOpen: true,
      fileName,
      content: null,
      isLoading: true
    });
  
    const content = await handlers.handleFetchContent(fileName);

      
    setModalState(prev => ({
      ...prev,
      content,
      isLoading: false
    }));
  };

  const enhancedHandlers = {
    ...handlers,
    isColabLoading: colabIntegration.isColabLoading,
    handleViewCode
  };

  if (isLoadingRuntime) {
    return (
      <Group position="center" p="xl">
        <Loader />
        <Text>Loading configuration...</Text>
      </Group>
    );
  }

  if (runtimeError) {
    return (
      <Text color="red" align="center" p="xl">
        {runtimeError}
      </Text>
    );
  }

  return (
    <Stack spacing="md">
      {/* Header with Visibility Toggle */}
      <Group position="apart" align="center">
        <Title order={2}>Agent Configuration</Title>
        <AgentVisibilityToggle
          agentId={attacheAgentId}
          currentUser={currentUser}
          agentOwnerId={agentOwnerId}
          initialVisibility={initialVisibility}
        />
      </Group>

      {/* Runtime Configuration */}
      <Paper shadow="xs" p="md">
        <Stack spacing="md">
          <Title order={3}>Runtime Configuration</Title>
          <RuntimeView 
            runtime={runtime}
            isLoading={isLoadingRuntime}
            error={runtimeError}
          />
        </Stack>
      </Paper>

      {/* Files List */}
      <Paper shadow="xs" p="md">
        <Stack spacing="md">
          <Title order={3}>Files</Title>
          <DocumentationManager
            competitionId={competitionId}
            attacheAgentId={attacheAgentId}
            files={files}
            onUpdate={fetchFiles}
            fileContents={fileContents}
            onFetchContent={handlers.handleFetchContent}
            onDownload={handlers.handleDownload}
            isOwner={currentUser?.user_id === agentOwnerId}
          />
          
          {/* Other Files */}
          <List spacing="xs">
            {Object.entries(files)
              .filter(([filename]) => !filename.endsWith('.md'))
              .map(([filename, fileData]) => (
                <List.Item
                  key={filename}
                  icon={
                    <ThemeIcon 
                      color={filename === 'agent.py' ? 'blue' : 
                        filename.endsWith('.ipynb') ? 'violet' : 'gray'} 
                      size={24} 
                      radius="xl"
                    >
                      <IconFileText size={16} />
                    </ThemeIcon>
                  }
                >
                  <Group position="apart" wrap="nowrap">
                    <Text size="sm">
                      {filename}
                      {filename === 'agent.py' && ' (Main File)'}
                      {filename === 'agent.ipynb' && ' (Notebook)'}
                    </Text>
                    {getFileActions(filename, enhancedHandlers, colabIntegration.handleColabOpen)}
                  </Group>
                </List.Item>
              ))}
          </List>
        </Stack>
      </Paper>

      {/* Code View Modal */}
      <Modal
        opened={modalState.isOpen}
        onClose={() => setModalState(prev => ({ ...prev, isOpen: false }))}
        title={modalState.fileName}
        size="xl"
      >
{modalState.isLoading ? (
  <Group position="center" p="xl">
    <Loader />
    <Text>Loading file content...</Text>
  </Group>
) : (
  <>
    <Editor
      height="500px"
      language={modalState.fileName?.endsWith('.py') ? 'python' : 'plaintext'}
      theme="vs-dark"
      value={modalState.content}
      options={{
        readOnly: true,
        minimap: { enabled: false },
        lineNumbers: 'on',
        automaticLayout: true,
      }}
    />
  </>
)}
      </Modal>

      <Divider my="xl" />
      <Discussions
        competitionId={competitionId}
        isAdmin={isAdmin}
        isAuthenticated={isAuthenticated}
        currentUser={currentUser}
        isFromConfiguration={true}
        agentAttachedId={attacheAgentId}
      />
    </Stack>
  );
};

export default Configuration;