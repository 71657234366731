import React from 'react';
import { Stack, Text, Group, Badge, ThemeIcon } from '@mantine/core';
import { Award, Users2, ScrollText } from 'lucide-react';

const SectionHeader = ({ icon: Icon, title }) => (
  <Group spacing="sm" mb="md">
    <ThemeIcon size={32} radius="md" color="blue.5">
      <Icon size={20} />
    </ThemeIcon>
    <Text size="lg" fw={700}>{title}</Text>
  </Group>
);

const StatItem = ({ label, value }) => (
    <Text size="sm" lh={1.5}>{label} : {value}</Text>
);

const CompetitionRightInfo = ({ 
  isAwardPoints,
  statistics = {
    unique_participants: 0,
    running_agents: 0,
    team_count: 0,
  },
  tags = []
}) => {
  return (
    <Stack spacing="xl" style={{ marginBottom: 'calc(var(--mantine-spacing-xl) * 2)' }}>
      {/* Prizes & Awards Section */}
      <div>
        <SectionHeader icon={Award} title="Prizes & Awards" />
        <Text size="sm" lh={1.5}>
          {isAwardPoints 
            ? "This competition awards points & medals to top performers"
            : "This competition does not award points"}
        </Text>
      </div>

      {/* Participation Statistics Section */}
      <div>
        <SectionHeader icon={Users2} title="Participation" />
        <Stack spacing={0}>
          <StatItem 
            label="Entrants" 
            value={statistics.unique_participants.toLocaleString()} 
          />
          <StatItem 
            label="Active Agents" 
            value={statistics.running_agents.toLocaleString()} 
          />
          <StatItem 
            label="Teams" 
            value={statistics.team_count.toLocaleString()} 
          />
        </Stack>
      </div>

      {/* Tags Section */}
      <div>
        <SectionHeader icon={ScrollText} title="Tags" />
        <Group spacing="xs">
          {tags.map((tag, index) => (
            <Badge 
              key={tag.id || index}
              size="lg"
              radius="sm"
              variant="light"
              color="blue.5"
            >
              {tag.name}
            </Badge>
          ))}
        </Group>
      </div>
    </Stack>
  );
};

export default CompetitionRightInfo;