import React from 'react';
import { Table, Text, Badge, Loader, Group, ActionIcon, Tooltip, Box, Avatar } from '@mantine/core';
import { IconUserCircle, IconTrophy, IconRobot, IconChartBar, IconRun, IconChessKnight, IconBook, IconEye } from '@tabler/icons-react';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import useLeaderboardData from "hooks/competition/useLeaderboardData";
import IconIntroduction from './IconIntroduction'

const ParticipantInfo = ({ username, teamName, teamMembers = [] }) => {
  if (!teamName) {
    // Single user display
    return (
      <Group gap="sm">
        <Avatar
          size={30}
          radius="xl"
          src={`https://api.dicebear.com/7.x/initials/svg?seed=${username}`}
        />
        <Text fw={500}>{username}</Text>
      </Group>
    );
  }
  // Team display with all members
  return (
    <Box>
      <Text fw={500} size="sm" mb={4}>{teamName}</Text>
      <Group gap="xs">
        {teamMembers.map((member, index) => (
          <Group key={member.id} gap="xs">
            <Avatar
              size={24}
              radius="xl"
              src={`https://api.dicebear.com/7.x/initials/svg?seed=${member.username}`}
            />
          </Group>
        ))}
      </Group>
    </Box>
  );
};

const LeaderboardRow = ({ entry, index, competitionId }) => {
  const navigate = useNavigate();

  const handleAgentClick = () => {
    if (entry.agentAttachId) {
      if (!entry.IsMyAgent && entry.IsPublic) {
        navigate(`/attach-agent/view/${competitionId}/${entry.agentAttachId}`, {
          state: { defaultTab: 'configuration' }
        });
      } else {
        navigate(`/attach-agent/view/${competitionId}/${entry.agentAttachId}`);
      }
    }
  };

  const handleDiscussionClick = (e) => {
    e.stopPropagation();
    navigate(`/viewcompetition/${competitionId}`, {
      state: { 
        defaultTab: 'discussions',
        presetAgentId: entry.agentAttachId 
      }
    });
  };

  return (
    <Table.Tr key={index} style={entry.IsMyAgent ? { backgroundColor: '#f0f8ff' } : {}}>
      <Table.Td>
        {entry.Rank <= 2 ? (
          <Tooltip label={`Rank ${entry.Rank}`}>
            <IconTrophy 
              size={24} 
              color={entry.Rank === 1 ? 'gold' : 'silver'} 
            />
          </Tooltip>
        ) : entry.Rank}
      </Table.Td>
      <Table.Td>
        <ParticipantInfo 
          username={entry.Username} 
          teamName={entry.TeamName}
          teamMembers={entry.TeamMembers}
        />
      </Table.Td>
      <Table.Td>
        <Group gap="xs">
          <Text>{entry.AgentName}</Text>
          <Group gap={4}>
            {entry.IsMyAgent && (
              <Tooltip label="View Agent Details">
                <ActionIcon 
                  color="blue" 
                  variant="subtle" 
                  onClick={handleAgentClick}
                >
                  <IconUserCircle size={16} />
                </ActionIcon>
              </Tooltip>
            )}
            {(!entry.IsMyAgent && entry.IsPublic) && (
              <Tooltip label="View Public Agent">
                <ActionIcon 
                  color="gray" 
                  variant="subtle" 
                  onClick={handleAgentClick}
                >
                  <IconEye size={16} />
                </ActionIcon>
              </Tooltip>
            )}
            {entry.IsDiscussed && (
              <Tooltip label="View Agent Discussions">
                <ActionIcon
                  color="violet"
                  variant="subtle"
                  onClick={handleDiscussionClick}
                >
                  <IconBook size={16} />
                </ActionIcon>
              </Tooltip>
            )}
          </Group>
        </Group>
      </Table.Td>
      <Table.Td>
        <Group gap="xs">
          <IconChartBar size={16} />
          <Text>{entry.MeanReward != null ? entry.MeanReward.toFixed(2) : 'N/A'}</Text>
        </Group>
      </Table.Td>
      {entry.IsEloRanked && (
        <Table.Td>
          <Group gap="xs">
            <IconChessKnight size={16} />
            <Text>{entry.EloScore != null ? entry.EloScore.toFixed(0) : 'N/A'}</Text>
          </Group>
        </Table.Td>
      )}
      <Table.Td>
        <Group gap="xs">
          <IconRun size={16} />
          <Text>{entry.NumberOfRuns}</Text>
        </Group>
      </Table.Td>
      <Table.Td>{format(new Date(entry.SubscriptionDate), 'yyyy-MM-dd HH:mm')}</Table.Td>
      <Table.Td>
        {entry.LastRun
          ? format(new Date(entry.LastRun), 'yyyy-MM-dd HH:mm')
          : 'N/A'}
      </Table.Td>
    </Table.Tr>
  );
};

const Leaderboard = ({ competitionId }) => {
  const { leaderboardData, loading, error } = useLeaderboardData(competitionId);

  if (loading) return <Loader size="xl" />;
  if (error) return <Text color="red">Error loading leaderboard: {error}</Text>;

  const isEloRanked = leaderboardData[0]?.IsEloRanked;

  return (
    <Box>

      <Group justify="space-between" mb="md">
        <Group>
          <IconRobot size={24} />
          <Text fw={500} size="lg">
            Agent Leaderboard
            {isEloRanked && (
              <Text component="span" size="sm" ml="xs" c="dimmed">
                (Ranked by ELO Score)
              </Text>
            )}
          </Text>
        </Group>
        <Badge color="blue" variant="light">
          Updated: {format(new Date(), 'yyyy-MM-dd HH:mm')}
        </Badge>
      </Group>

      <Table>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Rank</Table.Th>
            <Table.Th>Participant</Table.Th>
            <Table.Th>Agent Name</Table.Th>
            <Table.Th>Mean Reward</Table.Th>
            {isEloRanked && <Table.Th>ELO Score</Table.Th>}
            <Table.Th>Number of Runs</Table.Th>
            <Table.Th>Subscription Date</Table.Th>
            <Table.Th>Last Run</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {leaderboardData.map((entry, index) => (
            <LeaderboardRow 
              entry={entry} 
              index={index} 
              key={index} 
              competitionId={competitionId}
            />
          ))}
        </Table.Tbody>
      </Table>
      <IconIntroduction />
    </Box>
  );
};

export default Leaderboard;