import { Badge } from '@mantine/core';

const GameOutcomeBadge = ({ outcome }) => {
  const getColor = () => {
    switch (outcome?.toLowerCase()) {
      case 'winner': return 'green';
      case 'loser': return 'red';
      case 'draw': return 'yellow';
      default: return 'gray';
    }
  };

  return (
    <Badge color={getColor()}>
      {outcome ? outcome.charAt(0).toUpperCase() + outcome.slice(1) : 'Unknown'}
    </Badge>
  );
};

export default GameOutcomeBadge;