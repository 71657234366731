import React from 'react';
import { Stack, Group, Text, Alert } from '@mantine/core';
import FileManagement from 'components/DirectAgentAttach/Upload/FileManagement';
import RuntimeSelector from 'components/DirectAgentAttach/RuntimeSelector';
import AgentInstructions from 'components/DirectAgentAttach/Upload/AgentInstructions';
import { useRuntimeOptions } from 'hooks/DirectAgentAttach/useRuntimeOptions';
import { useAgentFileManagement } from 'hooks/DirectAgentAttach/useAgentFileManagement';
import { Check, X } from 'lucide-react';
import { agentAttachedStyles as styles } from 'theme/agentAttachedStyles';

const MessageAlert = ({ status, message }) => {
  const getMessageConfig = (status) => {
    switch (status) {
      case 'upload_validated':
        return { type: 'success', color: 'green' };
      case 'upload_failed':
      case 'deploy_failed':
        return { type: 'error', color: 'red' };
      case 'uploading':
      case 'deploy_queue':
        return { type: 'info', color: 'blue' };
      default:
        return null;
    }
  };
 
  const config = getMessageConfig(status);
  if (!config || !message) return null;
 
  return (
    <Alert
      icon={config.type === 'error' ? <X size={16} /> : <Check size={16} />}
      color={config.color}
      variant="light"
      title={status.replace('_', ' ').toUpperCase()}
      style={styles.alertContainer}
    >
      {message}
    </Alert>
  );
};

const AgentUpload = ({ 
  competitionId, 
  attacheAgentId, 
  status, 
  statusMessage, 
  onStatusChange, 
  handleTemporaryStatus, 
  competitionInfo 
}) => {
  const { fileState, handlers } = useAgentFileManagement(
    competitionId, 
    attacheAgentId,
    onStatusChange,
    handleTemporaryStatus
  );
  
  const {
    options: runtimeOptions,
    selectedRuntime,
    setSelectedRuntime,
    isLoading: isLoadingRuntime
  } = useRuntimeOptions(competitionId, true, attacheAgentId);
 
  const UPLOAD_PHASE_STATUSES = ['created', 'uploading', 'upload_failed', 'upload_validated', 'deploy_failed'];
  const isInUploadPhase = UPLOAD_PHASE_STATUSES.includes(status);

  return (
    <Stack spacing="xl">
      <div style={styles.instructionsContainer}>
        <AgentInstructions competitionInfo={competitionInfo}/>
      </div>
      
      <div style={styles.runtimeSection}>
        <Stack spacing="md">
          <Group position="apart">
            <Text style={styles.sectionTitle}>Agent Runtime Configuration</Text>
          </Group>
          
          <RuntimeSelector
            isLoading={isLoadingRuntime}
            runtimeOptions={runtimeOptions}
            selectedRuntime={selectedRuntime}
            setSelectedImage={setSelectedRuntime}
            isReadOnly={!isInUploadPhase}
          />
        </Stack>
      </div>
 
      {fileState.error && (
        <Alert 
          color="red" 
          title="Error" 
          style={styles.alertContainer}
        >
          {fileState.error}
        </Alert>
      )}

      <MessageAlert 
        status={status}
        message={statusMessage}
      />

      <div style={styles.fileManagementSection}>
        <FileManagement
          files={fileState.files}
          loading={fileState.isLoading || fileState.isUploading}
          disabled={!isInUploadPhase}
          onDelete={handlers.handleFileDelete}
          onEdit={handlers.handleEditFile}
          onUpload={handlers.handleUpload}
        />
      </div>
    </Stack>
  );
};

export default AgentUpload;