import React, { useState } from 'react';
import { Stack, Card, Group, Button, Text, NumberInput, Progress, Alert, Badge } from '@mantine/core';
import { Rocket, AlertTriangle, RefreshCcw, Clock, Activity, List } from 'lucide-react';
import { useAgentDeploy } from 'hooks/DirectAgentAttach/useAgentDeploy';
import { Check, X } from 'lucide-react';
import DeployAllocationControl from 'components/DirectAgentAttach/Deploy/DeployAllocationControl'
import { RunInfo, QueueInfo } from 'components/DirectAgentAttach/Deploy/DisplayInfo';


const MessageAlert = ({ status, message }) => {
  const getMessageConfig = (status) => {
    switch (status) {
      case 'upload_validated':
        return { type: 'success', color: 'green' };
      case 'upload_failed':
      case 'deploy_failed':
        return { type: 'error', color: 'red' };
      case 'uploading':
      case 'deploy_queue':
        return { type: 'info', color: 'blue' };
      case 'deploying':
        return { type: 'info', color: 'blue' };
      case 'deploy_run':
        return { type: 'info', color: 'blue' };
      default:
        return null;
    }
  };
 
  const config = getMessageConfig(status);
  if (!config || !message) return null;
 
  return (
    <Alert
      icon={config.type === 'error' ? <X size={16} /> : <Check size={16} />}
      color={config.color}
      variant="light"
      title={status.replace('_', ' ').toUpperCase()}
    >
      {message}
    </Alert>
  );
};

const AgentDeploy = ({ competitionId, attacheAgentId, status, statusMessage, queueInfo, runInfo, onStatusChange }) => {
  const {
    deploymentLimits,
    isLoading,
    error,
    deployAgent,
    currentAllocation,
    availableAllocation,
    dailyRunAllocation,
    setDailyRunAllocation,
  } = useAgentDeploy(competitionId, attacheAgentId, onStatusChange);

  const remainingDeploys = deploymentLimits.weekly_deploys_remaining;
  const isDeploying = ['deploying', 'deploy_queue', 'deploy_run'].includes(status);
  const deployLimitReached = remainingDeploys <= 0;

  const handleDeploy = async () => {
    try {
      await deployAgent();
    } catch (err) {
      // Error handling is managed by the hook
    }
  };

  return (
    <Stack spacing="xl">
      {/* Deployment Status Card */}
        <Stack spacing="md">
          
          <MessageAlert 
            status={status}
            message={statusMessage}
          />
          
          {error && (
            <Alert color="red" icon={<AlertTriangle size={16} />}>
              {error}
            </Alert>
          )}

          { (
            <Alert icon={<RefreshCcw size={16} />} color="blue">
              Deployment process includes:
              <ul>
                <li>2 dry-run tests to validate performance</li>
                <li>10 competitive runs for initial ranking</li>
                <li>You have {remainingDeploys} deployment(s) remaining this week</li>
              </ul>
            </Alert>
          )}

          <DeployAllocationControl
            value={dailyRunAllocation}
            onChange={setDailyRunAllocation}
            currentAllocation={currentAllocation}
            availableAllocation={availableAllocation}
            isDisabled={isDeploying || deployLimitReached}
          />

          <Button
            leftSection={<Rocket size={16} />}
            onClick={handleDeploy}
            loading={isLoading}
            disabled={deployLimitReached || isDeploying}
            fullWidth
          >
            Deploy Agent
          </Button>
        </Stack>

      {status === 'deploy_queue' && <QueueInfo queueInfo={queueInfo} />}
      {status === 'deploy_run' && <RunInfo runInfo={runInfo} />}
    </Stack>
  );
};

export default AgentDeploy;