import React from 'react';
import { Box, Container, Grid, Group, Text, Stack } from '@mantine/core';
import { Link } from 'react-router-dom';
import { Twitter, Linkedin, Github } from 'lucide-react';
import { footerStyles } from '../theme/footerStyles';

const Footer = () => {
  return (
    <Box style={footerStyles.wrapper}>
      <Container size="xl">
        <Grid>
          {/* Logo and Social Links Column */}
          <Grid.Col span={{ base: 12, md: 3 }}>
            <Stack gap="lg">
              <Text component={Link} to="/" style={footerStyles.logoText}>
                ML-Arena
              </Text>
              <Group>
                <Twitter
                  size={24}
                  style={footerStyles.icon}
                  onClick={() => window.open('https://bsky.app/profile/ml-arena.bsky.social')}
                />
                <Linkedin
                  size={24}
                  style={footerStyles.icon}
                  onClick={() => window.open('https://linkedin.com/company/ml-arena')}
                />
                <Github
                  size={24}
                  style={footerStyles.icon}
                  onClick={() => window.open('https://github.com/ml-arena')}
                />
              </Group>
            </Stack>
          </Grid.Col>

          {/* Product Column */}
          <Grid.Col span={{ base: 6, md: 3 }}>
            <Stack gap="md">
              <Text style={footerStyles.title}>Product</Text>
              <Stack gap="xs">
                <Text component={Link} to="/listcompetition" style={footerStyles.footerLink}>
                  Competitions
                </Text>
              </Stack>
            </Stack>
          </Grid.Col>

          {/* Documentation Column */}
          <Grid.Col span={{ base: 6, md: 3 }}>
            <Stack gap="md">
              <Text style={footerStyles.title}>Documentation</Text>
              <Stack gap="xs">
                <Text component={Link} to="/docs/competitions" style={footerStyles.footerLink}>
                  Competitions
                </Text>
                <Text component={Link} to="/discussions" style={footerStyles.footerLink}>
                  Discussions
                </Text>
              </Stack>
            </Stack>
          </Grid.Col>

          {/* Company Column */}
          <Grid.Col span={{ base: 6, md: 3 }}>
            <Stack gap="md">
              <Text style={footerStyles.title}>Company</Text>
              <Stack gap="xs">
                <Text component={Link} to="/company" style={footerStyles.footerLink}>
                  Our Team
                </Text>
                <Text component={Link} to="/company" style={footerStyles.footerLink}>
                  Host a Competition
                </Text>
                <Group style={footerStyles.linkWrapper}>
                  <Text component={Link} to="/company" style={footerStyles.footerLink}>Terms</Text>
                  <Text>·</Text>
                  <Text component={Link} to="/company" style={footerStyles.footerLink}>Privacy Policy</Text>
                </Group>
              </Stack>
            </Stack>
          </Grid.Col>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;