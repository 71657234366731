import React from 'react';
import { Stack, Title, Box, Grid, Text } from '@mantine/core';
import LiveStream from 'components/Competition/LiveStream';
import CompetitionConstraints from 'components/Competition/CompetitionConstraints';
import MarkdownEditor from 'components/Competition/MarkdownEditor';
import TableOfContents from 'components/Competition/TableOfContents';
import CompetitionProgress from 'components/Competition/CompetitionProgress';
import Instructions from 'components/Competition/Instructions';
import EvaluationSection from 'components/Competition/EvaluationSection';
import CompetitionRightInfo from 'components/Competition/CompetitionRightInfo';
import { competitionOverviewStyles as styles } from "theme/competitionOverviewStyles";



const Overview = ({
  competitionId,
  startTime,
  endTime,
  description,
  renderDelay,
  agentMemoryLimit,
  agentCpuLimit,
  agentMaxTimePerStepSecond,
  competitionInfo,
  environmentInfo,
  handleJoinCompetition
}) => {
  if (!competitionInfo || !environmentInfo) {
    return (
      <Grid>
        <Grid.Col span={9}>
          <Stack spacing={0}>
            <Title order={2}>Loading competition information...</Title>
          </Stack>
        </Grid.Col>
        <Grid.Col span={3}>
          <TableOfContents sections={[]} />
        </Grid.Col>
      </Grid>
    );
  }

  const statistics = {
    unique_participants: competitionInfo.statistics?.unique_participants || 0,
    running_agents: competitionInfo.statistics?.running_agents || 0,
    team_count: competitionInfo.statistics?.team_count || 0,
  };

  const sections = [
    { id: 'overview', label: 'Overview' },
    { id: 'constraints', label: 'Competition Constraints' },
    { id: 'timeline', label: 'Timeline' },
    { id: 'instructions', label: 'Instructions' },
    { id: 'evaluation', label: 'Evaluation Rules' },
    { id: 'best-runs', label: 'Best Agent Runs' },
  ];


  return (
    <Grid>
      <Grid.Col span={9}>
        <Stack spacing={0} style={styles.root}>
          <section id="overview" style={styles.section}>
            <Text style={styles.sectionTitle}>Overview</Text>
            <Text size="lg" mt="md" mb="xl">
              {competitionInfo.description}
            </Text>
            <Box mt={40} mb={40}>
              <CompetitionProgress startTime={startTime} endTime={endTime} />
            </Box>
          </section>

          <section id="description" style={styles.section}>
            <Text style={styles.sectionTitle}>Description</Text>
            <MarkdownEditor
              competitionId={competitionId}
              isViewOnly={true}
              content={description}
            />
          </section>
          <section id="constraints" style={styles.section}>
            <Text style={styles.sectionTitle}>Competition Constraints</Text>
            <CompetitionConstraints
              memoryLimit={agentMemoryLimit}
              cpuLimit={agentCpuLimit}
              maxTimePerStep={agentMaxTimePerStepSecond}
            />
          </section>
          <section id="timeline" style={styles.section}>
            <Text style={styles.sectionTitle}>Timeline</Text>
            <Box mt="md">
              <Text>Start date: {new Date(startTime).toLocaleDateString()}</Text>
              <Text>End date: {new Date(endTime).toLocaleDateString()}</Text>
            </Box>
          </section>



          <section id="instructions" style={styles.section}>
  <Text style={styles.sectionTitle}>Instructions before starting</Text>
  <Instructions 
    competitionId={competitionId}
    handleJoinCompetition={handleJoinCompetition}
  />
</section>

          <EvaluationSection competitionInfo={competitionInfo}/>

          <section id="best-runs" style={{ ...styles.section, ...styles.lastSection }}>
            <Text style={styles.sectionTitle}>Best Agent Recent Runs</Text>
            <Box style={styles.bestRunsContainer}>
              <Text mb="xl">
                In this section, watch videos of the best agent runs in the game. Select a match from the 
                Match History below to see the code in action!
              </Text>
              <LiveStream renderDelay={renderDelay} />
            </Box>
          </section>
        </Stack>
      </Grid.Col>

      <Grid.Col span={3}>
        <Stack spacing="xl">
          <CompetitionRightInfo
            isAwardPoints={competitionInfo.is_award_points}
            statistics={statistics}
            tags={competitionInfo.tags || []}
          />
        </Stack>
        <TableOfContents 
          sections={sections}
        />
      </Grid.Col>
    </Grid>
  );
};

export default Overview;