export const competitionOverviewStyles = {
    root: {
      padding: 0,
      paddingBottom: 'calc(var(--mantine-spacing-xl) * 4)'
    },
  
    section: {
      marginBottom: 'var(--mantine-spacing-xl)'
    },
  
    sectionTitle: {
      color: 'var(--mantine-color-blue-11)',
      fontSize: '2.2rem',
      fontWeight: 700,
      marginBottom: 'var(--mantine-spacing-lg)'
    },
  
    instructionsWrapper: {
      width: '100%',
      display: 'flex',
      gap: 'var(--mantine-spacing-xl)',
      alignItems: 'flex-start'
    },
  
    instructions: {
      flex: 1,
      display: 'flex',
      gap: 'var(--mantine-spacing-md)',
      alignItems: 'flex-start',
      padding: 'var(--mantine-spacing-md)',
      transition: 'transform 150ms ease',
      '&:hover': {
        transform: 'translateY(-2px)'
      }
    },
  
    instructionNumber: {
      width: 28,
      height: 28,
      borderRadius: '50%',
      backgroundColor: 'var(--mantine-color-blue-6)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexShrink: 0
    },
  
    instructionContent: {
      flex: 1
    },
  
    instructionImage: {
      width: '300px',
      marginLeft: 'var(--mantine-spacing-xl)'
    },
  
    evaluationRule: {
      padding: 'var(--mantine-spacing-lg)',
      transition: 'transform 150ms ease',
      '&:hover': {
        transform: 'translateY(-2px)'
      }
    },
  
    bestRunsContainer: {
      padding: 'var(--mantine-spacing-xl)',
      marginBottom: 'calc(var(--mantine-spacing-xl) * 2)'
    },
  
    lastSection: {
      marginBottom: 'calc(var(--mantine-spacing-xl) * 15)'
    }
  };