// src/theme/footerStyles.js
export const footerStyles = {
  wrapper: {
    backgroundColor: 'var(--mantine-color-blue-11)',
    color: 'var(--mantine-color-white)',
    paddingTop: '3rem',
    paddingBottom: '3rem'
  },

  logoText: {
    fontSize: '2.5rem',
    fontWeight: 700,
    color: 'var(--mantine-color-white)',
    textDecoration: 'none',
    transition: 'color 200ms ease',
    '&:hover': {
      color: 'var(--mantine-color-gray-3)'
    }
  },

  icon: {
    color: 'var(--mantine-color-white)',
    cursor: 'pointer',
    transition: 'color 200ms ease',
    '&:hover': {
      color: 'var(--mantine-color-gray-3)'
    }
  },

  title: {
    fontWeight: 600,
    marginBottom: '0.5rem',
    color: 'var(--mantine-color-white)'
  },

  footerLink: {
    color: 'var(--mantine-color-white)',
    textDecoration: 'none',
    transition: 'color 200ms ease',
    '&:hover': {
      color: 'var(--mantine-color-gray-3)'
    }
  },

  linkWrapper: {
    display: 'flex',
    gap: '0.5rem',
    alignItems: 'center'
  }
};