import React from 'react';
import { Paper, Title, Stack, Alert, Group, Text, Button, Badge } from '@mantine/core';
import { Info } from 'lucide-react';

const ReceivedInvitations = ({ invitations, onRespond }) => {
  return (
    <>
      <Title order={3} mb="md">Received Invitations</Title>
      
      <Stack spacing="sm">
        {invitations?.length > 0 ? (
          invitations.map((invitation) => (
            <Alert 
              key={invitation.id} 
              radius="md"
              title={<Text size="sm" weight={500}>Team Invitation</Text>}
            >
              <Stack spacing="xs">
                <Group position="apart">
                  <div>
                    <Text>
                      You've been invited to join <strong>{invitation.team_name}</strong>
                    </Text>
                    <Text size="sm" color="dimmed">
                      Sent by {invitation.sender_name} • {new Date(invitation.created_at).toLocaleDateString()}
                    </Text>
                  </div>
                  <Badge color="yellow">Pending</Badge>
                </Group>
                
                <Group position="right" spacing="xs">
                  <Button
                    size="xs"
                    variant="light"
                    color="red"
                    onClick={() => onRespond(invitation.id, false)}
                  >
                    Decline
                  </Button>
                  <Button
                    size="xs"
                    variant="filled"
                    color="green"
                    onClick={() => onRespond(invitation.id, true)}
                  >
                    Accept
                  </Button>
                </Group>
              </Stack>
            </Alert>
          ))
        ) : (
          <Alert 
            color="gray" 
            variant="light"
            icon={<Info size={16} />}
          >
            <Text>You haven't received any invitations from other teams.</Text>
          </Alert>
        )}
      </Stack>
      </>
  );
};

export default ReceivedInvitations;