// src/theme/landingShellStyles.js
export const landingShellStyles = {
  header: {
    height: '5rem',         // 80px -> 5rem
    borderBottom: '1px solid var(--mantine-color-gray-2)',
    backgroundColor: 'var(--mantine-color-white)',
    padding: '0 1.5rem'     // 24px -> 1.5rem
  },

  logo: {
    color: 'var(--mantine-color-blue-11)',
    fontSize: '2.5rem',  // 24px -> 1.5rem
    fontWeight: 700,
    textDecoration: 'none',
    display: 'block',
    fontFamily: 'var(--mantine-font-family)'
  },

  navItem: (theme) => ({
    root: {
      display: 'flex',
      alignItems: 'center', // Add this to center items vertically
      height: '2.5rem',    // Add fixed height for consistency
      padding: '0 1rem',   
      borderRadius: theme.radius.md,
      fontWeight: 500,
      color: theme.colors.navy[2],
      backgroundColor: 'transparent',
      transition: 'background-color 200ms ease, color 200ms ease',
      
      '&.active': {
        backgroundColor: theme.colors.purple[0],
        color: theme.colors.purple[6],
        '& svg': {
          color: theme.colors.purple[6]
        }
      },
      '&:hover:not(.active)': {
        backgroundColor: theme.colors.purple[1]
      }
    },
    label: {
      fontSize: '1.5rem',
      fontWeight: 500,
      lineHeight: 1, // Add this to ensure text is centered
    },
    leftSection: {
      marginRight: '0rem',
      color: theme.colors.navy[2],
      display: 'flex',     // Add this
      alignItems: 'center' // Add this
    }
  }),
  
  // Add a new style for the nav group container
  navGroup: {
    height: '100%',
    alignItems: 'center', // This ensures all items are vertically centered
    gap: '0.5rem'        // Consistent spacing between items
  },

  loginButton: {
    root: {
      height: '2.5rem',         // 40px -> 2.5rem
      padding: '0 1.5rem',      // 0 24px -> 0 1.5rem
      borderRadius: '6.25rem',   // 100px -> 6.25rem
      backgroundColor: 'var(--mantine-color-purple-6)',
      fontWeight: 500,
      '&:hover': {
        backgroundColor: 'var(--mantine-color-purple-7)'
      }
    }
  }
};