import React, { useEffect } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { Box, Alert, Container } from '@mantine/core';
import { AlertCircle } from 'lucide-react';
import { useAgentAttachment } from 'hooks/DirectAgentAttach/useAgentAttachment';
import ViewAttachAgentHeader from 'components/DirectAgentAttach/ViewAttachAgentHeader';
import AgentMonitor from 'components/DirectAgentAttach/Monitor/AgentMonitor';
import { useAuth } from 'context/AuthContext';

const AgentAttachedView = () => {
  const { competitionId, attacheAgentId } = useParams();
  const {
    attachment,
    isFetching,
    error,
    fetchAttachment,
    handleDeleteAttach,
    updateDailyAllocation,
    competitionInfo
  } = useAgentAttachment(competitionId, attacheAgentId);
  
  const { currentUser, isAdmin, isAuthenticated, refreshAuthState } = useAuth();

  useEffect(() => {
    refreshAuthState();
  }, [refreshAuthState]);

  useEffect(() => {
    if (attachment?.status && !['active', 'paused'].includes(attachment.status)) {
      return <Navigate to={`/attach-agent/edit/${competitionId}/${attacheAgentId}`} />;
    }
  }, [attachment?.status, competitionId, attacheAgentId]);

  if (isFetching || !attachment) {
    return (
      <Container size="lg">
        <Box className="p-4">
          <Alert icon={<AlertCircle size={16} />} color="blue">
            Loading agent information...
          </Alert>
        </Box>
      </Container>
    );
  }

  return (
    <Box>
      <ViewAttachAgentHeader
        competitionId={competitionId}
        agentId={attacheAgentId}
        onDeleteAgent={handleDeleteAttach}
        onUpdateAllocation={updateDailyAllocation}
        isOwner={currentUser?.user_id === attachment.user_id}
        currentUser={currentUser}
        attachment={attachment}
        competitionInfo={competitionInfo}
      />
      
      {error ? (
        <Container size="lg">
          <Box className="p-4">
            <Alert icon={<AlertCircle size={16} />} color="red" title="Error">
              {error}
            </Alert>
          </Box>
        </Container>
      ) : (
        <AgentMonitor 
          competitionId={competitionId}
          attacheAgentId={attacheAgentId}
          status={attachment.status}
          statusMessage={attachment.last_status_message}
          onStatusChange={fetchAttachment}
          currentUser={currentUser}
          isAdmin={isAdmin}
          isAuthenticated={isAuthenticated}
          agentOwnerId={attachment.user_id}
          initialVisibility={attachment.is_public}
        />
      )}
    </Box>
  );
};

export default AgentAttachedView;