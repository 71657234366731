import React, { useState, useEffect } from 'react';
import { 
  Card,
  Text,
  Group,
  Stack,
  Avatar,
  TextInput,
  Table,
  Badge,
  Pagination,
  Skeleton,
  Tooltip,
  Container,
  Title,
  Paper,
  Image
} from '@mantine/core';
import { useAuth } from 'context/AuthContext';
import { Medal, Search, Crown, Trophy, Award } from 'lucide-react';
import axios from 'axios';

const UserStats = ({ userId }) => {
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_PREFIX_BACKEND}/api/ranking/user/${userId}`);
        setStats(response.data.stats);
      } catch (error) {
        console.error('Failed to fetch user stats:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchStats();
  }, [userId]);

  if (loading) {
    return <Skeleton height={100} />;
  }

  if (!stats) {
    return null;
  }

  return (
    <Card withBorder>
      <Group justify="space-between">
        <Stack gap="xs">
          <Text size="sm" c="dimmed">Your Global Ranking</Text>
          <Group>
            <Text size="xl" fw={700}>#{stats.rank}</Text>
            <Text size="sm" c="dimmed">
              Top {stats.percentile.toFixed(1)}%
            </Text>
          </Group>
        </Stack>
        <Stack gap="xs" align="flex-end">
          <Text size="sm" c="dimmed">Total Points</Text>
          <Text size="xl" fw={700}>{stats.total_points.toLocaleString()}</Text>
        </Stack>
      </Group>

      <Group mt="md">
        {stats.medals.gold > 0 && (
          <Badge leftSection={<Trophy size={14} />} color="yellow">
            {stats.medals.gold} Gold
          </Badge>
        )}
        {stats.medals.silver > 0 && (
          <Badge leftSection={<Medal size={14} />} color="gray">
            {stats.medals.silver} Silver
          </Badge>
        )}
        {stats.medals.bronze > 0 && (
          <Badge leftSection={<Award size={14} />} color="brown">
            {stats.medals.bronze} Bronze
          </Badge>
        )}
      </Group>
    </Card>
  );
};

const UserRankings = () => {
  const { user } = useAuth();
  const [rankings, setRankings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [metadata, setMetadata] = useState({
    total_pages: 1,
    current_page: 1,
    total_users: 0
  });

  const fetchRankings = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_PREFIX_BACKEND}/api/ranking/`, {
        params: {
          page,
          search: search.trim(),
          per_page: 20
        }
      });
      setRankings(response.data.rankings);
      setMetadata(response.data.metadata);
    } catch (error) {
      console.error('Failed to fetch rankings:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRankings();
  }, [page, search]);

  const handleSearch = (e) => {
    setSearch(e.target.value);
    setPage(1);
  };
  return (
    <Container size="xl" py="xl">
      <Stack spacing="xl">
        {/* Hero Section */}
        <Paper radius="md" p="xl">
          <Group align="flex-start" justify="space-between">
            <Stack spacing="lg" style={{ width: '50%' }}>
              <div>
                <Title order={1}>User Rankings</Title>
                <Text size="lg" mt="sm" color="dimmed">
                  Track your progress and compete with other participants in our global leaderboard. 
                  Earn medals and points by participating in competitions and deploying successful agents. 
                  Rise through the ranks to become a top performer in our AI community.
                </Text>
              </div>
            </Stack>
  
            <Image 
              src="/Trophy.jpeg"
              alt="Trophy"
              radius="md"
              style={{ width: '30%', height: 'auto' }}
            />
          </Group>
        </Paper>

        {/* User Stats */}
        {user && (
          <Stack spacing="md">
            <Title order={2}>Your Performance</Title>
            <UserStats userId={user.id} />
          </Stack>
        )}

        {/* Rankings Table */}
          <Stack gap="md">
            <Group justify="space-between">
              <TextInput
                placeholder="Search users..."
                value={search}
                onChange={handleSearch}
                leftSection={<Search size={16} />}
                style={{ width: 250 }}
              />
            </Group>

            {loading ? (
              <Skeleton height={400} />
            ) : (
              <Table striped highlightOnHover>
                <Table.Thead>
                  <Table.Tr>
                    <Table.Th>Rank</Table.Th>
                    <Table.Th>User</Table.Th>
                    <Table.Th>Points</Table.Th>
                    <Table.Th>Medals</Table.Th>
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                  {rankings.map((rank) => (
                    <Table.Tr 
                      key={rank.user_id}
                      style={user?.id === rank.user_id ? { backgroundColor: 'var(--mantine-color-blue-0)' } : undefined}
                    >
                      <Table.Td>
                        <Group gap="xs">
                          {rank.rank <= 3 && (
                            <Crown 
                              size={16} 
                              color={
                                rank.rank === 1 ? '#FFD700' :
                                rank.rank === 2 ? '#C0C0C0' :
                                '#CD7F32'
                              }
                            />
                          )}
                          <Text fw={500}>#{rank.rank}</Text>
                        </Group>
                      </Table.Td>
                      <Table.Td>
                        <Group gap="sm">
                          <Avatar
                            size={30}
                            radius="xl"
                            src={`https://api.dicebear.com/7.x/initials/svg?seed=${rank.username}`}
                          />
                          <Text fw={500}>{rank.username}</Text>
                        </Group>
                      </Table.Td>
                      <Table.Td>
                        <Text fw={500}>{rank.points.toLocaleString()}</Text>
                      </Table.Td>
                      <Table.Td>
                        <Group gap="xs">
                          {rank.medals.gold > 0 && (
                            <Tooltip label={`${rank.medals.gold} Gold`}>
                              <Badge color="yellow" size="sm">
                                {rank.medals.gold}
                              </Badge>
                            </Tooltip>
                          )}
                          {rank.medals.silver > 0 && (
                            <Tooltip label={`${rank.medals.silver} Silver`}>
                              <Badge color="gray" size="sm">
                                {rank.medals.silver}
                              </Badge>
                            </Tooltip>
                          )}
                          {rank.medals.bronze > 0 && (
                            <Tooltip label={`${rank.medals.bronze} Bronze`}>
                              <Badge color="brown" size="sm">
                                {rank.medals.bronze}
                              </Badge>
                            </Tooltip>
                          )}
                        </Group>
                      </Table.Td>
                    </Table.Tr>
                  ))}
                </Table.Tbody>
              </Table>
            )}

            {metadata.total_pages > 1 && (
              <Group justify="center">
                <Pagination
                  value={page}
                  onChange={setPage}
                  total={metadata.total_pages}
                  withEdges
                />
              </Group>
            )}
          </Stack>
      </Stack>
    </Container>
  );
};

export default UserRankings;