import React, { useEffect } from 'react';
import { Card, Text, Group, Button, NumberInput, Stack, Tooltip } from '@mantine/core';
import { IconChevronUp, IconChevronDown, IconFlag, IconPlayerPlay, IconLock } from '@tabler/icons-react';
import useAgentAllocation from 'hooks/DirectAgentAttach/useAgentAllocation';

const AllocationControl = ({ 
  attacheAgentId, 
  isStarted, 
  handleStart, 
  isStartBlocked,
  isOwner = false
}) => {
  const {
    allocation: dailyRunAllocation,
    availableAllocation,
    totalLimit: dailyAgentRunLimit,
    updateAllocation,
    isUpdating,
    fetchAllocation
  } = useAgentAllocation(attacheAgentId);

  useEffect(() => {
    fetchAllocation();
  }, [attacheAgentId]);

  const [newDailyRunAllocation, setNewDailyRunAllocation] = React.useState(dailyRunAllocation || 0);

  useEffect(() => {
    if (dailyRunAllocation !== null) {
      setNewDailyRunAllocation(dailyRunAllocation);
    }
  }, [dailyRunAllocation]);

  const isAllocationChanged = newDailyRunAllocation !== dailyRunAllocation;

  const handleAllocationUpdate = async () => {
    if (isAllocationChanged && isOwner) {
      const success = await updateAllocation(newDailyRunAllocation);
      if (success) {
        handleStart?.();
      }
    }
  };

  const getActionButton = () => {
    if (!isStarted && newDailyRunAllocation > 0) {
      return (
        <Button
          color="green"
          onClick={handleStart}
          disabled={isStartBlocked || isUpdating}
          fullWidth
          leftSection={<IconFlag size={14} />}
        >
          Start Competition
        </Button>
      );
    } else if (isStarted && isAllocationChanged && isOwner) {
      return (
        <Button
          color="blue"
          onClick={handleAllocationUpdate}
          disabled={!isAllocationChanged || isUpdating}
          fullWidth
          leftSection={<IconPlayerPlay size={14} />}
          loading={isUpdating}
        >
          Update Allocation
        </Button>
      );
    }
    return null;
  };

  const AllocationInput = () => {
    const input = (
      <NumberInput
        value={newDailyRunAllocation}
        onChange={(value) => isOwner && setNewDailyRunAllocation(value)}
        min={0}
        max={dailyAgentRunLimit}
        step={1}
        styles={{ input: { width: 60, textAlign: 'center' } }}
        rightSection={
          isOwner ? (
            <Stack spacing={0}>
              <Button
                variant="subtle"
                size="xs"
                onClick={() => setNewDailyRunAllocation(Math.min(
                  newDailyRunAllocation + 1, 
                  dailyRunAllocation + availableAllocation
                ))}
                disabled={newDailyRunAllocation >= dailyRunAllocation + availableAllocation}
              >
                <IconChevronUp size={14} />
              </Button>
              <Button
                variant="subtle"
                size="xs"
                onClick={() => setNewDailyRunAllocation(Math.max(newDailyRunAllocation - 1, 1))}
                disabled={newDailyRunAllocation <= 1}
              >
                <IconChevronDown size={14} />
              </Button>
            </Stack>
          ) : (
            <IconLock size={14} className="text-gray-400" />
          )
        }
        rightSectionWidth={30}
        disabled={isUpdating || !isOwner}
      />
    );

    return isOwner ? input : (
      <Tooltip label="Only the agent owner can change the allocation" position="top">
        {input}
      </Tooltip>
    );
  };

  return (
    // <Card shadow="sm" p="lg" radius="md" withBorder>
      <Stack>
        
        <Group position="apart" align="center">
        <Text size="sm" weight={500}>Daily Run Allocation</Text>
          <AllocationInput />
          <Stack spacing={0} align="flex-end">
            {isAllocationChanged && isOwner && (
              <Text size="xs" color="dimmed">Previous: {dailyRunAllocation}</Text>
            )}
            <Text size="xs" color="dimmed">Available: {availableAllocation}</Text>
          </Stack>
        </Group>
        {getActionButton()}
      </Stack>
    // </Card>
  );
};

export default AllocationControl;