import React from 'react';
import { Paper, Title, Text, List } from '@mantine/core';
import { Users, Send, Trophy } from 'lucide-react';

const TeamInfoSection = () => {
  return (
    <Paper 
      shadow="sm" 
      className="p-6 mb-6 bg-blue-50"
    >
      <Title order={3} className="mb-4 flex items-center gap-2">
        <Users size={24} />
        How Teams Work
      </Title>
      
      <List 
        spacing="md"
        className="ml-2"
      >
        <List.Item
        >
          <Text size="sm" className="leading-relaxed">
            You can be a member of only one team at a time. You can create or be invited in a team.
          </Text>
        </List.Item>
        
        <List.Item
        >
          <Text size="sm" className="leading-relaxed">
            Team leaders can send invitations to other users. Once accepted, they become team members with full participation rights.
          </Text>
        </List.Item>
        
        <List.Item
        >
          <Text size="sm" className="leading-relaxed">
            All members&apos; submissions count as team submissions (even the past ones). Points are awarded based on the team&apos;s best performing submission.
          </Text>
        </List.Item>
      </List>
    </Paper>
  );
};

export default TeamInfoSection;