import React from 'react';
import { Container, Title, Text, Button, Stack, Center } from '@mantine/core';
import { IconHome } from '@tabler/icons-react';

const NotFound = () => {
  return (
    <Container size="sm">
      <Center h="100vh">
        <Stack align="center" spacing="xl">
          <Title order={1}>404 - Not Found!</Title>
          <Text size="lg">
            The page you are looking for does not exist or has been moved.
          </Text>
          <IconHome size={64} stroke={1.5} />
          <Button 
            variant="filled" 
            size="lg"
            leftSection={<IconHome size="1rem" />}
            component="a" 
            href="/listcompetition"
          >
            Go Back To Competitions
          </Button>
        </Stack>
      </Center>
    </Container>
  );
};

export default NotFound;